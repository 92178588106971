import React, { useState, useEffect } from "react";
import allApi from "../../../api/allApi";
import { useNavigate } from "react-router-dom";


const UserAddModal = ({
  showPopUp,
  handleClose,
  headerMessage1,

}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [fnameError, setFnameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const [err, setErr] = useState("");
  const [countryErr, setCountryErr] = useState("");
  const [resp, setResp] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/user/findAllRoles");
      if (response) {
        setRoles(response);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
    } finally {
      setLoading(false);
    }
  };
  const validateStateAndCountry = (state, country) => {
    const regex = /^[A-Za-z][A-Za-z\s]*$/;
    return regex.test(state) && regex.test(country);
  };

  const validateAll = (firstName, lastName) => {
    const regexp = /^[a-zA-Z0-9]*$/;
    return regexp.test(firstName) && regexp.test(lastName);
  };
  const validateAddress = (address) => {
    const regad = /^[a-zA-Z0-9][a-zA-Z0-9 ]*$/;
    return regad.test(address);
  };


  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };



  const handleAddUser = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setErr("");
    setCountryErr("");

    if (!validateEmail(email)) {
      setErrorMessage("Invalid email format.");
      return;
    }


    if (address && !validateAddress(address)) {
      setErrorMessage("No blank space allowed in address initially");
      return;
    };



    if (!validateAll(firstName, lastName)) {
      setErrorMessage("Only alphanumeric values are allowed in firstname and lastname fields");
      return;
    };


    try {
      const api = new allApi();
      const userData = {
        firstname: firstName,
        lastname: lastName,
        username,
        email,
        address,
        state,
        country,
        role: selectedRole,
      };
      const response = await api.postRequest("api/user/add-tempUser", "", userData);

      if (response) {
        setResp(response);
        console.log('Email sent successfully:', response);
        handleClose();
        setShowModal(true);


      } else {
        console.error("Failed in sending mail");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage("email already in use.");

      } else {
        console.error("failed to send mail:", error);
      }
    };
  };

  const userEmail = async (e) => {
    setEmail(e.target.value);
  };
  const handleFname = (e) => {
    const value = e.target.value;
    setFirstName(value)
    if (value && value[0] !== value[0].toUpperCase()) {
      setFnameError("First letter of firstname must be capital");

    } else {
      setFnameError("");
    }
  };
  const handleState = (e) => {
    const value1 = e.target.value;
    setState(value1);

    if (value1 && !validateStateAndCountry(value1)) {
      setErr("Invalid State Format. Only alphabets are allowed.");
    } else {
      setErr("");
    }
  };

  const handleCountry = (e) => {
    const value = e.target.value;
    setCountry(value);

    if (value && !validateStateAndCountry(value)) {
      setCountryErr("Invalid Country Format. Only alphabets are allowed.");
    } else {
      setCountryErr("");
    }
  };
  const handleLname = (e) => {
    const value = e.target.value;
    setLastName(value)
    if (value && value[0] !== value[0].toUpperCase()) {
      setLnameError("First letter of lastname must be capital");

    } else {
      setLnameError("");
    }
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setUsername("");
    setEmail("");
    setSelectedRole("");
    setAddress("");
    setState("");
    setCountry("");
    setErrorMessage("");
    setFnameError("");
    setLnameError("");
  };
  const handleCancel = () => {
    resetForm();
    handleClose();
  };
  const handleSuccessClose = () => {
    setShowModal(false);
    navigate("/user-management");
  }

  const disableCreate = !(firstName && lastName && username && email && selectedRole && !err && !countryErr);

  return (
    <>
      {showPopUp && (
        <div
          className={"modal fade customModal " + (showPopUp ? " show" : "")}
          id="discover"
          style={{ display: showPopUp ? "block" : "none", zIndex: 1009 }}
        >
          <div className="modal-dialog modal-dialog-centered create-usr-pop">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {headerMessage1}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  onClick={handleCancel}
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleAddUser}>
                  <div className="row">
                    <div className="col-lg-6">
                      <label htmlFor="firstName" className="col-form-label">
                        Firstname<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        value={firstName}
                        onChange={handleFname}
                      />
                      {fnameError && (
                        <span className="required-message " style={{ color: 'red' }}>
                          {fnameError}
                        </span>)}
                    </div>

                    <div className="col-lg-6">
                      <label htmlFor="lastName" className="col-form-label">
                        Lastname<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        value={lastName}
                        onChange={handleLname}

                      />
                      {lnameError && (
                        <span className="required-message " style={{ color: 'red' }}>
                          {lnameError}
                        </span>)}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="username" className="col-form-label">
                        Username<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="email" className="col-form-label">
                        Email<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <label className="col-form-label">
                        Role<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select"
                        id="role"
                        name="role"
                        value={selectedRole}
                        onChange={(e) => setSelectedRole(e.target.value)}
                      >
                        <option value="">Select Role</option>
                        {roles.map((role) => (
                          <option key={role.id} value={role.name}>
                            {role.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="address" className="col-form-label">
                        Address
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <label htmlFor="state" className="col-form-label">
                        State
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="state"
                        value={state}
                        onChange={handleState}
                      />
                      {err && (
                        <span className="required-message " style={{ color: 'red' }}>
                          {err}
                        </span>)}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="country" className="col-form-label">
                        Country
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="country"
                        value={country}
                        onChange={handleCountry}
                      />
                      {countryErr && (
                        <span className="required-message " style={{ color: 'red' }}>
                          {countryErr}
                        </span>)}
                    </div>
                  </div>
                  {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  {emailError && (
                    <div className="alert alert-danger" role="alert">
                      {emailError}
                    </div>
                  )}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="text-btn primary-btn"
                  disabled={disableCreate}
                  onClick={handleAddUser}
                  aria-label="Create"
                >
                  Create
                </button>

              </div>
            </div>
          </div>
          
        </div>
      )}
      {showModal && (
            <div
              className="modal fade inc-alert customModal show"
              id="mailConfirmation"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
              style={{ display: 'block' }}
            >
              <div className="modal-dialog email-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <h4>Email Validation Required</h4>
                    <h6>
                      An email has been sent to <div style={{color:"blue"}}>{email}.</div> <br /> 
                      Please check your inbox and follow the provided link to verify your email address.<br />
                    </h6>
                    <div className="confirm-btn">
                      <button
                        type="button"
                        className="btn btn-fill"
                        onClick={handleSuccessClose}
                        data-bs-dismiss="modal"
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
    </>
  );
};

export default UserAddModal;
