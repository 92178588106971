import React, { useState, useEffect } from "react";
import Auth from "../../../../assets/images/Auth.png";
import Unauth from "../../../../assets/images/Unauth.png";
import allApi from "../../../../api/allApi";
import { useNavigate } from "react-router-dom";

function WiredSettings({
  data,
  setData,
  serialNumber,
  formData,
  setFormData,
  voiceOptimization,
  setVoiceOptimization,
  videoOptimization,
  setVideoOptimization,
  onDataChange,
}) {
  const [assignedPorts, setAssignedPorts] = useState({});
  const [localFormData, setLocalFormData] = useState({
    vlanId: "",
    serialNumber: serialNumber || "",
    taggedPorts: [],
    untaggedPorts: [],
  });

  const navigate = useNavigate();
  const [portDetails, setPortDetails] = useState([]);
  const [isMacAclEnabled, setIsMacAclEnabled] = useState(false);
  const [policy, setPolicy] = useState("0");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupIP, setShowPopupIP] = useState(false);
  const [showCustomPopup, setShowCustomPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [manualAclEntries, setManualAclEntries] = useState([]);
  const [newAclEntry, setNewAclEntry] = useState({
    deviceName: "",
    mac: "",
    macMask: "00-00-00-00-00-00",
    accessDirection: "0",
  });
  const [allowFromThisDevice, setAllowFromThisDevice] = useState(false);
  const [allowToThisDevice, setAllowToThisDevice] = useState(false);

  //temparory var
  const [assignedPortsV2, setAssignedPortsV2] = useState({});
  const [accessPortV2, setAccessPortV2] = useState({});
  const [truncatePortV2, setTruncatePortV2] = useState({});
  const wiredSettingPayload = {
    SerialNumber: serialNumber,
    vlanMembers: {
      apPortMembers: [],
      employeeMembers: [],
      guestMembers: [],
      lagMembers: {
        portGrp: [],
        tagged: [],
        untagged: [],
      },
      lanPortMembers: [],
      memberWirelessNetwork: [],
      portMembers: [
        {
          taggedPorts: [],
          untaggedPorts: [],
        },
      ],
    },
  };

  const handleWiredSettingsDataChange = (updatedPayloadList) => {
    onDataChange(updatedPayloadList);
  };

  const [wiredSettingPayloadList, setWiredSettingPayloadList] = useState([]);

  useEffect(() => {
    console.log("updating assignedPortsV2 :", assignedPortsV2);
  }, [assignedPortsV2]);

  useEffect(() => {
    console.log("updating accessPortV2 :", accessPortV2);
  }, [accessPortV2]);

  useEffect(() => {
    console.log("updating truncatePortV2 :", truncatePortV2);
  }, [truncatePortV2]);

  useEffect(() => {
    if (data?.vlanId) {
      fetchMacAclStatus();
    }
  }, [data, policy]);

  useEffect(() => {
    const fetchPortDetails = async () => {
      try {
        const api = new allApi();
        const response = await api.getRequest(
          "api/vlan/get/allSwitchBasicInfo"
        );
        if (response && response.portMembers) {
          setPortDetails(response.portMembers);
        }
      } catch (error) {
        console.error("Error fetching port details:", error);
      }
    };

    fetchPortDetails();
  }, []);

  const totalPortMembersCount = portDetails?.length;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAclEntry((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setNewAclEntry((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (name === "allowFromThisDevice") {
      setAllowFromThisDevice(checked);
    } else if (name === "allowToThisDevice") {
      setAllowToThisDevice(checked);
    }
  };

  const addManualEntry = () => {
    setManualAclEntries((prev) => [...prev, newAclEntry]);
    setNewAclEntry({
      deviceName: "",
      mac: "",
      macMask: "00-00-00-00-00-00",
      accessDirection: "0",
    });
    setShowModal(false);
  };

  const handleSaveAcl = async () => {
    const payload = {
      vlanId: 2200,
      macAclList: {
        mode: "0",
        action: "0",
        macAclRule: {
          manual: [
            {
              macMask: "00-00-00-00-00-00",
              accessDirection: "0",
              mac: "11:11:11:11:11:11",
              deviceName: "Device_1",
            },
            {
              macMask: "00-00-00-00-00-00",
              accessDirection: "2",
              mac: "22:22:22:22:22:22",
              deviceName: "Device_2",
            },
          ],
          custom: [],
        },
      },
    };

    try {
      const api = new allApi();
      const response = await api.postRequest(
        "api/vlan/add/macAcl",
        "",
        payload
      );
      if (response.ok) {
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handlePortClickV2 = (serialNo, portNumber) => {
    setAssignedPortsV2((prev) => {
      const updatedPorts = { ...prev };
      if (updatedPorts[serialNo]) {
        if (updatedPorts[serialNo].includes(portNumber)) {
          updatedPorts[serialNo] = updatedPorts[serialNo].filter(
            (port) => port !== portNumber
          );
        } else {
          updatedPorts[serialNo] = [...updatedPorts[serialNo], portNumber];
        }
      } else {
        updatedPorts[serialNo] = [portNumber];
      }
      return updatedPorts;
    });
  };

  const handlePortClick = (serialNo, portNumber) => {
    handlePortClickV2(serialNo, portNumber);

    setAssignedPorts((prev) => {
      const modelPorts = prev[serialNo] || {};
      const isSelected = !modelPorts[portNumber];
      const isTagged = modelPorts[portNumber] === "trunk";
      togglePort(portNumber, isTagged);
      return {
        ...prev,
        [serialNo]: {
          ...modelPorts,
          [portNumber]: isSelected ? "selected" : undefined,
        },
      };
    });
  };

  const handleAccessPortV2 = () => {
    const updatedPayloadList = [...wiredSettingPayloadList];
    Object.entries(assignedPortsV2).forEach(([serialNo, ports]) => {
      let existingPayload = updatedPayloadList.find(
        (payload) => payload.SerialNumber === serialNo
      );
      if (!existingPayload) {
        existingPayload = {
          SerialNumber: serialNo,
          vlanMembers: {
            apPortMembers: [],
            employeeMembers: [],
            guestMembers: [],
            lagMembers: {
              portGrp: [],
              tagged: [],
              untagged: [],
            },
            lanPortMembers: [],
            memberWirelessNetwork: [],
            portMembers: [
              {
                taggedPorts: [],
                untaggedPorts: ports,
              },
            ],
          },
        };
        updatedPayloadList.push(existingPayload);
      }
      const untaggedPorts = ports.filter((port) =>
        localFormData.untaggedPorts.includes(port)
      );
      existingPayload.vlanMembers.portMembers[0].untaggedPorts = [
        ...new Set([
          ...existingPayload.vlanMembers.portMembers[0].untaggedPorts,
          ...untaggedPorts,
        ]),
      ];
      // Remove ports from tagged ports in the payload
      existingPayload.vlanMembers.portMembers[0].taggedPorts =
        existingPayload.vlanMembers.portMembers[0].taggedPorts.filter(
          (port) => !untaggedPorts.includes(port)
        );
    });
    setWiredSettingPayloadList(updatedPayloadList);
    handleWiredSettingsDataChange(updatedPayloadList);

    console.log(
      "Updated Payload List (Access): ",
      JSON.stringify(updatedPayloadList, null, 2)
    );
  };
  
  const handleTrunkPortV2 = () => {
    const updatedPayloadList = [...wiredSettingPayloadList];
  
    Object.entries(assignedPortsV2).forEach(([serialNo, ports]) => {
      let existingPayload = updatedPayloadList.find(
        (payload) => payload.SerialNumber === serialNo
      );
  
      if (!existingPayload) {
        existingPayload = {
          SerialNumber: serialNo,
          vlanMembers: {
            apPortMembers: [],
            employeeMembers: [],
            guestMembers: [],
            lagMembers: {
              portGrp: [],
              tagged: [],
              untagged: [],
            },
            lanPortMembers: [],
            memberWirelessNetwork: [],
            portMembers: [
              {
                taggedPorts: ports,
                untaggedPorts: [],
              },
            ],
          },
        };
        updatedPayloadList.push(existingPayload);
      }
  
      // Filter ports into tagged only
      const taggedPorts = ports.filter(
        (port) => !localFormData.taggedPorts.includes(port)
      );
  
      // Update the tagged ports
      existingPayload.vlanMembers.portMembers[0].taggedPorts = [
        ...new Set([
          ...existingPayload.vlanMembers.portMembers[0].taggedPorts,
          ...taggedPorts,
        ]),
      ];
  
      // Update trunk class logic for rendering
      setAssignedPorts((prev) => {
        const updatedPorts = { ...prev };
        if (!updatedPorts[serialNo]) {
          updatedPorts[serialNo] = {};
        }
        ports.forEach((port) => {
          updatedPorts[serialNo][port] = "trunk"; // Mark port as trunk for rendering
        });
        return updatedPorts;
      });
    });
  
    setWiredSettingPayloadList(updatedPayloadList);
    handleWiredSettingsDataChange(updatedPayloadList);
  
    // Log for debugging
    console.log(
      "Updated Payload List (Trunk):",
      JSON.stringify(updatedPayloadList, null, 2)
    );
  };
  

  const handleAccessPort = () => {
    handleAccessPortV2();
  
    // Update `assignedPorts` to reflect access mode
    setAssignedPorts((prev) => {
      const updatedPorts = { ...prev };
      Object.entries(assignedPortsV2).forEach(([serialNo, ports]) => {
        if (!updatedPorts[serialNo]) {
          updatedPorts[serialNo] = {};
        }
        ports.forEach((port) => {
          updatedPorts[serialNo][port] = "access"; // Mark port as access
        });
      });
      return updatedPorts;
    });
  
    setAssignedPortsV2({});
  };
  

  const handleTrunkPort = () => {
    handleTrunkPortV2();
    setAssignedPortsV2({});
  };

  const togglePort = (portNumber, isTagged) => {
    setLocalFormData((prev) => {
      let updatedTaggedPorts = [...prev.taggedPorts];
      let updatedUntaggedPorts = [...prev.untaggedPorts];
      if (isTagged) {
        updatedTaggedPorts = [...new Set([...updatedTaggedPorts, portNumber])];
        updatedUntaggedPorts = updatedUntaggedPorts.filter(
          (port) => port !== portNumber
        );
      } else {
        updatedUntaggedPorts = [
          ...new Set([...updatedUntaggedPorts, portNumber]),
        ];
        updatedTaggedPorts = updatedTaggedPorts.filter(
          (port) => port !== portNumber
        );
      }

      return {
        ...prev,
        taggedPorts: updatedTaggedPorts,
        untaggedPorts: updatedUntaggedPorts,
      };
    });
  };

  const updateFormDataPorts = () => {
    const taggedPorts = [];
    const untaggedPorts = [];

    Object.entries(assignedPorts).forEach(([serialNo, ports]) => {
      Object.entries(ports).forEach(([portNumber, state]) => {
        const portDetail = { serialNo, portNumber: parseInt(portNumber, 10) };
        if (state === "tagged") {
          taggedPorts.push(portDetail);
        } else if (state === "untagged") {
          untaggedPorts.push(portDetail);
        }
      });
    });

    const updatedData = {
      ...formData,
      taggedPorts,
      untaggedPorts,
    };

    setFormData(updatedData);
    // onDataChange(updatedData);
  };

  useEffect(() => {
    updateFormDataPorts();
  }, [assignedPorts]);

  const renderPorts = () => {
    if (portDetails.length === 0) {
      return <div>No ports available to display.</div>;
    }

    return portDetails.map((portConfig, index) => {
      const { model, serialNo, numberOfPorts, portMembers } = portConfig;

      const modelAssignedPorts = assignedPorts[serialNo] || {};

      return (
        <div key={serialNo} className="port-container">
          <h4>{model}</h4>
          <div className="router-port left mt-3">
            <div className="mxw-1260">
              <div className="polygon"></div>
              <div className="port-box">
                <ul className={`port port${numberOfPorts}`}>
                  {[...Array(numberOfPorts).keys()].map((i) => {
                    const portNumber = i + 1;
                    const portState = modelAssignedPorts[portNumber];
                    const portClass = portState
                      ? portState === "selected"
                        ? "selected"
                        : "assign"
                      : "unassign";
                    return (
                      <li
                        key={portNumber}
                        className={portClass}
                        onClick={() => handlePortClick(serialNo, portNumber)}
                      >
                        {portState === "trunk" ? `${portNumber}T` : portNumber}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const fetchMacAclStatus = async (selectedPolicy) => {
    try {
      const api = new allApi();
      const vlanId = data?.vlanId;
      const response = await api.getRequest(
        `api/vlan/acl/${vlanId}/${selectedPolicy}`
      );
      if (response.ok) {
        const macAclData = response.data;
        console.log("Fetched MAC ACL Data:", macAclData);
        setIsMacAclEnabled(macAclData.length > 0);
      } 
    } catch (error) {
      console.error("Error fetching MAC ACL status:", error);
    }
  };

  const handlePolicyChange = async (e) => {
    const selectedPolicy = e.target.value;
    setPolicy(selectedPolicy);
    await fetchMacAclStatus(selectedPolicy);
  };

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const handleAddMacAcl = async () => {
    try {
      const macAclManualEntries = manualAclEntries.map((entry) => ({
        macMask: entry.macMask || "00-00-00-00-00-00",
        accessDirection: entry.accessDirection || "0",
        mac: entry.mac,
        deviceName: entry.deviceName,
      }));

      const payload = {
        vlanId: parseInt(formData.vlanId, 10),
        macAclList: {
          mode: policy,
          action: isMacAclEnabled ? "0" : "1",
          macAclRule: {
            manual: macAclManualEntries,
            custom: [],
          },
        },
      };
      const api = new allApi();
      const response = await api.postRequest(
        "api/vlan/add/macAcl",
        "",
        payload
      );

      if (response) {
        setShowModal(false);
      }
    } catch (error) {
      console.error("Error while adding MAC ACL:", error);
    }
  };

  const handleAddManualEntry = () => {
    if (
      newAclEntry.deviceName &&
      newAclEntry.mac &&
      newAclEntry.macMask &&
      newAclEntry.accessDirection
    ) {
      setManualAclEntries((prev) => [
        ...prev,
        newAclEntry, // Add new entry to the manual ACL list
      ]);
      setNewAclEntry({
        deviceName: "",
        mac: "",
        macMask: "00-00-00-00-00-00",
        accessDirection: "0",
      });
    } else {
      alert("Please fill all fields before submitting.");
    }
  };

  return (
    <div>
      <h4>Wired Settings</h4>
      <p>Set where the VLAN will be applied.</p>

      <div className="accordion mt-4" id="accordionWidgets">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Port Members ({totalPortMembersCount})
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionWidgets"
          >
            <div className="accordion-body">
              <div className="port-member">
                <ul>
                  <li>
                    <i
                      className="fa fa-circle colorBlue"
                      aria-hidden="true"
                    ></i>
                    Assigned
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorGreen"
                      aria-hidden="true"
                    ></i>
                    Connected & Powered
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorLightGray"
                      aria-hidden="true"
                    ></i>
                    Disabled
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorBlack"
                      aria-hidden="true"
                    ></i>
                    Available
                  </li>
                  <li>
                    <i className="fa fa-circle colorRed" aria-hidden="true"></i>
                    Error
                  </li>
                </ul>
              </div>
              <div className="mt-4">
                <div className="mb-4 router-port">{renderPorts()}</div>
              </div>
              <div className="port-button">
                <button
                  type="button"
                  className="btn btn-fill"
                  onClick={handleTrunkPort}
                >
                  Trunk Port
                </button>
                <button
                  type="button"
                  className="btn btn-fill"
                  onClick={handleAccessPort}
                >
                  Access Port
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Advanced Settings
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionWidgets"
          >
            <div className="accordion-body">
              <div className="row tab-form mb-2">
                <div className="col-lg-6">
                  <div className="switch-box">
                    <strong>Voice Optimization</strong>
                    <label className="ice-switch">
                      <input
                        type="checkbox"
                        checked={voiceOptimization}
                        onChange={(e) => setVoiceOptimization(e.target.checked)}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="switch-box">
                    <strong>Video Optimization (IGMP Snooping)</strong>
                    <label className="ice-switch">
                      <input
                        type="checkbox"
                        checked={videoOptimization}
                        onChange={(e) => setVideoOptimization(e.target.checked)}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row tab-form mb-4">
                <div className="col-lg-6">
                  <label className="col-form-label">Traffic</label>
                  <select className="form-select">
                    <option>0 - Lowest Priority</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7 - Highest Priority</option>
                  </select>
                </div>
              </div>
              <div className="port-member">
                <ul>
                  <li>
                    <i
                      className="fa fa-circle colorBlue"
                      aria-hidden="true"
                    ></i>
                    Assigned
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorGreen"
                      aria-hidden="true"
                    ></i>
                    Connected & Powered
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorLightGray"
                      aria-hidden="true"
                    ></i>
                    Disabled
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorBlack"
                      aria-hidden="true"
                    ></i>
                    Available
                  </li>
                  <li>
                    <i className="fa fa-circle colorRed" aria-hidden="true"></i>
                    Error
                  </li>
                  <li>
                    <i className="fa fa-bolt colorGreen" aria-hidden="true"></i>
                    PoE
                  </li>
                  <li>
                    <strong className="colorBlack">L</strong>LAG Member
                  </li>
                  <li>
                    <strong className="colorBlack">S</strong>1G SFP Fiber Port
                  </li>
                  <li>
                    <strong className="colorBlack">S+</strong>10G SFP+Fiber Port
                  </li>
                  <li>
                    <i
                      className="fa fa-caret-up colorBlue"
                      aria-hidden="true"
                    ></i>
                    Uplink Port
                  </li>
                  <li>
                    <img src={Auth} alt="Authorized" />
                    Authorized
                  </li>
                  <li>
                    <img src={Unauth} alt="Unauthorized" />
                    Unauthorized
                  </li>
                  <li>
                    <strong className="colorRed">!</strong>Admin Down
                  </li>
                  <li>
                    <i className="fa fa-ban colorRed" aria-hidden="true"></i>
                    Blocked
                  </li>
                </ul>
              </div>
              <div className="port-button">
                <button type="button" className="text-btn primary-btn" disabled>
                  Auto
                </button>
                <button type="button" className="text-btn primary-btn" disabled>
                  Authorized
                </button>
                <button type="button" className="text-btn primary-btn" disabled>
                  Unauthorized
                </button>
              </div>

              <div className="row mt-4 tab-form">
                <div className="col-lg-12">
                  <div className="switch-box">
                    <strong>MAC ACL</strong>
                    <label className="ice-switch">
                      <input
                        type="checkbox"
                        onClick={() => setShowPopup(true)}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                  <div>
                    <label className="col-form-label">Policy</label>
                    <select
                      className="form-select"
                      value={policy}
                      onChange={(e) => setPolicy(e.target.value)}
                    >
                      <option value="0">Allow</option>
                      <option value="1">Deny</option>
                    </select>
                  </div>
                  <div className="port-button">
                    <button
                      type="button"
                      className="btn btn-fill"
                      onClick={() => setShowModal(true)}
                    >
                      Manual
                    </button>
                    {/* <button type="button" className="btn btn-fill">
                      Confirm
                    </button> */}
                    {/* <button type="button" className="btn btn-fill">
                      Add Device
                    </button> */}
                    <button type="button" className="btn btn-fill">
                      Remove device
                    </button>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="switch-box">
                    <strong>Enable IP Filtering</strong>
                    <label className="ice-switch">
                      <input
                        type="checkbox"
                        onClick={() => setShowPopupIP(true)}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                  <div>
                    <label className="col-form-label">Policy</label>
                    <select
                      className="form-select"
                      value={policy}
                      onChange={handlePolicyChange}
                    >
                      <option value="0">Allow</option>
                      <option value="1">Deny</option>
                    </select>
                  </div>
                  <div className="port-button">
                    <button
                      type="button"
                      className="btn btn-fill"
                      onClick={() => setShowModal(true)}
                    >
                      Manual
                    </button>
                    <button
                      type="button"
                      className="btn btn-fill"
                      onClick={setShowCustomPopup}
                    >
                      Custom
                    </button>
                    {/* <button type="button" className="btn btn-fill">
                      Add Device
                    </button> */}
                    <button type="button" className="btn btn-fill">
                      Remove device
                    </button>
                  </div>
                </div>
              </div>

              {showPopup && (
                <div className="customModal">
                  <div className="c-modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Enable MAC ACL</h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={() => setShowPopup(false)}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <p
                          style={{
                            textAlign: "justify",
                          }}
                        >
                          When MAC ACL policy is enabled with at least one
                          device added in Allow/Policy list, the gateway router
                          MAC address also needs to be added to the allow list
                          to ensure proper traffic flow.
                        </p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="text-btn primary-btn"
                          style={{
                            margin: "0 auto",
                          }}
                          onClick={() => setShowPopup(false)}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showPopupIP && (
                <div className="customModal">
                  <div className="c-modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          Enable IP Filtering Policy
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={() => setShowPopup(false)}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <p
                          style={{
                            textAlign: "justify",
                          }}
                        >
                          Enable IP Filtering policy when at least one device is
                          added in Allow/Policy list.
                        </p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="text-btn primary-btn"
                          style={{
                            margin: "0 auto",
                          }}
                          onClick={() => setShowPopupIP(false)}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`modal fade ${showModal ? "show d-block" : ""}`}
                tabIndex="-1"
                role="dialog"
                style={{
                  backgroundColor: showModal
                    ? "rgba(0, 0, 0, 0.5)"
                    : "transparent",
                }}
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">MAC ACL Manual Entry</h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowModal(false)}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="mb-3">
                          <label htmlFor="macAddress" className="form-label">
                            Device Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="macAddress"
                            placeholder="Enter Device Name"
                            value={newAclEntry.deviceName}
                            onChange={(e) =>
                              setNewAclEntry({
                                ...newAclEntry,
                                deviceName: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="macAddress" className="form-label">
                            MAC Address
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="macAddress"
                            placeholder="Enter MAC Address"
                            value={newAclEntry.mac}
                            onChange={(e) =>
                              setNewAclEntry({
                                ...newAclEntry,
                                mac: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-3">
                          <div className="form-check checkbox-label">
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                id="allowFromThisDevice"
                                checked={newAclEntry.allowFromThisDevice}
                                onChange={(e) =>
                                  setNewAclEntry({
                                    ...newAclEntry,
                                    allowFromThisDevice: e.target.checked,
                                  })
                                }
                              />
                              <span className="indicator"></span>
                            </label>
                            <label htmlFor="allowFromThisDevice">
                              Allow access from this device
                            </label>
                          </div>
                          <div className="form-check checkbox-label">
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                id="allowToThisDevice"
                                checked={newAclEntry.allowToThisDevice}
                                onChange={(e) =>
                                  setNewAclEntry({
                                    ...newAclEntry,
                                    allowToThisDevice: e.target.checked,
                                  })
                                }
                              />
                              <span className="indicator"></span>
                            </label>
                            <label htmlFor="allowToThisDevice">
                              Allow access to this device
                            </label>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button
                        button
                        type="button"
                        className="btn btn-fill"
                        onClick={handleAddMacAcl}
                      >
                        Add
                      </button>
                      <button type="button" className="btn btn-outline">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showCustomPopup && (
            <div className="customModal">
              <div className="c-modal-dialog modal-dialog-centered small">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Custom Access Management</h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => setShowCustomPopup(false)}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div>
                      <div className="mb-3">
                        <label className="col-form-label">From</label>
                        <select className="form-select">
                          <option>Manual</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Device Name</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">IP Address</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="form-check checkbox-label">
                        <label className="checkbox">
                          <input type="checkbox" id="rangedevice1" />
                          <span className="indicator"></span>
                        </label>
                        <label htmlFor="rangedevice1">
                          Add range of device
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <label className="col-form-label">To</label>
                        <select className="form-select">
                          <option>Manual</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Device Name</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">IP Address</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="form-check checkbox-label">
                        <label className="checkbox">
                          <input type="checkbox" id="rangedevice1" />
                          <span className="indicator"></span>
                        </label>
                        <label htmlFor="rangedevice1">
                          Add range of device
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-outline">
                      Cancel
                    </button>
                    <button type="submit" className="text-btn primary-btn">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>{" "}
      </div>
    </div>
  );
}

export default WiredSettings;
