import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import allApi from "../../../../api/allApi";
import Loader from "../../common/Loader";
import eyeIcon from "../../../../assets/images/eye-close.svg";
import eyeCloseIcon from "../../../../assets/images/eye-close.svg";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function WirelessSettings(getList) {
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showData, setShowData] = useState(false);
  const [showPopUp, setShowPopUp] = useState(true);
  const [formData, setFormData] = useState({
    ssid: "",
    broadcastStatus: "0",
    band: [],
    vlanId: "",
    bandSteeringSt: "0",
    fastRoamingSt: "0",
    clientIsolation: "0",
    AllowLocalUIAccess: "0",
    scheduleEnabled: "0",
    // support802_11k_v: "0",
    securityAuthentication: "",
  });
  const [schedule, setSchedule] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });
  const [ssidList, setSsidList] = useState([]);
  const [SSID, setSSID] = useState([]);
  const [wlanId, setWlanId] = useState("");
  const [showAddSsidForm, setShowAddSsidForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [vlanList, setVlanList] = useState([]);
  const [schedulesList, setSchedulesList] = useState([]);
  const [showScheduleForm, setShowScheduleForm] = useState(true);
  const [popUp, setPopUp] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [isCreatingNewSchedule, setIsCreatingNewSchedule] = useState(false);
  const [slotsBooked, setSlotsBooked] = useState(false);
  const [scheduleName, setScheduleName] = useState("");

  useEffect(() => {
    fetchSsidList();
    fetchSchedules();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      if (name === "band") {
        if (value === "All") {
          setFormData({
            ...formData,
            band: checked ? ["2.4 GHz", "5 GHz"] : [],
          });
        } else {
          setFormData((prevData) => {
            const newBand = checked
              ? [...prevData.band, value].filter((v) => v !== "All")
              : prevData.band.filter((band) => band !== value);
            return {
              ...prevData,
              band: newBand,
            };
          });
        }
      } else {
        setFormData({
          ...formData,
          [name]: checked ? "1" : "0",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const fetchSsidList = async () => {
    try {
      setLoading(true);
      setRefreshing(true);
      const api = new allApi();
      const response = await api.getRequest(
        "api/wireless/configuration/getAllSsids?networkId=6001"
      );
      if (response.status === "OK" && response.response) {
        setSSID(response.response);
      } else {
        setSSID([]);
      }
    } catch (error) {
      console.error("Failed to fetch SSID list", error.message);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const fetchSchedules = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        "api/wireless/configuration/getAllSsidSchedule?networkId=6001"
      );
      if (response.status === "OK") {
        setSchedules(response.response || []);
      }
    } catch (error) {
      console.error("Error fetching schedules:", error);
    }
  };

  const handleSubmit = async (e, actionType) => {
    e.preventDefault();
    try {
      let payload = {
        ssid: formData.ssid,
        broadcastStatus: formData.broadcastStatus,
        band: formData.band.join(", "),
        vlanId: formData.vlanId,
        bandSteeringSt: formData.bandSteeringSt,
        fastRoamingSt: formData.fastRoamingSt,
        clientIsolation: formData.clientIsolation,
        AllowLocalUIAccess: formData.AllowLocalUIAccess,
        // scheduleEnabled: formData.scheduleEnabled,
        // support802_11k_v: formData.support802_11k_v,
        securityAuthentication: formData.securityAuthentication,
      };
      setLoading(true);
      setRefreshing(true);
      const api = new allApi();
      const response = await api.postRequest(
        "api/wireless/configuration/addSsid?networkId=6001",
        "",
        payload
      );
      if (response.message) {
        setShowPopUp(false);
        setShowData(true);
        if (actionType === "saveAndConfigure") {
          navigate(`/wifi-settings`);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const removeTimeSlot = (day, index) => {
    const updatedSchedule = { ...schedule };
    updatedSchedule[day].splice(index, 1);
    setSchedule(updatedSchedule);
  };

  const addTimeSlot = (day) => {
    const updatedSchedule = { ...schedule };
    updatedSchedule[day].push({ startTime: "", endTime: "" });
    setSchedule(updatedSchedule);
  };

  return (
    <div>
      <div className="wifi-ssid">
        <div>
          <h4>Wireless Settings</h4>
          <p>Please create SSIDs for WiFi setup.</p>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-fill"
            onClick={() => setShowAddSsidForm(!showAddSsidForm)}
          >
            {showAddSsidForm ? "Cancel" : "Add New SSID"}
          </button>
        </div>
      </div>

      <div className="accordion mt-4" id="accordionRearrange">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              SSID
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionRearrange"
          >
            <div className="accordion-body">
              <div className="inc-card-table">
                <table className="full-table m-head">
                  <thead>
                    <tr>
                      <th>SSID</th>
                      <th>Band</th>
                      <th>Security</th>
                      <th>Broadcast Status</th>
                      <th>Fast Roaming</th>
                      <th>VLAN ID</th>
                    </tr>
                  </thead>
                  {loading ? (
                    <Loader />
                  ) : SSID && SSID.length > 0 ? (
                    <tbody>
                      {SSID.map((wifi, index) => (
                        <tr key={index}>
                          <td>{wifi.ssid}</td>
                          <td>
                            <div className="band-pills">
                              {wifi?.band
                                .replace(/\s+/g, "")
                                .toUpperCase()
                                .includes("2.4GHZ") && (
                                <span className="yellow">2.4 GHz</span>
                              )}
                              {wifi?.band
                                .replace(/\s+/g, "")
                                .toUpperCase()
                                .includes("5GHZ") && (
                                <span className="blue">5 GHz</span>
                              )}
                            </div>
                          </td>
                          <td>{wifi.securityAuthentication || "N/A"}</td>
                          <td>
                            <span
                              className={
                                "d-status " +
                                (wifi.broadcastStatus === 1
                                  ? "online"
                                  : "offline")
                              }
                            >
                              {wifi.broadcastStatus === 1
                                ? "Enabled"
                                : "Disabled"}
                            </span>
                          </td>
                          <td>
                            <span
                              className={
                                "d-status " +
                                (wifi.fastRoamingSt === "1"
                                  ? "online"
                                  : "offline")
                              }
                            >
                              {wifi.fastRoamingSt === "1"
                                ? "Enabled"
                                : "Disabled"}
                            </span>
                          </td>
                          <td>{wifi.vlanId}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="7">
                          <div
                            style={{ textAlign: "center", fontSize: "medium" }}
                          >
                            No SSID To Display
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-line"></div>

      {showAddSsidForm && (
        <div className="modal-body">
          <form>
            <div className="add-ssid-body">
              <h4>New WiFi Settings</h4>
              <div className="row d-flex">
                <div className="col-lg-6">
                  <input
                    type="text"
                    className="form-control"
                    name="ssid"
                    placeholder="SSID"
                    value={formData.ssid}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <div className="switch-box">
                    <strong>Broadcast SSID</strong>
                    <label className="ice-switch">
                      <input
                        type="checkbox"
                        name="broadcastStatus"
                        checked={formData.broadcastStatus === "1"}
                        onChange={handleChange}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="border-line"></div>

              <h4 className="mt-3">Band</h4>
              <p>
                Some legacy clients may not support WPA3 security. Please select
                WPA3 Personal <br />
                Mixed (WPA2+WPA3) mode.
              </p>
              <div className="row">
                <div className="col-lg-3 checkbox-group">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="band"
                      value="All"
                      checked={formData.band.length === 2}
                      onChange={handleChange}
                    />
                    <span className="indicator"></span>
                    <strong>All</strong>
                  </label>
                </div>
                <div className="col-lg-3 checkbox-group">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="band"
                      value="2.4 GHz"
                      checked={formData.band.includes("2.4 GHz")}
                      onChange={handleChange}
                    />
                    <span className="indicator"></span>
                    <strong>2.4 GHz</strong>
                  </label>
                </div>
                <div className="col-lg-3 checkbox-group">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="band"
                      value="5 GHz"
                      checked={formData.band.includes("5 GHz")}
                      onChange={handleChange}
                    />
                    <span className="indicator"></span>
                    <strong>5 GHz</strong>
                  </label>
                </div>
              </div>

              <div className="border-line"></div>

              <h4 className="mt-3">Security Settings</h4>
              <div className="row">
                <div className="col-lg-6">
                  <label htmlFor="security" className="col-form-label">
                    Security<span className="text-danger">*</span>
                  </label>
                  <select
                    id="securityAuthentication"
                    className="form-select"
                    name="securityAuthentication"
                    placeholder="Select Security"
                    value={formData.securityAuthentication}
                    onChange={handleChange}
                  >
                    <option value="">Select Security</option>
                    <option value="WPA2 Personal">WPA2 Personal</option>
                    <option value="WPA2 Personal Mixed">
                      WPA2 Personal Mixed
                    </option>
                    <option value="WPA3 Personal">WPA3 Personal</option>
                    <option value="WPA3 Personal Mixed">
                      WPA3 Personal Mixed (WPA2 + WPA3)
                    </option>
                  </select>
                </div>
              </div>
              {formData.securityAuthentication === "WPA2 Personal" && (
                <div className="row mt-3">
                  <div className="col-lg-12">
                    <div className="switch-box">
                      <strong>Multi Pre-shared Key (MPsK)</strong>
                      <label className="ice-switch">
                        <input
                          type="checkbox"
                          name="multiPsK"
                          checked={formData.multiPsK}
                          onChange={() =>
                            setFormData((prev) => ({
                              ...prev,
                              multiPsK: !prev.multiPsK,
                            }))
                          }
                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                  </div>

                  {formData.multiPsK && (
                    <div className="col-lg-6">
                      <label htmlFor="password" className="col-form-label">
                        Password<span className="text-danger">*</span>
                      </label>
                      <div className="input-wrapper">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          name="password"
                          placeholder="Password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                        <img
                          src={showPassword ? eyeIcon : eyeCloseIcon}
                          alt={showPassword ? "Hide password" : "Show password"}
                          className="show-password"
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="border-line"></div>
              <h4 className="mt-3">Network Settings</h4>
              <div className="row mt-3">
                <div className="col-lg-12">
                  <div className="switch-box">
                    <strong>Client Isolation</strong>
                    <label className="ice-switch">
                      <input
                        type="checkbox"
                        name="clientIsolation"
                        checked={formData.clientIsolation === "1"}
                        onChange={handleChange}
                      />{" "}
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="border-line"></div>
              <div className="row mt-3">
                <div className="col-lg-12">
                  <div className="switch-box">
                    <strong>Access To Local UI</strong>
                    <label className="ice-switch">
                      <input
                        type="checkbox"
                        name="allowLocalUIAccess"
                        checked={formData.allowLocalUIAccess === "1"}
                        onChange={handleChange}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="border-line"></div>
              <h4 className="mt-3 mb-4">Radio Settings</h4>
              <div className="row">
                <div className="col-lg-4">
                  <div className="switch-box">
                    <strong>Band Steering</strong>
                    <label className="ice-switch">
                      <input
                        type="checkbox"
                        name="bandSteeringSt"
                        checked={formData.bandSteeringSt === "1"}
                        onChange={handleChange}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="switch-box">
                    <strong>Fast Roaming (802.11r)</strong>
                    <label className="ice-switch">
                      <input
                        type="checkbox"
                        name="fastRoamingSt"
                        checked={formData.fastRoamingSt === "1"}
                        onChange={handleChange}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="switch-box">
                    <strong>802.11k/v</strong>
                    <label className="ice-switch">
                      <input
                        type="checkbox"
                        name="kvrStatus"
                        checked={formData.kvrStatus === "1"}
                        onChange={handleChange}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="border-line"></div>
              <div className="row mt-3">
                <div className="col-lg-12">
                  <div className="switch-box">
                    <strong>Enable SSID Schedule</strong>
                    <label className="ice-switch">
                      <input
                        type="checkbox"
                        name="scheduleEnabled"
                        checked={formData.scheduleEnabled === "1"}
                        onChange={handleChange}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
              </div>

              {formData.scheduleEnabled === "1" && (
                <div className="mt-3">
                  <div className="row">
                    <div className="col-lg-12">
                      <label htmlFor="vlanId" className="col-form-label">
                        Select a Schedule
                      </label>
                      <select
                        className="form-select"
                        value={formData.schedule}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            schedule: e.target.value,
                          })
                        }
                      >
                        <option value="">Select a Schedule</option>
                        {schedules.map((schedule) => (
                          <option
                            key={schedule.scheduleId}
                            value={schedule.scheduleName}
                          >
                            {schedule.scheduleName}
                          </option>
                        ))}
                      </select>
                      <br />
                      <p className="text-center mt-2">OR</p>
                      <div className="row mt-3">
                        <div className="col-lg-12">
                          <div className="switch-box">
                            <strong
                              onClick={() =>
                                setIsCreatingNewSchedule(!isCreatingNewSchedule)
                              }
                            >
                              Configure New Schedule
                            </strong>
                          </div>
                        </div>
                      </div>
                      <br />

                      {showPopUp && (
                        <div>
                          {showScheduleForm && !slotsBooked ? (
                            <div>
                              <div className="schedule-week">
                                <h4>Schedule Name</h4>
                                <div className="row d-flex">
                                  <div className="col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Schedule Name"
                                      value={scheduleName}
                                      onChange={(e) =>
                                        setScheduleName(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="border-line"></div>
                                <p>Location Timezone - America/New_York (ET)</p>
                                <h4>Days</h4>
                                <p>
                                  You can add a maximum of 3 time slots per day.
                                </p>
                                <div className="schedule-slot-scroller">
                                  {Object.keys(schedule).map((day) => (
                                    <div className="schedule-days" key={day}>
                                      <div className="sc-day-title">
                                        <h4>{day}</h4>
                                        <div>
                                          <button
                                            className="icon-btn"
                                            onClick={() => addTimeSlot(day)}
                                          >
                                            <i
                                              className="fa fa-plus"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </div>
                                      </div>
                                      <div className="schedule-time">
                                        {schedule[day].map((slot, index) => (
                                          <div
                                            className="schedule-time-box"
                                            key={index}
                                          >
                                            <div className="start-time">
                                              <h4>Start Time</h4>
                                              <TimePicker
                                                value={slot.startTime}
                                                onChange={(value) =>
                                                  handleChange(
                                                    day,
                                                    index,
                                                    "startTime",
                                                    value
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    className="time-picker-input"
                                                  />
                                                )}
                                                components={{
                                                  OpenPickerIcon: () => (
                                                    <AccessTimeIcon fontSize="small" />
                                                  ),
                                                }}
                                              />
                                            </div>
                                            <div className="end-time">
                                              <h4>End Time</h4>
                                              <TimePicker
                                                value={slot.endTime}
                                                onChange={(value) =>
                                                  handleChange(
                                                    day,
                                                    index,
                                                    "endTime",
                                                    value
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    className="time-picker-input"
                                                  />
                                                )}
                                                components={{
                                                  OpenPickerIcon: () => (
                                                    <AccessTimeIcon fontSize="small" />
                                                  ),
                                                }}
                                              />
                                            </div>
                                            <button
                                              onClick={() =>
                                                removeTimeSlot(day, index)
                                              }
                                              className="delete-slot"
                                            >
                                              <i
                                                className="fa fa-trash"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="schedule-wrapper">
                              {schedulesList.length > 0 ? (
                                schedulesList.map((scheduleItem) => (
                                  <div
                                    className="schedule-item"
                                    key={scheduleItem.scheduleId}
                                  >
                                    <div className="schedule-detail">
                                      <h5>{scheduleItem.scheduleName}</h5>
                                      <p>
                                        Status:{" "}
                                        {scheduleItem.scheduleStatus === 1
                                          ? "Active"
                                          : "Inactive"}
                                      </p>
                                      <p>
                                        Created At:{" "}
                                        {new Date(
                                          scheduleItem.createdAt * 1000
                                        ).toLocaleString()}
                                      </p>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <p>No schedules found.</p>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
export default WirelessSettings;
