import React from "react";

const TrafficFilter = () => {
    return (
        <div>
            <div className="accordion mt-4" id="allowTraffic">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                        >
                            Wired
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#allowTraffic">
                        <div className="accordion-body">

                            <div className="tab-form">
                                <div className="row mb-4">
                                    <div className="col-lg-6">
                                        <div className="switch-box">
                                            <strong>Allow Broadcast/Multicast Traffic - Wired</strong>
                                            <label className="ice-switch">
                                                <input type="checkbox" />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-lg-4">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                            <label className="form-check-label" for="flexRadioDefault1">
                                                Allow All Devices Traffic
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label" for="flexRadioDefault2">
                                                Allow Traffic From Specific Devices
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>Import MAC Address List</p>
                            <div className="drag-file-box">
                                Drag and drop files here or <span>Browse</span>
                                <input type="file" />
                            </div>

                            <div className="inc-card-table">
                                <table className="m-head full-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <label className="checkbox">
                                                    <input type="checkbox" />
                                                    <span className="indicator"></span>
                                                </label>
                                            </th>
                                            <th>MAC Address</th>
                                            <th>Device Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <label className="checkbox">
                                                    <input type="checkbox" />
                                                    <span className="indicator"></span>
                                                </label>
                                            </td>
                                            <td>as:ed:12:aw:2s:11</td>
                                            <td>DHCP Request</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkbox">
                                                    <input type="checkbox" />
                                                    <span className="indicator"></span>
                                                </label>
                                            </td>
                                            <td>as:ed:12:aw:2s:11</td>
                                            <td>DHCP Request</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkbox">
                                                    <input type="checkbox" />
                                                    <span className="indicator"></span>
                                                </label>
                                            </td>
                                            <td>as:ed:12:aw:2s:11</td>
                                            <td>DHCP Request</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkbox">
                                                    <input type="checkbox" />
                                                    <span className="indicator"></span>
                                                </label>
                                            </td>
                                            <td>as:ed:12:aw:2s:11</td>
                                            <td>DHCP Request</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                        >
                            Wireless
                        </button>
                    </h2>
                    <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#allowTraffic"
                    >
                        <div className="accordion-body">

                            <div className="tab-form">
                                <div className="row mb-4">
                                    <div className="col-lg-6">
                                        <div className="switch-box">
                                            <strong>Allow Broadcast/Multicast Traffic - Wired</strong>
                                            <label className="ice-switch">
                                                <input type="checkbox" />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-lg-4">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                            <label className="form-check-label" for="flexRadioDefault1">
                                                Allow All Devices Traffic
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label" for="flexRadioDefault2">
                                                Allow Traffic From Specific Devices
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>Import MAC Address List</p>
                            <div className="drag-file-box">
                                Drag and drop files here or <span>Browse</span>
                                <input type="file" />
                            </div>

                            <div className="inc-card-table">
                                <table className="m-head full-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <label className="checkbox">
                                                    <input type="checkbox" />
                                                    <span className="indicator"></span>
                                                </label>
                                            </th>
                                            <th>MAC Address</th>
                                            <th>Device Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <label className="checkbox">
                                                    <input type="checkbox" />
                                                    <span className="indicator"></span>
                                                </label>
                                            </td>
                                            <td>as:ed:12:aw:2s:11</td>
                                            <td>DHCP Request</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkbox">
                                                    <input type="checkbox" />
                                                    <span className="indicator"></span>
                                                </label>
                                            </td>
                                            <td>as:ed:12:aw:2s:11</td>
                                            <td>DHCP Request</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkbox">
                                                    <input type="checkbox" />
                                                    <span className="indicator"></span>
                                                </label>
                                            </td>
                                            <td>as:ed:12:aw:2s:11</td>
                                            <td>DHCP Request</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkbox">
                                                    <input type="checkbox" />
                                                    <span className="indicator"></span>
                                                </label>
                                            </td>
                                            <td>as:ed:12:aw:2s:11</td>
                                            <td>DHCP Request</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrafficFilter;
