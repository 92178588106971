import React from "react";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import ApexCharts from "react-apexcharts";
import allApi from "../../../../api/allApi";
import Loader from "../../common/Loader";
import { useLocation } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import { useNavigate } from "react-router-dom";

function WiredDashboard() {
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const location = useLocation();
  const [deviceListings, setDeviceListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [devicesResponse, setDevicesResponse] = useState([]);
  const [totalDevices, setTotalDevices] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const [portUsage, setPortUsage] = useState([]);
  const [portData, setPortData] = useState([]);
  const [poeUsage, setPoeUsage] = useState([]);
  const [poeData, setPoeData] = useState([]);
  const [trafficData, setTrafficData] = useState([]);
  const [trafficSeries, setTrafficSeries] = useState([]);
  const [trafficCategories, setTrafficCategories] = useState([]);
  const navigate = useNavigate();
  const [trafficDuration, setTrafficDuration] =
    useState("24h");

  useEffect(() => {
    getDeviceDetails();
  }, []);

  useEffect(() => {
    getTraffic(trafficDuration);
  }, [trafficDuration]);

  const handleTrafficDurationChange = (newDuration) => {
    setTrafficDuration(newDuration);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const health = params.get("health");
    if (health) {
      const filteredDevices = deviceListings.filter(
        (device) => device.health.toLowerCase() === health.toLowerCase()
      );
      setFilteredDevices(filteredDevices);
    } else {
      setFilteredDevices(deviceListings);
    }
  }, [deviceListings, location.search]);

  async function getDeviceDetails() {
    try {
      setLoading(true);
      setRefreshing(true);
      const api = new allApi();
      const devicesResponse = await api.getRequest(
        "api/wired/dashboard/devices"
      );
      if (devicesResponse) {
        setDevicesResponse(devicesResponse);
        setDeviceListings(devicesResponse);
        setTotalDevices(devicesResponse.length);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching device data:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  }
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const api = new allApi();
        const response = await api.getRequest("api/wired/dashboard/portUsage");
        if (response) {
          console.log(response);
          // Ensure response.success and response.failure are arrays before setting the state
          setPortData({
            success: Array.isArray(response.success) ? response.success : [],
            failure: Array.isArray(response.failure) ? response.failure : [],
          });
        }
      } catch (error) {
        console.error("Error fetching ports", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const getPoeUsage = async () => {
      try {
        setLoading(true);
        // setRefreshing(true);
        const api = new allApi();
        const poeData = await api.getRequest(`api/wired/dashboard/poeUsage`);

        if (poeData && poeData.success && Array.isArray(poeData.success)) {
          const seriesData = poeData.success.map(item => ({
            x: item.serialNo,
            y: item.poe,
            z: item.total_power
          }));

          setSeries([{ data: seriesData }]);

          // Handle failure cases if needed
          if (poeData.failure && Array.isArray(poeData.failure)) {
            console.warn("Failed serial numbers:", poeData.failure);
          }
        } else {
          console.error("Unexpected poeData format:", poeData);
        }
      } catch (error) {
        console.error("Error fetching POE usage", error);
      } finally {
        setLoading(false);
        // setRefreshing(false);
      }
    };
    getPoeUsage();
  }, []);

  const getTraffic = async (newDuration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const trafficData = await api.getRequest(`api/wired/dashboard/traffic/${newDuration}`);
  
      if (trafficData && Array.isArray(trafficData)) {
        if (trafficData.length === 0) {
          console.log("No traffic data available.");
          setTrafficSeries([]);
          setTrafficCategories([]);
        } else {
          const aggregatedData = trafficData.reduce((acc, item) => {
            const date = new Date(item.hour);
            let formattedDate;
  
            // Format the date based on the duration
            if (newDuration === '8h' || newDuration === '24h') {
              formattedDate = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            } else if (newDuration === '7d' || newDuration === '30d') {
              formattedDate = date.toLocaleDateString(undefined, {
                day: '2-digit',
                month: 'short', // Format to display day and short month (e.g., "01 Sep")
              });
            }
  
            if (formattedDate) {
              if (!acc[formattedDate]) {
                acc[formattedDate] = { total_bandwidth: 0, userId: item.userId };
              }
              acc[formattedDate].total_bandwidth += item.total_bandwidth / 1024;
            }
  
            return acc;
          }, {});
  
          let categories = Object.keys(aggregatedData);
          let seriesData = categories.map((date, index) => ({
            x: date,
            y: aggregatedData[date].total_bandwidth.toFixed(2),
            z: aggregatedData[date].userId,
          }));
  
          // For the 30d duration, show time at gaps of 5 days
          if (newDuration === '30d') {
            seriesData = seriesData.filter((_, index) => index % 5 === 0);  // Filter to show every 5th entry
            categories = categories.filter((_, index) => index % 5 === 0);  // Update categories similarly
          }
  
          setTrafficSeries([{ data: seriesData }]);
          setTrafficCategories(categories);
        }
      } else {
        console.error("Traffic data is not an array:", trafficData);
        setTrafficSeries([]);
        setTrafficCategories([]);
      }
    } catch (error) {
      console.error("Error fetching traffic", error);
      setTrafficSeries([]);
      setTrafficCategories([]);
    } finally {
      setLoading(false);
    }
  };
  

  const trafficOptions = {
    chart: {
      type: "line",
      height: 135,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: trafficCategories,
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
      title: {
        text: "Traffic (MBPS)",
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        show: true,
      },
      y: {
        title: {
          formatter: function () {
            return 'Total Bandwidth: ';
          }
        },
        formatter: function (val) {
          return `${val.toFixed(2)} MB`;
        }
      },
      z: {
        title: 'UserId: ',
        formatter: function (value) {
          return value;
        }
      }
    },
    stroke: {
      width: 1,

    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: trafficSeries.map((_, index) =>
      index === 0 ? "#fc7594" : "#00aaff"
    ),
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  };

  const poeOptions = {
    chart: {
      type: "bar",
      height: 135,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: "Power Usage",
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
    },
    tooltip: {
      //shared: true,
      intersect: false,
      x: {
        show: true,
      },
      y: {
        formatter: function (val, opts) {
          return `POE: ${val.toFixed(2)}`;
        },
      },
      z: {
        title: 'Total Power: ',
        formatter: function (value) {
          return `${value} W`;
        }
      }
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <div>
      <div className="right-button-group">
        <div>
          <button
            className="text-btn primary-btn"
            onClick={() => {
              navigate("/wired-config");
            }}
          >
            Wired Configuration
          </button>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>
                Usage
                <span
                  onClick={() => {
                    navigate("/wiredusage-expansion");
                  }}
                >
                  <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                </span>
              </h3>
            </div>
            <div className="inc-card-body">
              <div className="trends-legends position-relative">
                <ul style={{ flex: "auto" }}>
                  <li>
                    <span className="d1"></span>Connected Using Power
                  </li>
                  <li>
                    <span className="d2"></span>Connected Not Using Power
                  </li>
                  <li>
                    <span className="d3"></span>Disabled
                  </li>
                  <li>
                    <span className="d4"></span>Error
                  </li>
                  <li>
                    <span className="d5"></span>Free
                  </li>
                </ul>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <div>
                  {portData && portData.success && portData.success.length > 0 ? (
                    portData.success.map((port, index) => (
                      <div key={index} className="wd-usage-chart">
                        <div className="wd-d-port">
                          <h4 style={{ textAlign: "left" }}>
                            {port.serialNumber}
                          </h4>
                          <h4 style={{ textAlign: "center" }}>
                            {port.numOfPorts} Port{port.numOfPorts > 1 ? 's' : ''}
                          </h4>
                        </div>
                        <ResponsiveContainer width="100%" height={40}>
                          <BarChart
                            layout="vertical"
                            data={[port]}
                            margin={{
                              top: 0,
                              right: 0,
                              left: -50,
                              bottom: 0,
                            }}
                          >
                            <CartesianGrid />
                            <XAxis
                              type="number"
                              domain={[0, port.numOfPorts]}
                            />
                            <YAxis type="category" />
                            <Tooltip
                                  content={({ payload, label }) => {
                                    if (payload && payload.length > 0) {
                                      return (
                                        <div
                                          className="custom-tooltip"
                                          style={{
                                            backgroundColor: "#fff", // White background
                                            padding: "10px", // Padding for better spacing
                                            border: "1px solid #ccc", // Border to distinguish
                                            borderRadius: "5px", // Rounded corners
                                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)", // Shadow for depth
                                            color: "#333", // Text color
                                          }}
                                        >
                                          <p>{`Serial Number: ${payload[0]?.payload.serialNumber}`}</p>
                                          {payload.map((item, index) => {
                                            // Only display items with value greater than 0
                                            return item.value > 0 ? (
                                              <p key={index} style={{ color: item.fill }}>
                                                {`${item.name}: ${item.value}`}
                                              </p>
                                            ) : null;
                                          })}
                                        </div>
                                      );
                                    }
                                    return null;
                                  }}
                                />
                            <Bar
                              dataKey="numOfActivePortsUsingPower"
                              stackId="a"
                              fill="#33A02C"
                              name="Connected Using Power"
                            />
                            <Bar
                              dataKey="numOfActivePortsNotUsingPower"
                              stackId="a"
                              fill="#1F78B4"
                              name="Connected Not Using Power"
                            />
                            <Bar
                              dataKey="numOfDisabledPorts"
                              stackId="a"
                              fill="#FB9A99"
                              name="Disabled"
                            />
                            <Bar
                              dataKey="numOfErrorPorts"
                              stackId="a"
                              fill="#f1a92d"
                              name="Error"
                            />
                            <Bar
                              dataKey="numOfFreePorts"
                              stackId="a"
                              fill="#A6CEE3"
                              name="Free"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <div className="no-data-box">
                      <div className="no-data-text">No Data Available</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="two-column mb-4">
        <div className="inc-card">
          <div className="inc-card-title">
            <h3>
              PoE Power Usage
              <span
                onClick={() => {
                  navigate("/wiredpower-expansion");
                }}
              >
                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
              </span>
            </h3>
          </div>
          <div className="inc-card-body">
            {loading ? (
              <Loader />
            ) : (
              <div>
                {series.length === 0 || !series[0].data.length ? (
                  <div className="no-data-box">
                    <div className="no-data-text">No Data Available</div>
                  </div>
                ) : (
                  <ApexCharts
                    options={poeOptions}
                    series={series}
                    type="bar"
                    height={250}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="inc-card">
          <div className="inc-card-title">
            <h3>
              Wired - Traffic
              <span
                onClick={() => {
                  navigate("/wiredtraffic-expansion");
                }}
              >
                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
              </span>
            </h3>
            <span
              className="dropdown"
              id="clientDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>
                {trafficDuration === "24h"
                  ? "24h"
                  : trafficDuration}
                <i className="fa fa-angle-down" aria-hidden="true"></i>
              </span>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() =>
                      handleTrafficDurationChange("8h")
                    }
                  >
                    Last 8 Hrs
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() =>
                      handleTrafficDurationChange("24h")
                    }
                  >
                    Last 24 Hrs
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() =>
                      handleTrafficDurationChange("7d")
                    }
                  >
                    Last 7 Days
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() =>
                      handleTrafficDurationChange("30d")
                    }
                  >
                    Last 30 Days
                  </a>
                </li>
              </ul>
            </span>
          </div>
          <div className="inc-card-body">
            {loading ? (
              <Loader />
            ) : (
              <div>
                {trafficSeries.length === 0 || !trafficSeries[0].data.length ? (
                  <div className="no-data-box">
                    <div className="no-data-text">No Data Available</div>
                  </div>
                ) : (
                  <ApexCharts
                    options={trafficOptions}
                    series={trafficSeries}
                    type="line"
                    height={250}
                  />
                )}
              </div>
            )}

          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Devices ({deviceListings.length})</h3>
              <div className="inc-card-button">
                <button
                  className="icon-btn"
                  title="Refresh"
                  onClick={() => getDeviceDetails()}
                >
                  {refreshing ? (
                    <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                  )}
                </button>
              </div>
            </div>
            <div className="inc-card-body">
              {loading ? (
                <Loader />
              ) : (
                <div className="inc-card-table">
                  <table className="m-head">
                    <thead>
                      <tr>
                        <th>Serial Number</th>
                        <th>Device Name</th>
                        <th>Model</th>
                        <th>Hardware Version</th>
                        <th>Firmware Version</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredDevices === 0 ? (
                        <div className="no-data-box">
                          <div className="no-data-text">No Data Available</div>
                        </div>
                      ) : (
                        filteredDevices.map((device, index) => (
                          <tr key={index}>
                            <td>
                              <span >
                                {device.serial_no || "N/A"}
                              </span>
                            </td>
                            <td>{device.device_name || "N/A"}</td>

                            <td>{device.model || "N/A"}</td>
                            <td>{device.hardware_version || "N/A"}</td>
                            <td>{device.firmware_version || "N/A"}</td>
                            <td>
                              <span
                                className={
                                  "d-status " +
                                  (device?.health === 0 ? "offline" : "online")
                                }
                              >
                                {device?.health === 0 ? "Offline" : "Online"}
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WiredDashboard;
