import React, { useState, useEffect } from "react";
import allApi from "../../../api/allApi"; // Ensure this path is correct

const RoleInfo = ({
  showPopUp,
  handleClose,
  headerMessage1,
  handleSuccess,
}) => {
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/user/findAllRoles`);
      setRoles(response);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };


  return (
    <>
      {showPopUp && (
        <div
          className={"modal fade customModal show"}
          id="discover"
          style={{ display: "block", zIndex: 1009 }}
        >
          <div className="modal-dialog modal-dialog-centered create-usr-pop">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {headerMessage1}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body">
                <div className="tab-content">
                  <div className="tab-pane fade show active">
                    <div className="inc-card-body">
                      <div className="device-info">
                          <strong>Role List:</strong>
                          <ul>
                            {roles.map((role, index) => (
                              <li key={index}>{role.name}</li>
                            ))}
                          </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RoleInfo;
