import React, { useState, useEffect } from "react";
import DiscoverDetailPopUp from "../common/DiscoverDetailPopup";
import allApi from "../../../api/allApi";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
const DiscoverPopUp = ({
  showPopUp,
  handleClose,
  headerMessage1,
  addDeviceToListing,
  getDeviceDetails,
}) => {
  const [ipVersion, setIpVersion] = useState("");
  const [showDiscoverDetailPopUp, setShowDiscoverDetailPopUp] = useState(false);
  const [activeTab, setActiveTab] = useState("snmp");
  const [snmpTemplates, setSnmpTemplates] = useState([]);
  const [snmpDropdownClicked, setSnmpDropdownClicked] = useState(false);
  const [snmpFields, setSnmpFields] = useState({
    ipVersion: "",
    startIp: "",
    endIp: "",
    snmpTemplate: "",
  });
  const [tr069Fields, setTr069Fields] = useState({
    manufacturer: "",
    oui: "",
    serialNo: "",
    productClass: "",
  });

  const [discoverResponseData, setDiscoverResponseData] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (showPopUp) {
      fetchSnmpTemplates();
    }
  }, [showPopUp]);

  const fetchSnmpTemplates = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/snmp/templates");
      if (response) {
        setSnmpTemplates(response);
      } else {
        console.error(
          "Error fetching SNMP templates: Invalid response or data structure."
        );
      }
    } catch (error) {
      console.error("Error fetching SNMP templates:", error);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const closePopUp = () => {
    handleClose();
    setSnmpFields({
      ipVersion: "",
      startIp: "",
      endIp: "",
      snmpTemplate: "",
    });
    setTr069Fields({
      manufacturer: "",
      oui: "",
      serialNo: "",
      productClass: "",
    });
  };

  if (!showPopUp) return null;

  const handleDiscover = async () => {
    try {
      const api = new allApi();
      const payload = {
        startIP: snmpFields.startIp,
        endIP: snmpFields.endIp,
        snmpID: "53799500-a40e-4573-a3f5-ba51555ce753",
        ipType: "IPv4",
      };
      console.log("SNMP", payload);
      const response = await api.postRequest("api/snmp/discover", "", payload);

      console.log("object", response);

      setDiscoverResponseData(response.data[0]);
      setShowDiscoverDetailPopUp(true);
    } catch (error) {
      console.error("Error discovering devices:", error);
    }
  };

  const addTRDevice = async () => {
    try {
      const api = new allApi();
      const response = await api.postRequest("api/genie/onboard/device", "", {
        Manufacture: tr069Fields.manufacturer,
        OUI: tr069Fields.oui,
        ProductClass: tr069Fields.productClass,
        SerialNumber: tr069Fields.serialNo,
      });
      console.log("New device added successfully:", response);
      setTr069Fields({
        manufacturer: "",
        oui: "",
        serialNo: "",
        productClass: "",
      });
      getDeviceDetails();
    } catch (error) {
      console.error("Error adding new device:", error);
    }
  };
  

  const handleOpenDiscoverDetailPopUp = () => {
    setShowDiscoverDetailPopUp(true);
  };

  const handleCloseDiscoverDetailPopUp = () => {
    setShowDiscoverDetailPopUp(false);
  };

  const handleClosePopUpOk = () => {
    handleClose();
  };

  const handleCloseCross = () => {
    handleClose();
  };

  const handleInputChange = (event, tab) => {
    const { name, value } = event.target;
    if (tab === "snmp") {
      setSnmpFields({ ...snmpFields, [name]: value });
    } else if (tab === "tr069") {
      setTr069Fields({ ...tr069Fields, [name]: value });
    }
  };

  const handleAddDeviceToListing = async () => {
    const newDevice = activeTab === "snmp" ? snmpFields : tr069Fields;
    addDeviceToListing(newDevice);
    if (activeTab === "snmp") {
      setShowDiscoverDetailPopUp(true);
    } else {
      try {
        await addTRDevice();
        successhandleShow();
      } catch (error) {
        console.error("Error adding TR-069 device:", error);
      }
    }
  };
  
  const successhandleClose = () => setShow(false);
  const successhandleShow = () => setShow(true);

  return (
    <>
      {!showDiscoverDetailPopUp && (
        <div
          className={"modal fade customModal " + (showPopUp ? " show" : "")}
          id="discover"
          style={{ display: showPopUp ? "block" : "none", zIndex: 1009 }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {headerMessage1}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    handleCloseCross();
                    closePopUp();
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <button
                      className={`nav-link ${activeTab === "snmp" && "active"}`}
                      onClick={() => {
                        handleTabChange("snmp");
                        setSnmpDropdownClicked(true);
                      }}
                    >
                      SNMP
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        activeTab === "tr069" && "active"
                      }`}
                      onClick={() => handleTabChange("tr069")}
                    >
                      TR-069
                    </button>
                  </li>
                </ul>
                <div className="tab-content mt-3">
                  {activeTab === "snmp" && (
                    <div className="tab-pane fade show active">
                      <form>
                        <div className="mb-3">
                          <label htmlFor="ipVersion" className="col-form-label">
                            IP Version
                          </label>
                          <select
                            className="form-select"
                            id="ipVersion"
                            name="ipVersion"
                            value={snmpFields.ipVersion}
                            onChange={(e) => handleInputChange(e, "snmp")}
                          >
                            <option value="">Select IP Version</option>
                            <option value="IPv4">IPv4</option>
                            <option value="IPv6">IPv6</option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="startIp" className="col-form-label">
                            Start IP
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="startIp"
                            name="startIp"
                            value={snmpFields.startIp}
                            onChange={(e) => handleInputChange(e, "snmp")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="endIp" className="col-form-label">
                            End IP
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="endIp"
                            name="endIp"
                            value={snmpFields.endIp}
                            onChange={(e) => handleInputChange(e, "snmp")}
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="snmpTemplate"
                            className="col-form-label"
                          >
                            SNMP Template
                          </label>
                          <select
                            className="form-select"
                            id="snmpTemplate"
                            name="snmpTemplate"
                            value={snmpFields.snmpTemplate}
                            onChange={(e) => handleInputChange(e, "snmp")}
                          >
                            <option value="">Select Template</option>
                            {snmpTemplates.map((template, index) => (
                              <option key={index} value={template}>
                                {template}
                              </option>
                            ))}
                          </select>
                        </div>
                      </form>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="ibtn btn-outline"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            handleClose();
                            closePopUp();
                          }}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          className="ibtn btn-fill"
                          onClick={() => {
                            handleDiscover();
                            handleOpenDiscoverDetailPopUp();
                          }}
                        >
                          Discover
                        </button>
                      </div>
                    </div>
                  )}
                  {activeTab === "tr069" && (
                    <div className="tab-pane fade show active">
                      <form>
                        <div className="mb-3">
                          <label
                            htmlFor="manufacture"
                            className="col-form-label"
                          >
                            Manufacture
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="manufacture"
                            name="manufacture"
                            value={"VVDN Technology"}
                            onChange={(e) => handleInputChange(e, "tr069")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="oui" className="col-form-label">
                            OUI
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="oui"
                            name="oui"
                            value={tr069Fields.oui}
                            onChange={(e) => handleInputChange(e, "tr069")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="serialNo" className="col-form-label">
                            Serial No.
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="serialNo"
                            name="serialNo"
                            value={tr069Fields.serialNo}
                            onChange={(e) => handleInputChange(e, "tr069")}
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="productClass"
                            className="col-form-label"
                          >
                            Product Class
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="productClass"
                            name="productClass"
                            value={tr069Fields.productClass}
                            onChange={(e) => handleInputChange(e, "tr069")}
                          />
                        </div>
                      </form>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="ibtn btn-outline"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            handleClose();
                            closePopUp();
                          }}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          className="ibtn btn-fill"
                          onClick={() => {
                            closePopUp();
                            handleClose();
                            handleAddDeviceToListing();
                            addTRDevice();
                            successhandleShow();
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <DiscoverDetailPopUp
        showPopUp={showDiscoverDetailPopUp}
        handleClose={() => {
          handleCloseDiscoverDetailPopUp(false);
          handleClose();
        }}
        snmpFields={snmpFields}
        discoverResponseData={discoverResponseData}
      />
      <Modal
        show={show}
        onHide={successhandleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>
         Device Added successfully!
        </p>
        <Button onClick={successhandleClose}>OK</Button>
      </Modal>
    </>
  );
};

export default DiscoverPopUp;
