import React, { useEffect, useState } from "react";
import WifiSchedule from "./wifiSchedule";
import UrlFiltering from "./urlFiltering";
import TrafficPolicies from "./trafficPolicies";
import FastRoaming from "./fastRoaming";
import LoadBalancing from "./loadBalancing";
import VlanManagement from "./vlanManagement";
import { useNavigate } from "react-router-dom";
import AddSSIDModal from "./addSSIDModal";
import errorIcon from "../../../../assets/images/error-2.png";
import Loader from "../../common/Loader";
import allApi from "../../../../api/allApi";

function WifiConfig() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("wifi");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [SSID, setSSID] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [schedulesList, setSchedulesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ssidPerPage] = useState(10);
  const [ssid, setSsid] = useState("");
  const [wnId, setWnId] = useState("");
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };
  const [addUrl, setAddUrl] = useState(false);
  const [newScheduleId, setNewScheduleId] = useState(null);
  const [scheduleId, setScheduleId] = useState("");
  const [scheduleName, setScheduleName] = useState("");

  useEffect(() => {
    getSSIDlist();
    fetchSchedules();
  }, []);

  const tabTitles = {
    wifi: "WiFi Settings",
    schedule: "WiFi Schedules",
    filtering: "URL Filtering",
    roaming: "Fast Roaming",
    balancing: "Load Balancing",
    management: "VLAN Management",
    // policies: "Traffic Policies",
  };

  const currentTitle = tabTitles[activeTab] || "WiFi Settings";

  const getSSIDlist = async () => {
    try {
      setLoading(true);
      setRefreshing(true);
      const api = new allApi();
      const response = await api.getRequest(
        "api/wireless/configuration/getAllSsids?networkId=6001"
      ); //api/wireless/configuration/getAllSsids?networkId=1
      if (response.response) {
        setSSID(response.response);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const fetchSchedules = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/configuration/getAllSsidSchedule?networkId=6001`
      );

      if (response.status === "OK" && response.response) {
        // Sort schedules by createdAt in descending order
        const sortedSchedules = response.response.sort(
          (a, b) => b.createdAt - a.createdAt
        );
        setSchedulesList(sortedSchedules);
      } else {
        console.error("Failed to fetch schedules:", response.message);
      }
    } catch (error) {
      console.error("Error fetching schedules:", error);
    }
  };

  const fetchSSIDDetails = async (wnId) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/configuration/getSsid?wnId=${wnId}`
      );
      if (response) {
        const ssidDetails = response.data;
        navigate(`/wifi-settings`, { state: { ssidDetails } });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const deleteSSID = async (wnId) => {
    try {
      setLoading(true);
      const api = new allApi();
      await api.deleteRequest(
        `api/wireless/configuration/deleteSsid?networkId=6001&wnId=${wnId}`
      );
      await getSSIDlist();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openScheduleModal = () => setIsScheduleModalOpen(true);
  const closeScheduleModal = () => setIsScheduleModalOpen(false);

  const indexOfLastSSID = currentPage * ssidPerPage;
  const indexOfFirstSSID = indexOfLastSSID - ssidPerPage;
  const totalFilteredSSID = SSID.length;
  const currentSSID = SSID.slice(indexOfFirstSSID, indexOfLastSSID);

  const totalPages = Math.ceil(totalFilteredSSID / ssidPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const getPageNumbers = () => {
    const range = 1;
    const start = Math.max(1, currentPage - range);
    const end = Math.min(totalPages, currentPage + range);

    return [...Array(end - start + 1).keys()].map((i) => start + i);
  };

  const openAddUrlModal = () => {
    if (activeTab === "filtering") {
      setAddUrl(true);
    }
  };

  const handleScheduleSave = (scheduleId) => {
    setNewScheduleId(scheduleId);
  };

  const deleteSchedule = async (scheduleId) => {
    try {
      const api = new allApi();
      const payload = [
        {
          scheduleId,
        },
      ];
      const response = await api.deleteRequest(
        `api/wireless/configuration/deleteSchedule?networkId=6001`,
        payload
      );

      if (response) {
        fetchSchedules();
      }
    } catch (error) {
      console.error("Error deleting schedule:", error);
    }
  };

  const openDeleteModal = (ssidName, wnId, scheduleName, scheduleId) => {
    setSsid(ssidName);
    setWnId(wnId);
    setShowModal(true);
    setScheduleId(scheduleId);
    setScheduleName(scheduleName);
  };

  return (
    <div>
      <div className="inc-card ch-100">
        <div className="inc-card-title">
          <h3>{currentTitle}</h3>
          <div className="inc-card-button">
            <button
              className="text-btn primary-btn"
              onClick={() => {
                navigate("/wireless");
              }}
            >
              Quick View
            </button>
            {(activeTab === "wifi" || activeTab === "schedule") && (
              <button
                className="icon-btn"
                title="Add"
                onClick={activeTab === "wifi" ? openModal : openScheduleModal}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
              </button>
            )}
            {activeTab === "filtering" && (
              <button
                className="icon-btn"
                title="Add"
                onClick={openAddUrlModal}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
              </button>
            )}
          </div>
        </div>
        <div className="inc-card-body">
          <div className="sideTab-container">
            <div className="inc-side-tab">
              <ul className="nav-side" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "wifi" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("wifi")}
                    role="tab"
                    aria-controls="pills-wifi"
                    aria-selected={activeTab === "wifi"}
                  >
                    WiFi{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "schedule" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("schedule")}
                    role="tab"
                    aria-controls="pills-schedule"
                    aria-selected={activeTab === "schedule"}
                  >
                    WiFi Schedules{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "filtering" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("filtering")}
                    role="tab"
                    aria-controls="pills-filtering"
                    aria-selected={activeTab === "filtering"}
                  >
                    URL Filtering{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                {/* <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "policies" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("policies")}
                    role="tab"
                    aria-controls="pills-policies"
                    aria-selected={activeTab === "policies"}
                  >
                    Traffic Policies{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li> */}
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "roaming" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("roaming")}
                    role="tab"
                    aria-controls="pills-roaming"
                    aria-selected={activeTab === "roaming"}
                  >
                    Fast Roaming{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "balancing" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("balancing")}
                    role="tab"
                    aria-controls="pills-balancing"
                    aria-selected={activeTab === "balancing"}
                  >
                    Load Balancing{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "management" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("management")}
                    role="tab"
                    aria-controls="pills-management"
                    aria-selected={activeTab === "management"}
                  >
                    VLAN Management{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
              </ul>
            </div>
            <div className="inc-side-tabcontainer">
              <div className="tab-content" id="pills-tabContent">
                {activeTab === "wifi" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-wifi"
                    role="tabpanel"
                    aria-labelledby="pills-wifi-tab"
                  >
                    {/* =============WIFI================ */}
                    <div className="inc-card-table">
                      <table className="full-table m-head">
                        <thead>
                          <tr>
                            <th>SSID</th>
                            <th>Band</th>
                            <th>Security</th>
                            <th>Broadcast Status</th>
                            <th>Rate Limiting</th>
                            <th>VLAN ID</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {loading ? (
                          <Loader />
                        ) : SSID && SSID.length > 0 ? (
                          <tbody>
                            {currentSSID.map((wifi, index) => (
                              <tr key={index}>
                                <td>{wifi.ssid}</td>
                                <td>
                                  <div className="band-pills">
                                    {wifi?.band
                                      .replace(/\s+/g, "")
                                      .toUpperCase()
                                      .includes("2.4GHZ") && (
                                      <span className="yellow">2.4 GHz</span>
                                    )}
                                    {wifi?.band
                                      .replace(/\s+/g, "")
                                      .toUpperCase()
                                      .includes("5GHZ") && (
                                      <span className="blue">5 GHz</span>
                                    )}
                                  </div>
                                </td>
                                <td>{wifi.securityAuthentication || "N/A"}</td>
                                <td>
                                  <span
                                    className={
                                      "d-status " +
                                      (wifi.broadcastStatus === 1
                                        ? "online"
                                        : "offline")
                                    }
                                  >
                                    {wifi.broadcastStatus === 1
                                      ? "Enabled"
                                      : "Disabled"}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={
                                      "d-status " +
                                      (wifi.fastRoamingSt === 1
                                        ? "online"
                                        : "offline")
                                    }
                                  >
                                    {wifi.fastRoamingSt === 1
                                      ? "Enabled"
                                      : "Disabled"}
                                  </span>
                                </td>
                                <td>{wifi.vlanId}</td>
                                <td>
                                  <div className="button-group">
                                    <button
                                      className="icon-btn"
                                      title="Edit"
                                      onClick={() =>
                                        fetchSSIDDetails(wifi.wnId)
                                      }
                                    >
                                      <i
                                        className="fa fa-edit"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <button
                                      className="icon-btn"
                                      title="Delete"
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteSsid"
                                      onClick={() =>
                                        openDeleteModal(wifi.ssid, wifi.wnId)
                                      }
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="9">
                                <div
                                  style={{
                                    textAlign: "center",
                                    fontSize: "medium",
                                  }}
                                >
                                  No SSID To Display
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                    <div className="pagination-container">
                      <div className="pagination-info">
                        {totalFilteredSSID > 0 ? (
                          <p>
                            Showing {indexOfFirstSSID + 1}
                            {"-"}
                            {Math.min(
                              currentPage * ssidPerPage,
                              totalFilteredSSID
                            )}{" "}
                            of {totalFilteredSSID} records
                          </p>
                        ) : (
                          <p>No Records Available</p>
                        )}
                      </div>
                      <div className="pagination">
                        <button
                          onClick={handlePrevPage}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                        {getPageNumbers().map((page) => (
                          <button
                            key={page}
                            onClick={() => handlePageClick(page)}
                            className={currentPage === page ? "active" : ""}
                          >
                            {page}
                          </button>
                        ))}
                        <button
                          onClick={handleNextPage}
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                        <button
                          onClick={handleLastPage}
                          disabled={currentPage === totalPages}
                        >
                          Last Page
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "schedule" && (
                  <div className="inc-side-tabcontainer">
                    <div className="tab-content" id="pills-tabContent">
                      <div>
                        <p>
                          A WiFi schedule allows you to set the hours when WiFi
                          is available.
                        </p>
                        {!isScheduleModalOpen ? (
                          <div className="schedule-wrapper">
                            {schedulesList.length > 0 ? (
                              schedulesList.map((scheduleItem) => (
                                <div
                                  className="schedule-item"
                                  key={scheduleItem.scheduleId}
                                >
                                  <div className="schedule-icon">
                                    <i
                                      className="fa fa-clock-o"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  <div className="schedule-detail">
                                    <h5>{scheduleItem.scheduleName}</h5>
                                    <p>
                                      <strong>Status:</strong>{" "}
                                      {scheduleItem.scheduleStatus === 1
                                        ? "Active"
                                        : "Inactive"}
                                      scheduleItem
                                    </p>
                                    <p>
                                      <strong>Created At:</strong>{" "}
                                      {new Date(
                                        scheduleItem.createdAt * 1000
                                      ).toLocaleString()}
                                    </p>
                                  </div>
                                  <div className="schedule-button">
                                    <button
                                      className="icon-btn"
                                      title="Edit"
                                      onClick={() => {
                                        // Add edit schedule functionality here
                                      }}
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <button
                                      className="icon-btn"
                                      title="Delete"
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteSlotModal"
                                       onClick={() => openDeleteModal(scheduleItem.scheduleName, null, null, scheduleItem.scheduleId)}

                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p>No schedules found.</p>
                            )}
                          </div>
                        ) : (
                          <WifiSchedule
                            isVisible={isScheduleModalOpen}
                            onClose={closeScheduleModal}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "filtering" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-filtering"
                    role="tabpanel"
                    aria-labelledby="pills-filtering-tab"
                  >
                    {/* =============URL Filtering================ */}
                    <UrlFiltering addUrl={addUrl} setAddUrl={setAddUrl} />
                  </div>
                )}
                {activeTab === "policies" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-policies"
                    role="tabpanel"
                    aria-labelledby="pills-policies-tab"
                  >
                    {/* =============URL Filtering================ */}
                    <TrafficPolicies />
                  </div>
                )}
                {activeTab === "roaming" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-roaming"
                    role="tabpanel"
                    aria-labelledby="pills-roaming-tab"
                  >
                    {/* =============Fast Roaming================ */}
                    <FastRoaming />
                  </div>
                )}
                {activeTab === "balancing" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-balancing"
                    role="tabpanel"
                    aria-labelledby="pills-balancing-tab"
                  >
                    {/* =============Load Balancing================ */}
                    <LoadBalancing />
                  </div>
                )}
                {activeTab === "management" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-management"
                    role="tabpanel"
                    aria-labelledby="pills-management-tab"
                  >
                    {/* =============VLAN Management================ */}
                    <VlanManagement />
                  </div>
                )}
                <div
                  className={`modal fade`}
                  id="deleteSsid"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content inc-modal">
                      <div className="modal-body success-msg">
                        <img src={errorIcon} alt="error" />
                        <p>
                          Are you sure to delete <strong>{ssid}</strong> SSID?
                        </p>
                        <button
                          className="text-btn primary-btn"
                          data-bs-dismiss="modal"
                          onClick={() => deleteSSID(wnId)}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`modal fade`}
                  id="deleteSlotModal"
                  tabIndex="-1"
                  aria-labelledby="deleteUrlLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content inc-modal">
                      <div className="modal-body success-msg">
                        <img src={errorIcon} alt="error" />
                        <p>
                          Are you sure you want to delete the Schedule Slots?
                        </p>
                        <button
                          className="text-btn primary-btn"
                          data-bs-dismiss="modal"
                          onClick={() => deleteSchedule(scheduleId)}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && <AddSSIDModal getList={getSSIDlist} />}
    </div>
  );
}

export default WifiConfig;
