import React from 'react'
import Chart from "react-apexcharts";
import Loader from "../../common/Loader";
import allApi from "../../../../api/allApi";
import { useNavigate } from "react-router-dom";
import ApexCharts from "react-apexcharts";
import { useEffect, useState } from "react";

function ChannelUtilizationExpandation() {
  const [ChannelSeries, setChannelSeries] = useState([]);
  const [ChannelCategories, setChannelCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [channelUtilizationDuration, setChannelUtilizationDuration] =
    useState("24h");
  const [ClientSeries, setClientSeries] = useState([]);
  const [newDuration, setNewDuration] = useState("24h");

  useEffect(() => {
    getChannelUtilizationData(channelUtilizationDuration);
  }, [channelUtilizationDuration]);

  const handleChannelUtilizationDurationChange = (channelUtilizationDuration) => {
    setChannelUtilizationDuration(channelUtilizationDuration);
  };

  const getChannelUtilizationData = async (channelUtilizationDuration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/totalChannelUtilization?commandType=5&duration=${channelUtilizationDuration}&networkId=6001`
      );
  
      // Check if the response status is OK and data exists
      if (response.status === "OK" && response.response) {
        const data = response.response; // Accessing the data correctly
        const seriesData = [];
        const categories = new Set(); // To store unique timestamps
  
        // Loop through each device
        data.forEach((device) => {
          const deviceSeries = {
            name: device.serialNumber, // Serial number as the legend
            data: [] // This will hold the data points
          };
  
          // Loop through each data entry for the device
          device.data.forEach((entry) => {
            const timestamp = new Date(entry.interval1).getTime(); // Convert 'interval1' (date) to a timestamp
            const totalUtilization = entry.totalUtilization; // Get total utilization
  
            // Push the data point into the device's series
            deviceSeries.data.push({
              x: timestamp, // Use timestamp for x-axis (time)
              y: totalUtilization // Total utilization for y-axis
            });
  
            categories.add(timestamp); // Add the timestamp to the category set
          });
  
          // Push each device's series data into the final series array
          seriesData.push(deviceSeries);
        });
  
        // Convert the set of categories (timestamps) into a sorted array for the x-axis
        const sortedCategories = Array.from(categories).sort((a, b) => a - b);
  
        // Log the series and categories (for debugging purposes)
        console.log("Final seriesData:", seriesData);
        console.log("Final categories (time):", sortedCategories);
  
        // Set the categories and series data for the chart
        setChannelCategories(sortedCategories); // This is for x-axis (time)
        setChannelSeries(seriesData); // This is for the chart's series (y-axis)
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching channel utilization data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  
  

  const ChannelOptions = {
    chart: {
      id: "channel-utilization-chart",
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "datetime",
      categories: ChannelCategories,
      labels: {
        formatter: (value) => {
          const date = new Date(value);

          // Check the duration and format accordingly
          if (channelUtilizationDuration === '7d' || channelUtilizationDuration === '30d') {
            const options = { day: '2-digit', month: 'short', timeZone: 'Asia/Kolkata' };
            return date.toLocaleString('en-IN', options); // Format: "dd MMM"
          } else {
            const options = { hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kolkata', hour12: false };
            return date.toLocaleString('en-IN', options); // Format: "HH:mm"
          }
        },
        style: {
          colors: '#9aa0ac',
          fontSize: '12px',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: function (val) {
          return val !== undefined ? Math.round(val) : "N/A";
        },
      },
      title: {
        text: "Channel Utilization (%)",
      },
    },
    stroke: {
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      showForSingleSeries: true,
      labels: {
        colors: "#9aa0ac",
        fontSize: "12px",
      }, // Display legend for each series
    },
    colors: ChannelSeries.map((_, index) => {
      // Assign different colors for each series
      const colorArray = ["#fc7594", "#00aaff", "#4caf50", "#ff9800"];
      return colorArray[index % colorArray.length];
    }),
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>
              Channel Utilization
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    All <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        2.4 HHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        6 GHz
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {channelUtilizationDuration === "24h"
                      ? "Last 24 Hrs"
                      : channelUtilizationDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleChannelUtilizationDurationChange("8h")
                        }
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleChannelUtilizationDurationChange("24h")
                        }
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleChannelUtilizationDurationChange("7d")
                        }
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleChannelUtilizationDurationChange("30d")
                        }
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              {/* <input className="list-search" type="text" /> */}
              <button className="icon-btn" title="Refresh">
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
              {/* <button className="icon-btn" title="Filter">
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button> */}
            </div>
          </div>
          <div className="inc-card-body">
            <div className='row'>
              <div className='col-lg-8'>
                {loading ? (
                  <Loader />
                ) : (
                  <div>
                    {ChannelSeries.length === 0 ? (
                      <div className="no-data-box">
                        <div className="no-data-text">No Data Available</div>
                      </div>
                    ) : (
                      <Chart
                        options={ChannelOptions}
                        series={ChannelSeries}
                        type="line"
                        height={170}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className='col-lg-4'>
                <div className='round-border'>
                  <div className="inc-card-table">
                    <table className="m-head">
                      <thead>
                        <tr>
                          <th>Devices</th>
                          <th>Channel Utilization</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ChannelSeries && ChannelSeries.length > 0 &&
                          ChannelSeries.map((seriesItem, seriesIndex) => (
                            seriesItem.data.map((dataItem, dataIndex) => (
                              <tr key={`${seriesIndex}-${dataIndex}`}>
                                <td>{seriesItem.name}</td>
                                <td>{dataItem.y}</td>
                              </tr>
                            ))
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChannelUtilizationExpandation;
