import React, { useEffect, useState } from "react";
import AllApi from "../../../api/allApi";
import "bootstrap/dist/css/bootstrap.min.css";
import PopupTagDevice from "../common/popupTagDevice";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const DeviceDetail = () => {
  const [parameters, setParameters] = useState([]);
  const [tags, setTags] = useState([]);
  const [lanData, setLanData] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [showTagPopUp, setShowTagPopUp] = useState(false);
  const [headerMessage, setHeaderMessage] = useState("");
  const [genieInfo, setDeviceInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const { deviceName } = useParams();
  const location = useLocation();
  const deviceData = location.state ? location.state.genieInfo : null;
  const [alarms, setAlarms] = useState([]);

  useEffect(() => {
    if (deviceData && deviceData.genieInfo) {
      setDeviceInfo(deviceData.genieInfo);
      setLoading(false);
    }
  }, [deviceData]);

  const handleSaveTagApiCall = async () => {
    try {
      const api = new AllApi();
      const deviceIDD = sessionStorage.getItem("deviceID");
      const payload = { deviceId: [deviceIDD], tag: tagInput };
      const response = await api.postRequest("api/genie/addTag", "", payload);
      if (response.ok) {
        setTags([...tags, tagInput]);
      } else {
        console.error("Failed to add tag");
      }
    } catch (error) {
      console.error("Error adding tag:", error);
    }
    setTagInput("");
  };

  const handleCloseTagModal = () => {
    setShowTagPopUp(false);
  };

  const getTagValueFromPopUp = (value) => {
    setTagInput(value);
  };

  const openTagPopUp = () => {
    const deviceIDD = sessionStorage.getItem("deviceID");
    setHeaderMessage(`${deviceIDD} says`);
    setShowTagPopUp(true);
  };

  return (
    <div>
      <div className="device-info-wrap mb-4">
        {loading ? (
          <div>Loading...</div>
        ) : genieInfo ? (
          <div className="inc-card info-card-4">
            <div className="inc-card-header">
              <div>
                <div className="inc-card-title">{genieInfo.id}</div>
                <div className="tags">
                  {tags?.map((tag, index) => (
                    <span className="tag" key={index}>
                      {tag}
                    </span>
                  ))}
                  <button onClick={openTagPopUp} className="text-btn">
                    + Tag
                  </button>
                </div>
              </div>
            </div>
            <div className="inc-card-body">
              <div className="device-info">
                <div>
                  <strong>Device ID:</strong>
                  <span>{genieInfo.deviceID.manufacturer}</span>
                </div>
                <div>
                  <strong>Product Class:</strong>
                  <span>{genieInfo.deviceID.productClass}</span>
                </div>
                <div>
                  <strong>Serial Number:</strong>{" "}
                  <span>{genieInfo.deviceID.serialNumber}</span>
                </div>
                <div>
                  <strong>Software Version:</strong>
                  <span>
                    {
                      genieInfo?.internetGatewayDevice?.genieInfo
                        ?.softwareVersion?.value
                    }
                  </span>
                </div>
                <div>
                  <strong>Hardware Version:</strong>
                  <span>
                    {
                      genieInfo.internetGatewayDevice.genieInfo.hardwareVersion
                        .value
                    }
                  </span>
                </div>
                <div>
                  <strong>IP Address:</strong>
                  <span>
                    {
                      genieInfo.internetGatewayDevice.wanDevice.wanDeviceConfig
                        .wanConnectionDevice.wanConnectionDeviceConfig
                        .wanIPConnection.wanIpConnectionConfig.externalIPAddress
                        .value
                    }
                  </span>
                </div>
                <div>
                  <strong>Last Inform:</strong>
                  <span>{genieInfo.lastInform}</span>
                </div>
                <div>
                <strong>Health:</strong>
                <span>{deviceData.health}</span>
              </div>
              </div>
            </div>
          </div>
        ) : (
          <div>No TR-069 device info found.</div>
        )}

        <div className="inc-card info-card-8">
          <div className="inc-card-header">
            <div className="inc-card-title">Lan Host</div>
          </div>
          <div className="inc-card-table">
            <table>
              <thead>
                <tr>
                  <th>Host Name</th>
                  <th>IP Address</th>
                  <th>MAC Address</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(genieInfo?.lanDevice?.lanDeviceConfig?.hosts) ? (
                  genieInfo.lanDevice.lanDeviceConfig.hosts.map(
                    (host, index) => (
                      <tr key={index}>
                        <td>{host.hostName}</td>
                        <td>{host.ipAddress}</td>
                        <td>{host.macAddress}</td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="3">No LAN hosts found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-header">
              <div className="inc-card-title">Device Alarms</div>
              <div className="inc-card-button">
                <button className="icon-btn">
                  <i className="bin icon"></i>
                </button>
                <button className="icon-btn">
                  <i className="refresh icon"></i>
                </button>
                <button className="icon-btn">
                  <i className="download icon"></i>
                </button>
              </div>
            </div>
            <div className="inc-card-table">
              <table>
                <thead>
                  <tr>
                    <th>Severity Level</th>
                    <th>Alarm Type</th>
                    <th>Is Acknowledged</th>
                    <th>Is Autoclear</th>
                    <th>Notification</th>
                    <th>Alarm Behaviour</th>
                    <th>Alarm Description</th>
                    <th>Alarm Time</th>
                    <th>Acked Time</th>
                    <th>Cleared time</th>
                    <th>Is Read</th>
                    <th>Event Name</th>
                    <th>Device Ipv6</th>
                  </tr>
                </thead>
                <tbody>
                  {alarms.map((alarm) => (
                    <tr key={alarm.alarm_id}>
                      <td>{alarm.severityLevel || "--"}</td>
                      <td>{alarm.alarmType || "--"}</td>
                      <td>{alarm.is_acknowledged ? "Yes" : "No"}</td>
                      <td>{alarm.is_autoclear}</td>
                      <td>{alarm.notification || "--"}</td>
                      <td>{alarm.alarm_behaviour || "--"}</td>
                      <td>{alarm.alarm_description || "--"}</td>
                      <td>{alarm.alarm_time || "--"}</td>
                      <td>{alarm.acked_time || "--"}</td>
                      <td>{alarm.cleared_time || "--"}</td>
                      <td>{alarm.is_read || "--"}</td>
                      <td>{alarm.event_name || "--"}</td>
                      <td>{alarm.device_ipv6 || "--"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-header">
              <div className="inc-card-title">All Parameters</div>
              <div className="inc-card-button">
                <button className="icon-btn">
                  <i className="upload icon"></i>
                </button>
                <button className="icon-btn">
                  <i className="bin icon"></i>
                </button>
                <button className="icon-btn">
                  <i className="refresh icon"></i>
                </button>
                <button className="icon-btn">
                  <i className="download icon"></i>
                </button>
              </div>
            </div>
            <div className="inc-card-table">
              <table className="parameter-table">
                <tbody>
                  {parameters.map((parameter, index) => (
                    <tr key={index}>
                      <th>{parameter.name}</th>
                      <td>{parameter.value}</td>
                      <td>
                        <i className="icon edit"></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <PopupTagDevice
        showPopUp={showTagPopUp}
        handleClose={handleCloseTagModal}
        getTagValue={getTagValueFromPopUp}
        handleSaveTag={handleSaveTagApiCall}
        headerMessage={headerMessage}
      />
    </div>
  );
};

export default DeviceDetail;
