import React, { useEffect, useState } from "react";
import allApi from "../../../../api/allApi";
import { all } from "axios";

function VlanManagement() {
  const [info, setInfo] = useState();
  const [showPopup,setShowPopup] = useState(false);
  const [list,setList] = useState([]);
  const [vlanFields,setVlanFields] = useState({
      untaggedVlanStatus: 0,
      untaggedVlanId: "",
      managementVlanId: ""
  });


  useEffect(() => {
    getVlanManagement();
    getList();
  }, []);

  const getVlanManagement = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        "api/wireless/getVlanManagement?networkId=6001"
      );
      if (response && response.response) {
        const data = response.response;
        console.log("data", data);
        setInfo(data);
        setVlanFields({
          managementVlanId: data.managementVlanId || "",
          untaggedVlanStatus: data.untaggedVlanStatus || 0,
          untaggedVlanId: data.untaggedVlanId || "",
        });
      }
    } catch (error) {
      console.error("Error in fetching VLAN info", error);
    }
  };

  const getList = async() =>{
    try{
     const api = new allApi();
     const response = await api.getRequest("api/vlan/list");
     if(response && Array.isArray(response)){
      setList(response);
     }
    }catch(error){
     console.error("Error fetching vlan list",error);
    }
  };

  const inputVlan = async () => {
    try {
      const api = new allApi();
      const payload = {
        managementVlanId: parseInt(vlanFields.managementVlanId),
        untaggedVlanStatus: vlanFields.untaggedVlanStatus,
        untaggedVlanId: parseInt(vlanFields.untaggedVlanId)

      }
      const response = await api.postRequest("api/wireless/vlanManagement?networkId=6001", "", payload);
      if (response) {
        console.log("Successfully sent vlan info", response);
      }
    } catch (error) {
      console.error("Error in payload for vlan", error);
    }
  };

  const handleVlanChange = (field,value) =>{
  setVlanFields({
    ...vlanFields, 
    [field] : field === "untaggedVlanStatus" ? parseInt(value) : value
  });
  };
  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-6">
          <label className="col-form-label">Management VLAN</label>
          <select className="form-select" 
          value={vlanFields.managementVlanId}
          onChange={(e)=>handleVlanChange("managementVlanId",parseInt(e.target.value))}>
            <option>Management VLAN</option>
            {list.map((vlan) => (
            <option key={vlan.vlan_id} value={vlan.vlan_id}>
              {vlan.vlan_name} ({vlan.vlan_id})
            </option>
          ))}
          </select>
        </div>
      </div>
      <div className="edit-custom-link">
        <a href="#" onClick={()=>setShowPopup(true)}>
          <i className="fa fa-plus-circle" aria-hidden="true"></i>
          Add Custom VLAN
        </a>
      </div>


      <div className="row mb-3 mt-4">
        <div className="col-lg-6">
          <label className="col-form-label label-check">
            Untagged VLAN
            <label className="checkbox">
              <input type="checkbox" 
              checked={vlanFields.untaggedVlanStatus == 1}
              value={vlanFields.untaggedVlanStatus}
              onChange={(e)=>handleVlanChange("untaggedVlanStatus",e.target.checked ? 1 : 0)}/>
              <span className="indicator"></span>
            </label>
          </label>
          <select className="form-select" 
          value={vlanFields.untaggedVlanId}
          onChange={(e)=>handleVlanChange("untaggedVlanId",parseInt(e.target.value))}>
            <option>Management VLAN</option>
            {list.map((vlan) => (
            <option key={vlan.vlan_id} value={vlan.vlan_id}>
              {vlan.vlan_name} ({vlan.vlan_id})
            </option>
          ))}
          </select>
        </div>
      </div>
      <div className="edit-custom-link">
        <a href="#" onClick={()=>setShowPopup(true)}>
          <i className="fa fa-plus-circle" aria-hidden="true"></i>
          Add Custom VLAN
        </a>
      </div>


      <div className="wifi-footer">
        <button type="button" className="btn btn-outline">Cancel</button>
        <button type="submit" className="text-btn primary-btn" onClick={()=>inputVlan()}>Save</button>
      </div>

      {showPopup && (
      <div className="customModal">
        <div className="c-modal-dialog modal-dialog-centered small">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add VLAN</h5>
              <button type="button" className="btn-close" onClick={()=>setShowPopup(false)}></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">VLAN Name</label>
                <input type="text" className="form-control" placeholder="www.example.com" />
              </div>
              <div className="mb-3">
                <label className="form-label">VLAN ID</label>
                <input type="text" className="form-control" placeholder="www.example.com" />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline" onClick={()=>setShowPopup(false)}>Cancel</button>
              <button type="submit" className="text-btn primary-btn">Save</button>
            </div>
          </div>
        </div>
      </div>
       )}
    </div>
  );
}

export default VlanManagement;
