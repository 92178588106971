import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/custom.css";
import "../src/assets/css/responsive.css";
import "../src/assets/css/icon.css";
import "../src/assets/css/theme.css";
import Login from "./app/component/login/Login.js";
import UserProfile from "./app/component/login/userProfile.js";
import ForgotPassword from "./app/component/login/forgotPassword.js";
// import Dashboard from "./app/component/dashboard/dashboard.js";
import Overview from "./app/component/dashboard/overview.js";
import DeviceListing from "./app/component/dashboard/deviceListing";
import ResetPassword from "./app/component/login/resetPassword";
import DeviceDetail from "./app/component/dashboard/deviceDetail";
import SnmpDevice from "./app/component/dashboard/snmpDevice";
import Layout from "./app/component/layout/Layout";
import Sidebar from "./app/component/common/Sidebar";
import Topology from "./app/component/dashboard/Topology.js";
import Alarms from "./app/component/dashboard/Alarms.js";
import SystemInfo from "./app/component/dashboard/systemInfo.js";
import OTAManagement from "./app/component/dashboard/OTAManagement.js";
import Audits from "./app/component/dashboard/Audits.js";
import DeviceDashboard from "./app/component/dashboard/DeviceDashboard.js";
import EdgeManagement from "./app/component/dashboard/EdgeManagement.js";
import DeviceDiscovery from "./app/component/dashboard/DeviceDiscovery.js";
import UserManagement from "./app/component/dashboard/UserManagement.js";
import SnmpTemplate from "./app/component/dashboard/snmpTemplate.js";
import TenantManagement from "./app/component/dashboard/TenantManagement.js";
import PrivateRoute from "./app/component/common/PrivateRoute";
import NotFound from "./app/component/common/NotFound.js";
import MQTT from "./app/component/dashboard/MQTT.js";
import TR069 from "./app/component/dashboard/TR069.js";
import Reports from "./app/component/dashboard/reports/Reports.js";
import DeviceSettings from "./app/component/dashboard/deviceSettings.js";
import DeviceLink from "./app/component/dashboard/deviceLink.js";
import LinkDetails from "./app/component/dashboard/LinkDetails.js";
import ReportList from "./app/component/dashboard/reports/reportList.js";
import Client from "./app/component/dashboard/Client.js";
import Troubleshoot from "./app/component/dashboard/Troubleshoot.js";
import WireDashboard from "./app/component/dashboard/wired/wireDashboard.js";
import WirelessDashboard from "./app/component/dashboard/wireless/wirelessDashboard.js";
import ClientExpandation from "./app/component/dashboard/wireless/ClientExpandation.js";
import TrafficBandwithExpandation from "./app/component/dashboard/wireless/trafficBandwidthExpandation.js";
import ConnectedClientExpandation from "./app/component/dashboard/wireless/ConnectedClientExpandation.js";
import ChannelUtilizationExpandation from "./app/component/dashboard/wireless/ChannelUtilizationExpandation.js";
import ClientDataExpandation from "./app/component/dashboard/wireless/ClientDataExpandation.js";
import WirelessDataExpandation from "./app/component/dashboard/wireless/WirelessDataExpandation.js";
import WifiConfig from "./app/component/dashboard/wireless/wifiConfig.js";
import WifiSetting from "./app/component/dashboard/wireless/wifiSetting.js";
import HttpsDetails from "./app/component/dashboard/HttpsDetail.js";
import WiredTrafficExpansion from "./app/component/dashboard/wired/WiredTrafficExpansion.js";
import WiredUsageExpansion from "./app/component/dashboard/wired/WiredUsageExpansion.js";
import WiredPowerExpansion from "./app/component/dashboard/wired/WiredpowerExpansion.js";
import WiredConfiguration from "./app/component/dashboard/wired/wiredConfiguration.js";
import HTTPS from "./app/component/dashboard/HTTPS.js";
import Notification from "./app/component/dashboard/Notification.js";
import ConfigureNetwork from "./app/component/dashboard/wired/configureNetwork.js";
import Chat from "./app/component/common/chat.js";
import HttpDashboard from "./app/component/dashboard/httpDashboard.js";
import { ThemeProvider } from './app/component/ThemeContext.js';
import ReportsPDF from "./app/component/dashboard/reports/reportPdf.js";

function App() {
  const location = useLocation();
  const hideSidebar =
    location.pathname === "/" ||
    location.pathname === "/forgot-password" ||
    location.pathname === "/reset-password";
    // location.pathname === "/not-found";

  return (
    <ThemeProvider> 
    <div className="app-container">
      
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/ReportsPDF" element={<ReportsPDF />} /> 
          {/* <Route path="/not-found" element={<NotFound />} /> */}

          {/* <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Layout heading="Dashboard">
                  <Dashboard />
                </Layout>
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/overview"
            element={
              <PrivateRoute>
                <Layout heading="Overview">
                  <Overview />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/device-listing"
            element={
              <PrivateRoute>
                <Layout heading="Device Management">
                  <DeviceListing />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/device-detail"
            element={
              <PrivateRoute>
                <Layout heading="DeviceDetail">
                  <DeviceDetail />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/device-snmp"
            element={
              <PrivateRoute>
                <Layout heading="Device Info">
                  <SnmpDevice />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/topology"
            element={
              <PrivateRoute>
                <Layout heading="Device Topology">
                  <Topology />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/OTAManagement"
            element={
              <PrivateRoute>
                <Layout heading="OTA Management">
                  <OTAManagement />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/alarms"
            element={
              <PrivateRoute>
                <Layout heading="Alerts">
                  <Alarms />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/system-info"
            element={
              <PrivateRoute>
                <Layout heading="System Information">
                  <SystemInfo />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/user-management"
            element={
              <PrivateRoute>
                <Layout heading="User Management">
                  <UserManagement />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/audits"
            element={
              <PrivateRoute>
                <Layout heading="Audits">
                  <Audits />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/device-dashboard"
            element={
              <PrivateRoute>
                <Layout heading="Device Dashboard">
                  <DeviceDashboard />
                </Layout>
              </PrivateRoute>
            }
          />
             <Route
            path="/http-dashboard"
            element={
              <PrivateRoute>
                <Layout heading="HTTPS Dashboard">
                  <HttpDashboard />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/device-settings"
            element={
              <PrivateRoute>
                <Layout heading="Device Settings">
                  <DeviceSettings />
                </Layout>
              </PrivateRoute>
            }
          />
           <Route
            path="/device-link"
            element={
              <PrivateRoute>
                <Layout heading="Link Module">
                  <DeviceLink />
                </Layout>
              </PrivateRoute>
            }
          />
           <Route
            path="/link-details"
            element={
              <PrivateRoute>
                <Layout heading="Link Module">
                  <LinkDetails />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/edge-management"
            element={
              <PrivateRoute>
                <Layout heading="Edge Management">
                  <EdgeManagement />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/device-discovery"
            element={
              <PrivateRoute>
                <Layout heading="Device Discovery">
                  <DeviceDiscovery />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/tenant-management"
            element={
              <PrivateRoute>
                <Layout heading="Tenant Management">
                  <TenantManagement />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/modal-management/create-template"
            element={
              <PrivateRoute>
                <Layout heading="SNMP Template">
                  <SnmpTemplate />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/modal-management/mqtt"
            element={
              <PrivateRoute>
                <Layout heading="MQTT">
                  <MQTT/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/modal-management/tr069"
            element={
              <PrivateRoute>
                <Layout heading="TR-069">{/* Your TR-069 component */}
                  <TR069/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/modal-management/https"
            element={
              <PrivateRoute>
                <Layout heading="HTTPS Management">
                  <HTTPS/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <PrivateRoute>
                <Layout heading="Reports">
                  <Reports/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/reports-listing"
            element={
              <PrivateRoute>
                <Layout heading="Reports">
                  <ReportList/>
                </Layout>                
              </PrivateRoute>
            }
          />
           <Route
            path="/wired"
            element={
              <PrivateRoute>
                <Layout heading="Wired Dashboard">
                  <WireDashboard/>
                </Layout>                
              </PrivateRoute>
            }
          />
           <Route
            path="/wireless"
            element={
              <PrivateRoute>
                <Layout heading="Wireless Dashboard">
                  <WirelessDashboard/>
                </Layout>                
              </PrivateRoute>
            }
          />
           <Route
            path="/client"
            element={
              <PrivateRoute>
                <Layout heading="Client">
                  <Client/>
                </Layout>                
              </PrivateRoute>
            }
          />
           <Route
            path="/troubleshoot"
            element={
              <PrivateRoute>
                <Layout heading="Troubleshoot">
                  <Troubleshoot/>
                </Layout>                
              </PrivateRoute>
            }
          />
           <Route
            path="/client-expandation"
            element={
              <PrivateRoute>
                <Layout heading="Wireless Dashboard">
                  <ClientExpandation/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/trafficBandwidth-expandation"
            element={
              <PrivateRoute>
                <Layout heading="Wireless Dashboard">
                  <TrafficBandwithExpandation/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/ConnectedClients-expandation"
            element={
              <PrivateRoute>
                <Layout heading="Wireless Dashboard">
                  <ConnectedClientExpandation/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/wirelessDataGraph-expandation"
            element={
              <PrivateRoute>
                <Layout heading="Wireless Dashboard">
                  <WirelessDataExpandation/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/ClientData-expandation"
            element={
              <PrivateRoute>
                <Layout heading="Wireless Dashboard">
                  <ClientDataExpandation/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/ChannelUtilization-expandation"
            element={
              <PrivateRoute>
                <Layout heading="Wireless Dashboard">
                  <ChannelUtilizationExpandation/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/wifi-settings"
            element={
              <PrivateRoute>
                <Layout heading="Wifi Settings">
                  <WifiSetting/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/wifi-config"
            element={
              <PrivateRoute>
                <Layout heading="Wifi Configuration">
                  <WifiConfig/>
                </Layout>                
              </PrivateRoute>
            }
          />
           <Route
            path="/https-info"
            element={
              <PrivateRoute>
                <Layout heading="Device Info">
                  <HttpsDetails/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/wiredtraffic-expansion"
            element={
              <PrivateRoute>
                <Layout heading="Wired Dashboard">
                  <WiredTrafficExpansion/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/wiredusage-expansion"
            element={
              <PrivateRoute>
                <Layout heading="Wired Usage">
                  <WiredUsageExpansion/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/wiredpower-expansion"
            element={
              <PrivateRoute>
                <Layout heading="Wired Power">
                  <WiredPowerExpansion/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/wired-config"
            element={
              <PrivateRoute>
                <Layout heading="Wired Configuration">
                  <WiredConfiguration/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/notification"
            element={
              <PrivateRoute>
                <Layout heading="Notification">
                  <Notification/>
                </Layout>                
              </PrivateRoute>
            }
          />
          <Route
            path="/configureNetwork"
            element={
              <PrivateRoute>
                <Layout heading="Configure Network">
                  <ConfigureNetwork/>
                </Layout>                
              </PrivateRoute>
            }
          />   
          <Route
            path="/chat"
            element={
              <PrivateRoute>
                <Layout heading="Chat">
                  <Chat/>
                </Layout>                
              </PrivateRoute>
            }
          />      
            <Route
            path="/network-management/wired-config"
            element={
              <PrivateRoute>
                <Layout heading="Wired Configuration">
                  <WiredConfiguration/>
                </Layout>                
              </PrivateRoute>
            }
          />   
          <Route
            path="/network-management/wifi-config"
            element={
              <PrivateRoute>
                <Layout heading="Wifi Configuration">
                  <WifiConfig/>
                </Layout>                
              </PrivateRoute>
            }
          />          
          <Route path="*" element={<NotFound />} /> 
        </Routes>
      </div>
    </div>
    </ThemeProvider>
  );
}

export default App;
