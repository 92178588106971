import React, { useEffect, useRef, useMemo, useState } from "react";
import * as d3 from "d3";
import towerIconSvg from "../../../assets/images/inc-cloud.svg";
import edgeIcon from "../../../assets/images/edge.svg";
import switchIcon from "../../../assets/images/switch.svg";
import apIcon from "../../../assets/images/access-point.svg";
import ubrIcon from "../../../assets/images/ubr.svg";
import masterIcon from "../../../assets/images/master-device.svg";
import slaveIcon from "../../../assets/images/slave-device.svg";
import laptopIcon from "../../../assets/images/laptop.svg";
import tabletIcon from "../../../assets/images/mobile.svg";

const D3Chart = ({ data, width = 1000, height = 600 }) => {
  const svgRef = useRef();
  const [selectedEdge, setSelectedEdge] = useState("");
  const [selectedDevice, setSelectedDevice] = useState(null);

  // Memoized force simulation
  const simulation = useMemo(() => {
    return d3.forceSimulation()
      .force("link", d3.forceLink().id((d) => d.id).distance(150))
      .force("charge", d3.forceManyBody().strength(-500))
      .force("center", d3.forceCenter(width / 3, height / 3));
  }, [width, height]);

  useEffect(() => {
    if (!data || (!data.edgeGateways && !data.wired && !data.wireless)) return;

    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    // Clear previous content to prevent duplication
    svg.selectAll("*").remove();

    // Create node and link containers
    const linkGroup = svg.append("g").attr("class", "links");
    const nodeGroup = svg.append("g").attr("class", "nodes");
    const labelGroup = svg.append("g").attr("class", "labels");

    let nodes = [];
    let links = [];

    // Add INC node as the center
    const INC = {
      id: "INC",
      label: `INC (${data.INC.Location})`,
      latitude: data.INC.latitude,
      longitude: data.INC.longitude,
      color: 'lightblue',
      icon: towerIconSvg,
      type: 'inc',  
    };
    nodes.push(INC);

    // SNMP (Edge Gateways)
    data.edgeGateways?.forEach((edge, index) => {
      const edgeNode = {
        id: `edge_${index}`,
        label: edge.edge_name,
        color: edge.status === 0 ? 'red' : 'lightgreen',
        icon: edgeIcon,
        type: 'edge',  
      };
      nodes.push(edgeNode);
      links.push({ source: "INC", target: `edge_${index}` });

      edge.devices?.forEach((device) => {
        const deviceNode = {
          id: device.mac_addess,
          label: device.device_name || device.mac_addess,
          color: device.health === 'Offline' ? 'red' : 'lightgreen',
          icon: device.mode === "Master" ? masterIcon : slaveIcon,
          type: 'master',  
        };
        nodes.push(deviceNode);
        links.push({ source: `edge_${index}`, target: device.mac_addess });

        device.slaves?.forEach((slave) => {
          const slaveNode = {
            id: slave.mac_addess,
            label: slave.device_name || slave.mac_addess,
            color: slave.health === 'Offline' ? 'red' : 'lightgreen',
            icon: slaveIcon,
            type: 'slave',  
          };
          nodes.push(slaveNode);
          links.push({ source: device.mac_addess, target: slave.mac_addess });
        });
      });
    });

    data.wired?.forEach((device, index) => {
      const wiredNode = {
        id: `wired_device_${index}`,
        label: device.device_name || `Wired Device ${index}`,
        color: device.status === 0 ? 'red' : 'lightgreen',
        icon: device.device_type === "Switch" ? switchIcon:apIcon,
        type: 'device',  
      };
      nodes.push(wiredNode);
      links.push({ source: "INC", target: `wired_device_${index}` });

      // Wired clients
      device.clients?.forEach((client, clientIndex) => {
        const clientNode = {
          id: `wired_client_${index}_${clientIndex}`,
          label: client.deviceName || `Client ${clientIndex}`,
          color: client.health === 'Offline' ? 'red' : 'lightgreen',
          icon: client.connDeviceType === 'Laptop/PC'
            ? laptopIcon
            : client.connDeviceType === 'Mobile/Tablet'
              ? tabletIcon
              : client.connDeviceType === 'INC Switch'  
                ? switchIcon
                : switchIcon,  

          type: 'client',  
        };
        nodes.push(clientNode);
        links.push({ source: `wired_device_${index}`, target: `wired_client_${index}_${clientIndex}` });
      });
    });

    // Wireless Devices
    data.wireless?.forEach((device, index) => {
      const wirelessNode = {
        id: `wireless_device_${index}`,
        label: device.device_name || `Wireless Device ${index}`,
        color: device.status === 0 ? 'red' : 'lightgreen',
        icon: device.device_type === "Switch" ? switchIcon:apIcon,
        type: 'device',  
      };
      nodes.push(wirelessNode);
      links.push({ source: "INC", target: `wireless_device_${index}` });

      // Wireless clients
      device.clients?.forEach((client, clientIndex) => {
        const clientNode = {
          id: `wireless_client_${index}_${clientIndex}`,
          label: client.deviceName || `Client ${clientIndex}`,
          color: client.health === 'Offline' ? 'red' : 'lightgreen',
          icon: client.connDeviceType === 'Laptop/PC'
            ? laptopIcon
            : client.connDeviceType === 'Mobile/Tablet'
              ? tabletIcon
              : client.connDeviceType === 'INC Switch'  
                ? switchIcon
                : switchIcon,
          type: 'client',  
        };
        nodes.push(clientNode);
        links.push({ source: `wireless_device_${index}`, target: `wireless_client_${index}_${clientIndex}` });
      });
    });

    // Create links and nodes
    const link = linkGroup.selectAll("path")
      .data(links)
      .enter()
      .append("path")
      .attr("stroke", "#999")
      .attr("stroke-width", 2)
      .attr("fill", "none")
      .attr("opacity", 0.6);

    const node = nodeGroup.selectAll("g")
      .data(nodes)
      .enter()
      .append("g")
      .call(d3.drag()
        .on("start", (event, d) => {
          if (!event.active) simulation.alphaTarget(0.3).restart();
          d.fx = d.x;
          d.fy = d.y;
        })
        .on("drag", (event, d) => {
          d.fx = event.x;
          d.fy = event.y;
        })
        .on("end", (event, d) => {
          if (!event.active) simulation.alphaTarget(0);
          d.fx = null;
          d.fy = null;
        })
      );

    // Function to determine node radius based on its type
    const getNodeRadius = (type) => {
      switch (type) {
        case 'inc': return 35;  // Largest size for INC
        case 'edge': return 30;  // Smaller for edge devices
        case 'master': return 25;  // Master devices
        case 'slave': return 20;  // Slaves
        case 'device': return 30;  // Regular devices
        case 'client': return 25;  // Smallest for clients
        default: return 20;  // Default size
      }
    };

    // Append circles to each node with different sizes
    node.append("circle")
      .attr("r", d => getNodeRadius(d.type))  // Set radius based on node type
      .attr("fill", d => d.color)
      .attr("stroke", "#fff")
      .attr("stroke-width", 1.5);

    // Append images to each node
    node.append("image")
      .attr("xlink:href", d => d.icon)  // Use the icon property from each node
      .attr("x", d => -getNodeRadius(d.type) + 3)  // Position the image at the center of the circle
      .attr("y", d => -getNodeRadius(d.type) + 3)
      .attr("width", d => getNodeRadius(d.type) * 2 - 6)
      .attr("height", d => getNodeRadius(d.type) * 2 - 6);

    const label = labelGroup.selectAll("text")
      .data(nodes)
      .enter()
      .append("text")
      .attr("dx", d => getNodeRadius(d.type) + 8)
      .attr("dy", ".35em")
      .text(d => d.label)
      .style("font-size", "10px")
      .attr('class', 'topology-label'); // Apply the CSS class

    // Update simulation
    simulation.nodes(nodes).on("tick", () => {
      link.attr("d", d => {
        const dx = d.target.x - d.source.x;
        const dy = d.target.y - d.source.y;
        const dr = Math.sqrt(dx * dx + dy * dy) * 0.8;
        const sweepFlag = dx > 0 ? 1 : 0;
        return `M${d.source.x},${d.source.y}A${dr},${dr} 0 0,${sweepFlag} ${d.target.x},${d.target.y}`;
      });

      node.attr("transform", d => `translate(${d.x},${d.y})`); // Move the entire group
      label.attr("x", d => d.x).attr("y", d => d.y);
    });

    simulation.force("link").links(links);

    // Cleanup
    return () => simulation.stop();
  }, [data, simulation, width, height]);

  return <svg ref={svgRef} style={{ width: "100%", height: "100%" }} />;
};

export default D3Chart;
