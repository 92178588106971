import React from "react";

function Reports() {
  return (
    <div>
      {/* <div className="d-none"> */}
      <h4>Report Profile</h4>
      <p>
        Provide report name, notes and define the profile for generation of
        report as once recursive.
      </p>
      <div className="row mb-35">
        <div className="col-lg-4">
          <label className="form-label">Report Name*</label>
          <input type="text" className="form-control" />
        </div>
        <div className="col-lg-4">
          <label className="form-label">Description</label>
          <input type="text" className="form-control" />
        </div>
      </div>

      <h4 className="mb-3">Report Type</h4>
      <div className="report-radio mb-3">
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="reporttype"
            id="reporttype1"
          />
          <label className="form-check-label" for="reporttype1">
            Custom Report
          </label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="reporttype"
            id="reporttype2"
          />
          <label className="form-check-label" for="reporttype2">
            Troubleshooting Report
          </label>
        </div>
      </div>
      <h4>Execution Period</h4>
      <div className="report-radio mb-35">
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="reporttime"
            id="reporttime1"
          />
          <label className="form-check-label" for="reporttime1">
            Run Once
          </label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="reporttime"
            id="reporttime2"
          />
          <label className="form-check-label" for="reporttime2">
            Scheduled
          </label>
        </div>
      </div>
      <h4>Time Period</h4>
      <div className="row mb-35">
        <div className="col-lg-4">
          <select className="form-select" id="time" name="time">
            <option value="">Last 24 Hours</option>
            <option value="">Last 7 Days</option>
            <option value="">Last Week</option>
            <option value="">Last Month</option>
          </select>
        </div>
      </div>

      <h4>Email Address</h4>
      <p>
        Please enter commas separated email address for all the recipients to
        whom reports should be sent.
      </p>
      <div className="row mb-35">
        <div className="col-lg-7">
          <input type="text" className="form-control" />
        </div>
      </div>

      <h4 className="mb-2">Branding Logo</h4>
      <div className="report-radio mb-4">
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="logo"
            id="logo1"
          />
          <label className="form-check-label" for="logo1">
            Use VAR Logo
          </label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="logo"
            id="logo2"
          />
          <label className="form-check-label" for="logo2">
            Use Organization Logo
          </label>
        </div>
      </div>
    </div>
  );
}

export default Reports;
