import React from "react";
import TrafficFilter from "./trafficFilter";
import AllowTraffic from "./allowTraffic";

const TrafficPolicies = () => {
    return (
        <div className="tab-form">
            <div className="tab-inside-tab">
                <ul className="nav nav-underline" id="policiesTab" role="tablist">
                    <li className="nav-item" role="trafficpolicies">
                        <button
                            className="nav-link um active"
                            id="broadcast-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#broadcast-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="broadcast-tab-pane"
                            aria-selected="true"
                        >
                            Allow Broadcast/Multicast Traffic
                        </button>
                    </li>
                    <li className="nav-item" role="trafficpolicies">
                        <button
                            className="nav-link rm"
                            id="mac-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#mac-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="mac-tab-pane"
                            aria-selected="false"
                        >
                            MAC/IP Traffic Filters
                        </button>
                    </li>
                </ul>
            </div>
            <div className="tab-content" id="policiesTabContent">
                <div
                    className="tab-pane fade show active"
                    id="broadcast-tab-pane"
                    role="tabpanel"
                    aria-labelledby="broadcast-tab"
                    tabIndex="0"
                >
                    <AllowTraffic/>
                </div>
                <div
                    className="tab-pane fade show"
                    id="mac-tab-pane"
                    role="tabpanel"
                    aria-labelledby="mac-tab"
                    tabIndex="0"
                >
                    <TrafficFilter />
                </div>
            </div>
        </div>
    );
};

export default TrafficPolicies;
