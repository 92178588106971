import React from "react";

const TrafficFilter = () => {
    return (
        <div>
            <div className="accordion" id="trafficFilter">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                        >
                            Wireless Noise Filter
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#trafficFilter">
                        <div className="accordion-body">

                            <div className="tab-form">
                                <div className="row mb-4">
                                    <div className="col-lg-6">
                                        <div className="switch-box">
                                            <strong>Enable Wireless Noise Filter</strong>
                                            <label className="ice-switch">
                                                <input type="checkbox" />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="inc-card-table">
                                <table className="m-head">
                                    <thead>
                                        <tr>
                                            <th>Priority</th>
                                            <th>Rule Name</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                            <th>Protocol</th>
                                            <th>Source Address</th>
                                            <th>Source Port</th>
                                            <th>Destination Address</th>
                                            <th>Destination Port</th>
                                            <th>Direction</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>-</td>
                                            <td>Drop DHCP Request towards SSID</td>
                                            <td>Enable</td>
                                            <td>Deny</td>
                                            <td>UDP</td>
                                            <td>ANY</td>
                                            <td>-</td>
                                            <td>ANY</td>
                                            <td>67</td>
                                            <td>Out</td>
                                            <td>
                                                <div className="button-group">
                                                    <button title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                            <td>Drop DHCP Request towards SSID</td>
                                            <td>Enable</td>
                                            <td>Deny</td>
                                            <td>UDP</td>
                                            <td>ANY</td>
                                            <td>-</td>
                                            <td>ANY</td>
                                            <td>67</td>
                                            <td>Out</td>
                                            <td>
                                                <div className="button-group">
                                                    <button title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                            <td>Drop DHCP Request towards SSID</td>
                                            <td>Enable</td>
                                            <td>Deny</td>
                                            <td>UDP</td>
                                            <td>ANY</td>
                                            <td>-</td>
                                            <td>ANY</td>
                                            <td>67</td>
                                            <td>Out</td>
                                            <td>
                                                <div className="button-group">
                                                    <button title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                            <td>Drop DHCP Request towards SSID</td>
                                            <td>Enable</td>
                                            <td>Deny</td>
                                            <td>UDP</td>
                                            <td>ANY</td>
                                            <td>-</td>
                                            <td>ANY</td>
                                            <td>67</td>
                                            <td>Out</td>
                                            <td>
                                                <div className="button-group">
                                                    <button title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                            <td>Drop DHCP Request towards SSID</td>
                                            <td>Enable</td>
                                            <td>Deny</td>
                                            <td>UDP</td>
                                            <td>ANY</td>
                                            <td>-</td>
                                            <td>ANY</td>
                                            <td>67</td>
                                            <td>Out</td>
                                            <td>
                                                <div className="button-group">
                                                    <button title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="accordion-footer">
                                <button type="button" className="btn btn-outline">Cancel</button>
                                <button type="button" className="btn btn-fill">Apply</button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                        >
                            Global Traffic Filter
                        </button>
                    </h2>
                    <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#trafficFilter"
                    >
                        <div className="accordion-body">

                            <div className="tab-form">
                                <div className="row mb-4">
                                    <div className="col-lg-6">
                                        <div className="switch-box">
                                            <strong>Enable Global Traffic Filter</strong>
                                            <label className="ice-switch">
                                                <input type="checkbox" />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 traffic-btn">
                                        <button className="icon-btn" title="Add"><i className="fa fa-plus" aria-hidden="true"></i></button>
                                        <button className="icon-btn" title="Delete"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>

                            <div className="inc-card-table">
                                <table className="m-head">
                                    <thead>
                                        <tr>
                                            <th>Priority</th>
                                            <th>Rule Name</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                            <th>Protocol</th>
                                            <th>Source Address</th>
                                            <th>Source Port</th>
                                            <th>Destination Address</th>
                                            <th>Destination Port</th>
                                            <th>Direction</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>-</td>
                                            <td>Drop DHCP Request towards SSID</td>
                                            <td>Enable</td>
                                            <td>Deny</td>
                                            <td>UDP</td>
                                            <td>ANY</td>
                                            <td>-</td>
                                            <td>ANY</td>
                                            <td>67</td>
                                            <td>Out</td>
                                            <td>
                                                <div className="button-group">
                                                    <button title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                            <td>Drop DHCP Request towards SSID</td>
                                            <td>Enable</td>
                                            <td>Deny</td>
                                            <td>UDP</td>
                                            <td>ANY</td>
                                            <td>-</td>
                                            <td>ANY</td>
                                            <td>67</td>
                                            <td>Out</td>
                                            <td>
                                                <div className="button-group">
                                                    <button title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                            <td>Drop DHCP Request towards SSID</td>
                                            <td>Enable</td>
                                            <td>Deny</td>
                                            <td>UDP</td>
                                            <td>ANY</td>
                                            <td>-</td>
                                            <td>ANY</td>
                                            <td>67</td>
                                            <td>Out</td>
                                            <td>
                                                <div className="button-group">
                                                    <button title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                            <td>Drop DHCP Request towards SSID</td>
                                            <td>Enable</td>
                                            <td>Deny</td>
                                            <td>UDP</td>
                                            <td>ANY</td>
                                            <td>-</td>
                                            <td>ANY</td>
                                            <td>67</td>
                                            <td>Out</td>
                                            <td>
                                                <div className="button-group">
                                                    <button title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                            <td>Drop DHCP Request towards SSID</td>
                                            <td>Enable</td>
                                            <td>Deny</td>
                                            <td>UDP</td>
                                            <td>ANY</td>
                                            <td>-</td>
                                            <td>ANY</td>
                                            <td>67</td>
                                            <td>Out</td>
                                            <td>
                                                <div className="button-group">
                                                    <button title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="accordion-footer">
                                <button type="button" className="btn btn-outline">Cancel</button>
                                <button type="button" className="btn btn-fill">Apply</button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                        >
                            Custom Noise Filter
                        </button>
                    </h2>
                    <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#trafficFilter"
                    >
                        <div className="accordion-body">

                            <div className="tab-form">
                                <div className="row mb-4">
                                    <div className="col-lg-6">
                                        <div className="switch-box">
                                            <strong>Enable Custom ACL</strong>
                                            <label className="ice-switch">
                                                <input type="checkbox" />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 traffic-btn">
                                        <button className="icon-btn" title="Add"><i className="fa fa-plus" aria-hidden="true"></i></button>
                                        <button className="icon-btn" title="Delete"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                    </div>
                                </div>

                            </div>

                            <div className="inc-card-table">
                                <table className="m-head">
                                    <thead>
                                        <tr>
                                            <th>Priority</th>
                                            <th>Rule Name</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                            <th>Protocol</th>
                                            <th>Source Address</th>
                                            <th>Source Port</th>
                                            <th>Destination Address</th>
                                            <th>Destination Port</th>
                                            <th>Direction</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>-</td>
                                            <td>Drop DHCP Request towards SSID</td>
                                            <td>Enable</td>
                                            <td>Deny</td>
                                            <td>UDP</td>
                                            <td>ANY</td>
                                            <td>-</td>
                                            <td>ANY</td>
                                            <td>67</td>
                                            <td>Out</td>
                                            <td>
                                                <div className="button-group">
                                                    <button title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                            <td>Drop DHCP Request towards SSID</td>
                                            <td>Enable</td>
                                            <td>Deny</td>
                                            <td>UDP</td>
                                            <td>ANY</td>
                                            <td>-</td>
                                            <td>ANY</td>
                                            <td>67</td>
                                            <td>Out</td>
                                            <td>
                                                <div className="button-group">
                                                    <button title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                            <td>Drop DHCP Request towards SSID</td>
                                            <td>Enable</td>
                                            <td>Deny</td>
                                            <td>UDP</td>
                                            <td>ANY</td>
                                            <td>-</td>
                                            <td>ANY</td>
                                            <td>67</td>
                                            <td>Out</td>
                                            <td>
                                                <div className="button-group">
                                                    <button title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                            <td>Drop DHCP Request towards SSID</td>
                                            <td>Enable</td>
                                            <td>Deny</td>
                                            <td>UDP</td>
                                            <td>ANY</td>
                                            <td>-</td>
                                            <td>ANY</td>
                                            <td>67</td>
                                            <td>Out</td>
                                            <td>
                                                <div className="button-group">
                                                    <button title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                            <td>Drop DHCP Request towards SSID</td>
                                            <td>Enable</td>
                                            <td>Deny</td>
                                            <td>UDP</td>
                                            <td>ANY</td>
                                            <td>-</td>
                                            <td>ANY</td>
                                            <td>67</td>
                                            <td>Out</td>
                                            <td>
                                                <div className="button-group">
                                                    <button title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="accordion-footer">
                                <button type="button" className="btn btn-outline">Cancel</button>
                                <button type="button" className="btn btn-fill">Apply</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrafficFilter;
