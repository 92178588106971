import React, { useState, useEffect } from "react";
import successIcon from "../../../assets/images/success_lg.png";
import errorIcon from "../../../assets/images/error-2.png";
import allApi from "../../../api/allApi";
import Loader from "../common/Loader";
import waitIcon from "../../../assets/images/hourglass.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import $ from "jquery";
import InputMask from "react-input-mask";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

const DeviceDiscovery = ({ addDeviceToListing }) => {
  const [snmpFields, setSnmpFields] = useState({
    startIp: "",
    endIp: "",
    ipType: "",
    snmpID: "",
    snmpTemplate: "",
    snmpGateway: "",
  });
  const mqttDefaultValues = {
    start_ip: "",
    snmp_template: "",
    gateway_id: "",
    mac_address: "",
  };
  const [mqttFields, setMqttFields] = useState(mqttDefaultValues);
  const [tr069Fields, setTr069Fields] = useState({
    manufacturer: "",
    oui: "",
    serialNo: "",
    productClass: "",
  });
  const [httpsFields, setHttpsFields] = useState({
    serialNo: "",
    macAddress: "",
  });
  const initialDiscoverResponseData =
    JSON.parse(localStorage.getItem("discoverResponseData")) || [];
  const [discoverResponseData, setDiscoverResponseData] = useState([]);

  const [snmpTemplates, setSnmpTemplates] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("snmp");
  const [showNoDeviceSelectedPopup, setShowNoDeviceSelectedPopup] =
    useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [deviceAddedMessageVisible, setDeviceAddedMessageVisible] =
    useState(false);
  const [showSuccessMessageModal, setShowSuccessMessageModal] = useState(false);
  const [mqttDeviceMessage, setMqttDeviceMessage] = useState("");
  const [gateways, setGateways] = useState([]);
  const [error, setError] = useState("");
  const [errorDiscovery, setErrorDiscovery] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isDiscoverButtonDisabled, setIsDiscoverButtonDisabled] =
    useState(true);
  const [isAddMqttDisabled, setIsAddMqttDisabled] = useState(true);
  const [isTRDisabled, setTRDisabled] = useState(true);
  const [isAddHttpDisabled, setIsAddHttpDisabled] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [discoverError, setDiscoverError] = useState(false);
  const macAddressRef = useRef(null);
  const [startIpError, setStartIpError] = useState("");
  const [endIpError, setEndIpError] = useState("");
  const [deviceManagedError, setDeviceManagedError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setActiveTab("snmp");
    fetchSnmpTemplates();
    getEdgeDevices();
  }, []);

  const fetchSnmpTemplates = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/snmp/templates/all");
      console.log("RES", response);
      if (response) {
        setSnmpTemplates(response);
      } else {
        console.error(
          "Error fetching SNMP templates: Invalid response or data structure."
        );
      }
    } catch (error) {
      console.error("Error fetching SNMP templates:", error);
    }
  };
  async function getEdgeDevices() {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/gateway/all");
      if (response && Array.isArray(response)) {
        setGateways(response);
      }
    } catch (error) {
      setError("Error fetching gateways");
    }
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab == "mqtt") {
      setMqttFields({ ...mqttDefaultValues });
    } else if (tab == "tr069") {
      setTr069Fields({ ...tr069Fields });
    } else if (tab == "https") {
      setHttpsFields({ ...httpsFields });
    }
  };

  const handleDiscover = async () => {
    try {
      setIsLoading(true);
      const api = new allApi();
      const payload = {
        startIP: snmpFields.startIp,
        endIP: snmpFields.endIp,
        snmpID: snmpFields.snmpTemplate,
        ipType: "IPv4",
        gateway_id: parseInt(snmpFields.snmpGateway),
      };
      const response = await api.postRequest("api/snmp/discover", "", payload);
      if (response.status === 204 || response.data === "No device discovered") {
        setDiscoverResponseData([]);
        setErrorDiscovery(true);
      } else {
        setDiscoverResponseData(response.data);
        setErrorDiscovery(false);
      }
    } catch (error) {
      console.error("Error discovering devices:", error);
      setErrorDiscovery(true);
    } finally {
      setIsLoading(false);
      setSnmpFields({
        startIp: "",
        endIp: "",
        ipType: "",
        snmpTemplate: "",
        snmpGateway: "",
      });
    }
  };

  const isValidIP = (ip) => {
    const trimmedIp = ip.trim();
    const regex =
      /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-7]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}$/;
    return regex.test(trimmedIp);
  };

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;

    if (activeTab === "snmp") {
      setSnmpFields((prevState) => {
        const newState = {
          ...prevState,
          [fieldName]: value,
        };
        if (
          newState.startIp &&
          newState.endIp &&
          newState.ipType &&
          newState.snmpTemplate
        ) {
          setIsDiscoverButtonDisabled(false);
        } else {
          setIsDiscoverButtonDisabled(true);
        }
        return newState;
      });
    } else if (activeTab === "mqtt") {
      setMqttFields((prevState) => {
        const newState = {
          ...prevState,
          [fieldName]: value,
        };
        if (
          newState.start_ip &&
          newState.snmp_template &&
          newState.mac_address
        ) {
          setIsAddMqttDisabled(false);
        } else {
          setIsAddMqttDisabled(true);
        }
        return newState;
      });
    } else if (activeTab === "tr069") {
      setTr069Fields((prevState) => {
        const newState = {
          ...prevState,
          [fieldName]: value,
        };
        if (
          newState.manufacturer &&
          newState.oui &&
          newState.serialNo &&
          newState.productClass
        ) {
          setTRDisabled(false);
        } else {
          setTRDisabled(true);
        }
        return newState;
      });
    } else if (activeTab === "https") {
      const { value } = e.target;
      setHttpsFields((prevState) => {
        const newState = {
          ...prevState,
          [fieldName]: value,
        };

        if (newState.serialNo && newState.macAddress && newState.deviceName) {
          setIsAddHttpDisabled(false);
        } else {
          setIsAddHttpDisabled(true);
        }

        return newState;
      });
    }
  };

  async function getEdgeDevices() {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/gateway/all");
      if (response && Array.isArray(response)) {
        setGateways(response);
      }
    } catch (error) {
      setError("Error fetching gateways");
    }
  }

  const handleHTTPSDevice = async () => {
    setIsAddHttpDisabled(true);

    try {
      const api = new allApi();
      const payload = {
        macAddress: httpsFields.macAddress,
        serialNumber: httpsFields.serialNo,
        deviceName: httpsFields.deviceName,
        deviceType: "http",
      };
      const response = await api.postRequest("api/http/onboard", "", payload);

      if (response) {
        setHttpsFields({
          serialNo: "",
          macAddress: "",
          deviceName: "",
        });

        setShowSuccessModal(true);
        await getEdgeDevices();
      } else {
        console.error("Error adding device:", response);
      }
    } catch (error) {
      console.error("Network error:", error);
    } finally {
      setIsAddHttpDisabled(false);
    }
  };

  const cancelClicked = () => {
    if (activeTab === "snmp") {
      setSnmpFields({
        startIp: "",
        endIp: "",
        ipType: "",
        snmpTemplate: "",
        snmpGateway: "",
      });
    } else if (activeTab === "mqtt") {
      setMqttFields({ ...mqttDefaultValues });
    } else if (activeTab === "tr069") {
      setTr069Fields({
        manufacturer: "",
        oui: "",
        serialNo: "",
        productClass: "",
      });
      setTRDisabled(true);
    } else if (activeTab === "https") {
      setHttpsFields({
        serialNo: "",
        macAddress: "",
        deviceName: "",
      });
      setIsAddHttpDisabled(true);
    }
  };

  const addMqttDevice = async () => {
    try {
      const api = new allApi();
      let response = await api.postRequest(`api/mqtt/onboard`, "", mqttFields);
      setMqttFields({ ...mqttDefaultValues });
      setMqttDeviceMessage(response.msg);
      if (response && response.status === 200) {
        const addedDevice = response.data;
        addDeviceToListing(addedDevice);
        setDiscoverResponseData((prevData) => [...prevData, addedDevice]);
      } else {
        console.error("Failed to add MQTT device. Response:", response);
      }
    } catch (error) {
      console.error("Error adding new device:", error);
    }
  };

  const handleAddDeviceToListing = async (device) => {
    try {
      addDeviceToListing(device);
      setShowSuccessModal(false);
    } catch (error) {
      console.error("Error adding device to listing:", error);
    }
  };
  const checkFields = () => {
    for (const field in mqttFields) {
      if (field !== "gateway_id" && mqttFields[field] === "") {
        return true;
      }
    }
    for (const field in snmpFields) {
      if (snmpFields[field] === "") {
        return true;
      }
    }

    return false;
  };

  const closePopUp = () => {
    setMqttFields({
      start_ip: "",
      mac_address: "",
      snmp_template: "",
    });
  };

  const addTRDevice = async () => {
    try {
      const api = new allApi();
      const response = await api.postRequest("api/genie/onboard/device", "", {
        Manufacture: tr069Fields.manufacturer,
        OUI: tr069Fields.oui,
        ProductClass: tr069Fields.productClass,
        SerialNumber: tr069Fields.serialNo,
      });

      if (response.status === 200) {
        // Show success popup
        $("#trAddedMessage").modal("show");
        console.log("TR-069 Device added successfully:", response);
        setTr069Fields({
          manufacturer: "",
          oui: "",
          serialNo: "",
          productClass: "",
        });
      } else {
        // Show error popup
        $("#errorPopup").modal("show");
      }
    } catch (error) {
      console.error("Error adding new device:", error);
    }
  };

  const handleDeviceSelect = (device) => {
    console.log("Selected Device:", device);
    const selectedIndex = selectedDevices.findIndex(
      (selectedDevice) => selectedDevice.deviceIP === device.deviceIP
    );
    if (selectedIndex === -1) {
      setSelectedDevices([...selectedDevices, device]);
    } else {
      const updatedDevices = [...selectedDevices];
      updatedDevices.splice(selectedIndex, 1);
      setSelectedDevices(updatedDevices);
    }
  };
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectedDevices(isChecked ? discoverResponseData : []);
    setSelectAllChecked(isChecked);
  };

  const handleAddDevices = async () => {
    if (selectedDevices.length === 0) {
      setShowNoDeviceSelectedPopup(true);
      return;
    } else {
      setSuccessMessageVisible(true);
    }
    try {
      const api = new allApi();
      let array = gateways.filter(
        (item) => item.gateway_name == selectedDevices[0].gateway_name
      );
      const payload = selectedDevices.map((device) => ({
        deviceIP: device.deviceIP,
        deviceName: device.deviceName,
        deviceMac: device.deviceMac,
        deviceType: device.deviceType,
        snmpID: device.snmpID,
        workingMode: device.workingMode,
        deviceModel: device.deviceModel,
        gateway_id: array.length > 0 ? array[0].request_id : null, 
        protocol_type: "SNMP",
      }));
      const response = await api.postRequest("api/snmp/add_snmp", "", payload);
      if (response) {
        selectedDevices.forEach((device) => {
          handleAddDeviceToListing(device);
        });
        setSelectedDevices([]);
        setDiscoverResponseData((prevData) =>
          prevData.filter(
            (device) =>
              !selectedDevices.some(
                (selectedDevice) => selectedDevice.deviceIP === device.deviceIP
              )
          )
        );
        setShowSuccessPopup(true);
      } else {
        console.error("Failed to add device. Response:", response);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.msg ===
          "Duplicate key value violates unique constraint."
      ) {
        setErrorMessage("A device with the same IP address already exists.");
        setShowErrorMessage(true);
        setTimeout(() => {
          setShowErrorMessage(false);
          setErrorMessage("");
        }, 3000);
      } else {
        console.error("Error adding device:", error);
      }
    }
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    navigate("/device-listing");
    closePopUp();
  };
  const successhandleClose = () => setShowSuccessModal(false);
  const successhandleShow = () => setShowSuccessModal(true);
  const disableDiscover = !(
    snmpFields.startIp &&
    snmpFields.endIp &&
    snmpFields.ipType &&
    snmpFields.snmpTemplate &&
    snmpFields.snmpGateway &&
    !startIpError &&
    !endIpError
  );

  const handleStartIp = (e) => {
    const value = e.target.value;
    setSnmpFields({ ...snmpFields, startIp: value });
    if (value && !isValidIP(value)) {
      setStartIpError("Please enter valid IP");
    } else {
      setStartIpError("");
    }
  };
  const handleEndIp = (e) => {
    const value = e.target.value;
    setSnmpFields({ ...snmpFields, endIp: value });
    if (value && !isValidIP(value)) {
      setEndIpError("Please enter valid IP");
    } else {
      setEndIpError("");
    }
  };

  return (
    <div>
      {error && <div className="error">{error}</div>}
      <div className="inc-card ch-100">
        <div className="inc-card-title">
          <ul className="nav nav-underline" id="discoverTab" role="tablist">
            <li className="nav-item" role="discover">
              <button
                className={`nav-link ${activeTab === "snmp" ? "active" : ""}`}
                id="snmp-tab"
                data-bs-toggle="tab"
                data-bs-target="#snmp-tab-pane"
                type="button"
                role="tab"
                aria-controls="snmp-tab-pane"
                aria-selected={activeTab === "snmp" ? "true" : "false"}
                onClick={() => handleTabChange("snmp")}
              >
                SNMP
              </button>
            </li>
            <li className="nav-item" role="discover">
              <button
                className={`nav-link ${activeTab === "mqtt" ? "active" : ""}`}
                id="mqtt-tab"
                data-bs-toggle="tab"
                data-bs-target="#mqtt-tab-pane"
                type="button"
                role="tab"
                aria-controls="mqtt-tab-pane"
                aria-selected={activeTab === "mqtt" ? "true" : "false"}
                onClick={() => handleTabChange("mqtt")}
              >
                MQTT
              </button>
            </li>
            <li className="nav-item" role="discover">
              <button
                className={`nav-link ${activeTab === "tr069" ? "active" : ""}`}
                id="tr069-tab"
                data-bs-toggle="tab"
                data-bs-target="#tr69-tab-pane"
                type="button"
                role="tab"
                aria-controls="tr69-tab-pane"
                aria-selected={activeTab === "tr069" ? "true" : "false"}
                onClick={() => handleTabChange("tr069")}
              >
                TR-069
              </button>
            </li>
            <li className="nav-item" role="discover">
              <button
                className={`nav-link ${activeTab === "https" ? "active" : ""}`}
                id="https-tab"
                data-bs-toggle="tab"
                data-bs-target="#https-tab-pane"
                type="button"
                role="tab"
                aria-controls="https-tab-pane"
                aria-selected={activeTab === "https" ? "true" : "false"}
                onClick={() => handleTabChange("https")}
              >
                HTTPS
              </button>
            </li>
          </ul>
          <div className="inc-card-button">
            {activeTab === "snmp" && (
              <button
                className="text-btn primary-btn"
                data-bs-toggle="modal"
                disabled={selectedDevices.length < 2}
                data-bs-target={
                  selectedDevices.length === 0
                    ? "#noDeviceSelectedPopup"
                    : "#deviceAddedMessagePopup"
                }
                onClick={() => {
                  if (selectedDevices.length === 0) {
                    setShowNoDeviceSelectedPopup(true);
                  } else {
                    setShowSuccessMessageModal(true);
                    handleAddDevices();
                  }
                }}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
                Add Devices
              </button>
            )}
          </div>
        </div>
        <div className="inc-card-body">
          <div>
            <div className="tab-content mt-4" id="discoverTabContent">
              <div
                className={`tab-pane fade ${
                  activeTab === "snmp" && "active show"
                }`}
                id="snmp-tab-pane"
                role="tabpanel"
              >
                {activeTab === "snmp" && (
                  <div>
                    <div className="discover-form">
                      <div className="row">
                        <div className="col-lg-6">
                          <label className="form-label">
                            Start IP<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Start IP"
                            id="startIp"
                            name="startIp"
                            value={snmpFields.startIp}
                            onChange={handleStartIp}
                          />{" "}
                          {startIpError && (
                            <p className="text-danger">{startIpError}</p>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="form-label">
                            End IP<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter End IP"
                            id="endIp"
                            name="endIp"
                            value={snmpFields.endIp}
                            onChange={handleEndIp}
                          />{" "}
                          {endIpError && (
                            <p className="text-danger">{endIpError}</p>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <label className="form-label">
                            IP Version<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            id="ipType"
                            name="ipType"
                            value={snmpFields.ipType}
                            onChange={(e) => handleInputChange(e, "ipType")}
                          >
                            <option value="">Select Type</option>
                            <option value="ipType">IPv4</option>
                            <option value="ipType">IPv6</option>
                          </select>
                        </div>
                        <div className="col-lg-4">
                          <label className="form-label">
                            Device Template
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            id="snmpTemplate"
                            name="snmpTemplate"
                            value={snmpFields.snmpTemplate}
                            onChange={(e) =>
                              handleInputChange(e, "snmpTemplate")
                            }
                          >
                            <option value="">Select Template</option>
                            {snmpTemplates.map((template, index) => (
                              <option
                                key={index}
                                value={template.snmp_template_id}
                              >
                                {template.template_name}
                              </option>
                            ))}
                          </select>{" "}
                        </div>
                        <div className="col-lg-4">
                          <label className="form-label">Gateway Name
                          <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            id="snmpGateway"
                            name="snmpGateway"
                            value={snmpFields.snmpGateway}
                            onChange={(e) =>
                              handleInputChange(e, "snmpGateway")
                            }
                          >
                            <option value="">Select Gateway</option>
                            <option value="">N/A</option>
                            {gateways.map((gateway, index) => (
                              <option key={index} value={gateway.request_id}>
                                {gateway.gateway_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="discover-button mt-3">
                        <button
                          className="text-btn btn-outline"
                          onClick={() => cancelClicked()}
                        >
                          Cancel
                        </button>
                        <button
                          className={`text-btn primary-btn ${
                            isDiscoverButtonDisabled ? "disabled" : ""
                          }`}
                          disabled={disableDiscover}
                          onClick={handleDiscover}
                          data-bs-target={
                            isDiscoverButtonDisabled
                              ? ""
                              : "#successMessagePopup"
                          }
                        >
                          Discover
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {showErrorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
                {discoverError && (
                  <div className="alert alert-danger" role="alert">
                    Invalid IP address provided. Please enter valid IP
                    addresses.
                  </div>
                )}
                <div>
                  <div className="inc-card-table">
                    <table className="full-table">
                      <thead>
                        <tr>
                          <th>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                checked={selectAllChecked}
                                onChange={handleSelectAll}
                              />
                              <span className="indicator"></span>
                            </label>
                          </th>
                          <th>Device IP</th>
                          <th>Device Name</th>
                          <th>Device Type</th>
                          <th>Gateway Name</th>
                          <th>Device Mac</th>
                          <th>Device Model</th>
                          <th>Last Inform</th>
                          <th>Health</th>
                          <th>Action</th>
                        </tr>
                        <tr className="search-border">
                          <td></td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="serialNumber"
                            />
                            <i
                              className="fa fa-sort-amount-desc"
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="deviceName"
                            />{" "}
                            <i
                              className="fa fa-sort-amount-desc"
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="deviceType"
                            />
                            <i
                              className="fa fa-sort-amount-desc"
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="deviceType"
                            />
                            <i
                              className="fa fa-sort-amount-desc"
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="softwareVersion"
                            />
                            <i
                              className="fa fa-sort-amount-desc"
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="ip"
                            />{" "}
                            <i
                              className="fa fa-sort-amount-desc"
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="macAddress"
                            />
                            <i
                              className="fa fa-sort-amount-desc"
                              aria-hidden="true"
                            ></i>
                          </td>

                          <td>
                            <select className="searchBox smallWidth">
                              <option>All</option>
                              <option>Online</option>
                              <option>Offline</option>
                            </select>
                          </td>
                          <td></td>
                        </tr>
                      </thead>
                      {isLoading ? (
                        <tbody>
                          <tr>
                            <td colspan="10">
                              <Loader />
                            </td>
                          </tr>
                        </tbody>
                      ) : errorDiscovery ? (
                        <tbody>
                          <tr>
                            <td colSpan="10">No devices discovered.</td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {deviceManagedError ? (
                            <tr>
                              <td colSpan="9">
                                <div
                                  className="alert alert-danger"
                                  role="alert"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "small",
                                  }}
                                >
                                  <p>{deviceManagedError}</p>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            discoverResponseData &&
                            discoverResponseData.map((device, index) => (
                              <tr key={index}>
                                <td>
                                  <label className="checkbox">
                                    <input
                                      type="checkbox"
                                      checked={selectedDevices.some(
                                        (selectedDevice) =>
                                          selectedDevice.deviceIP ===
                                          device.deviceIP
                                      )}
                                      onChange={() =>
                                        handleDeviceSelect(device)
                                      }
                                    />
                                    <span className="indicator"></span>
                                  </label>
                                </td>
                                <td>{device.deviceIP}</td>
                                <td>{device.deviceName}</td>
                                <td>{device.deviceType}</td>
                                <td>{device.gateway_name}</td>
                                <td>{device.deviceMac}</td>
                                <td>{device.deviceModel}</td>
                                <td>{device.snmpVer}</td>
                                <td>
                                  <span
                                    className={`d-status ${
                                      device.status === "online"
                                        ? "online"
                                        : "offline"
                                    }`}
                                  >
                                    {device.status === "online"
                                      ? "Online"
                                      : "Offline"}
                                  </span>
                                </td>
                                <td>
                                  <div className="button-group">
                                    <button title="Edit">
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <button title="Delete">
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <button
                                      className="text-btn primary-btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#deviceAddedMessagePopup"
                                      disabled={selectedDevices.length === 0}
                                    >
                                      <i className="fa fa-plus" aria-hidden="true"></i>
                                      Add
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>

              <div className="tab-pane fade" id="mqtt-tab-pane" role="tabpanel">
                {activeTab === "mqtt" && (
                  <div>
                    <div className="discover-form">
                      <div className="row">
                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            Start IP<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter IP"
                            id="startIp"
                            name="startIp"
                            value={mqttFields.start_ip}
                            onChange={(e) => handleInputChange(e, "start_ip")}
                          />
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            Mac Address<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Mac Address"
                            id="mac_address"
                            name="mac_address"
                            value={mqttFields.mac_address}
                            onChange={(e) =>
                              handleInputChange(e, "mac_address")
                            }
                          />
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            Device Template
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            id="snmpTemplate"
                            name="snmpTemplate"
                            value={mqttFields.snmp_template}
                            onChange={(e) =>
                              handleInputChange(e, "snmp_template")
                            }
                          >
                            <option value="">Select Template</option>
                            {snmpTemplates.map((template, index) => (
                              <option
                                key={index}
                                value={template.snmp_template_id}
                              >
                                {template.template_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label className="form-label">Gateway</label>
                          <select
                            className="form-select"
                            id="snmpTemplate"
                            name="snmpTemplate"
                            value={mqttFields.gateway_id}
                            onChange={(e) => handleInputChange(e, "gateway_id")}
                          >
                            <option value="">Select Gateway</option>
                            {gateways.map((gateway, index) => (
                              <option key={index} value={gateway.request_id}>
                                {gateway.gateway_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="discover-button mt-3">
                        <button
                          type="button"
                          className="text-btn btn-outline"
                          onClick={() => cancelClicked()}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="text-btn primary-btn"
                          disabled={isAddMqttDisabled}
                          onClick={() => {
                            addMqttDevice();
                            setShowSuccessModal(true);
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="tab-pane fade" id="tr69-tab-pane" role="tabpanel">
                {activeTab === "tr069" && (
                  <div>
                    <div className="discover-form">
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="manufacture" className="form-label">
                            Manufacture<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="manufacture"
                            name="manufacturer"
                            value={tr069Fields.manufacturer}
                            onChange={(e) =>
                              handleInputChange(e, "manufacturer")
                            }
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="oui" className="form-label">
                            OUI<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="oui"
                            name="oui"
                            value={tr069Fields.oui}
                            onChange={(e) => handleInputChange(e, "oui")}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="serialNo" className="form-label">
                            Serial No.<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="serialNo"
                            name="serialNo"
                            value={tr069Fields.serialNo}
                            onChange={(e) => handleInputChange(e, "serialNo")}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="productClass" className="form-label">
                            Product Class<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="productClass"
                            name="productClass"
                            value={tr069Fields.productClass}
                            onChange={(e) =>
                              handleInputChange(e, "productClass")
                            }
                          />
                        </div>
                        <div className="discover-button mt-3">
                          <button
                            type="button"
                            className="text-btn btn-outline"
                            onClick={() => cancelClicked()}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="text-btn primary-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#trAddedMessage"
                            disabled={isTRDisabled}
                            onClick={() => addTRDevice()}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="tab-pane fade"
                id="https-tab-pane"
                role="tabpanel"
              >
                {activeTab === "https" && (
                  <div>
                    <div className="discover-form">
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="manufacture" className="form-label">
                            Serial No.<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="serialNo"
                            name="serialNo"
                            value={httpsFields.serialNo}
                            onChange={(e) => handleInputChange(e, "serialNo")}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="oui" className="form-label">
                            Mac Address<span className="text-danger">*</span>
                          </label>
                          <InputMask
                            autoComplete="off"
                            id="macAddress"
                            onChange={(e) => handleInputChange(e, "macAddress")}
                            type="text"
                            mask="**:**:**:**:**:**"
                            placeholder="aa:bb:cc:dd:ee:ff"
                            data-type="mac"
                            className="form-control"
                            ref={macAddressRef}
                            spellCheck="false"
                            formatChars={{
                              "*": "[0-9A-Fa-f]",
                            }}
                            value={httpsFields.macAddress}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="oui" className="form-label">
                            Device Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="deviceName"
                            name="deviceName"
                            value={httpsFields.deviceName}
                            onChange={(e) => handleInputChange(e, "deviceName")}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="protocolType" className="form-label">
                            Device Type
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="protocolType"
                            name="protocolType"
                            value={httpsFields.protocol_type}
                            onChange={(e) => handleInputChange(e, "protocol_type")}
                          />
                        </div>
                        <div className="discover-button mt-3">
                          <button
                            type="button"
                            className="text-btn btn-outline"
                            onClick={() => {
                              cancelClicked();
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="text-btn primary-btn"
                            onClick={() => {
                              handleHTTPSDevice();
                            }}
                            disabled={isAddHttpDisabled}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`modal fade`}
              id="successMessagePopup"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content inc-modal">
                  <div className="modal-body success-msg">
                    <img src={waitIcon} alt="Success" />
                    <p>
                      {deviceAddedMessageVisible
                        ? "Device added Successfully"
                        : "Please wait while device is getting discovered."}
                    </p>
                    <button
                      className="text-btn primary-btn"
                      data-bs-dismiss="modal"
                      onClick={() => {setShowSuccessMessageModal(false)}}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`modal fade`}
              id="noDeviceSelectedPopup"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content inc-modal">
                  <div className="modal-body success-msg">
                    <img src={errorIcon} alt="error" />
                    <p>
                      You need to do discover and
                      <br />
                      select at least one device for add.
                    </p>
                    <button
                      className="text-btn primary-btn"
                      data-bs-dismiss="modal"
                      onClick={() => setShowNoDeviceSelectedPopup(false)}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="errorPopup"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content inc-modal">
                  <div className="modal-body error-msg">
                    <img src={errorIcon} alt="Error" />
                    <p>An error occurred while adding the TR-069 device.</p>
                    <button
                      className="text-btn primary-btn"
                      data-bs-dismiss="modal"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Device Added Successfully Popup */}
            <div
              className={`modal fade
                `}
              id="deviceAddedMessagePopup"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content inc-modal">
                  <div className="modal-body success-msg">
                    <img src={waitIcon} alt="wait" />
                    <p>
                      Please wait while your device is getting added.
                    </p>
                    <button
                      className="text-btn primary-btn"
                      data-bs-dismiss="modal"
                      onClick={handleAddDevices}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`modal fade
                `}
              id="trAddedMessage"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content inc-modal">
                  <div className="modal-body success-msg">
                    <img src={successIcon} alt="Success" />
                    <p>TR-069 Device added successfully!</p>
                    <button
                      className="text-btn primary-btn"
                      data-bs-dismiss="modal"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showSuccessPopup}
        onHide={() => setShowSuccessPopup(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert"
      >
        <span className="success-icon">
          <img src={successIcon} alt="Success" />
        </span>
        <h3>Success</h3>
        <p>Request sent to client successfully!</p>
        <Button onClick={() => {
          setShowSuccessPopup(false);
          navigate("/device-listing")
        }}>OK</Button>
      </Modal>

      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert"
      >
        <span className="success-icon">
          <img src={successIcon} alt="Success" />
        </span>
        <h3>Success</h3>
        <p>Device discovered successfully!</p>
        <Button
          onClick={() => handleAddDeviceToListing(discoverResponseData[0])}
        >
          Add Device
        </Button>
      </Modal>
      <Modal
        show={showSuccessModal}
        onHide={handleSuccessModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>
          Device Added Successfully!
          {/* Request sent to gateway successfully. Wait for the device to be added. */}
        </p>
        <Button
          onClick={() => {
            handleSuccessModalClose();
          }}
        >
          OK
        </Button>
      </Modal>
    </div>
  );
};

export default DeviceDiscovery;
