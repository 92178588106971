import React, { useEffect } from 'react';

const Chat = () => {
  useEffect(() => {
    // Initialize Kommunicate Chatbot
    (function(d, m) {
      var kommunicateSettings = {
        "appId": "1327509cbe5077f87f6f729a449da1db5",  // Your Kommunicate App ID
        "popupWidget": true,
        "automaticChatOpenOnNavigation": true
      };
      var s = document.createElement("script"); 
      s.type = "text/javascript"; 
      s.async = true;
      s.src = "https://widget.kommunicate.io/v2/kommunicate.app";
      var h = document.getElementsByTagName("head")[0]; 
      h.appendChild(s);
      window.kommunicate = m; 
      m._globals = kommunicateSettings;
    })(document, window.kommunicate || {});

    // Clean up the script when component unmounts
    return () => {
      const scripts = document.querySelectorAll('script[src="https://widget.kommunicate.io/v2/kommunicate.app"]');
      scripts.forEach(script => script.remove());
    };
  }, []);

  return null; // This component does not render anything visible
};

export default Chat;
