import React from "react";
import inclogo from "../../../assets/images/inc-name-logo.png";
import { useState, useEffect } from "react";
import allApi from "../../../api/allApi";
import { useLocation } from "react-router-dom";
import eyeIcon from "../../../assets/images/eye-open.svg";
import eyeCloseIcon from "../../../assets/images/eye-close.svg";
import { useNavigate } from "react-router-dom";


const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [address, setAddress] = useState(userData?.address || '');
  const [state, setState] = useState(userData?.state || '');
  const [country, setCountry] = useState(userData?.country || '');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tokenId = searchParams.get("tokenId");
  const [user, setUser] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [err,setErr] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showPopUp,setShowPopUp] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [showPop,setShowPop] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (tokenId) {
        try {
          const api = new allApi();
          const response = await api.getRequest(`api/user/verify?tokenId=${tokenId}`);
          
          if (response.status === 200) {
            setUserData(response);
            setAddress(response.address);
            setState(response.state);
            setCountry(response.country);
    
            console.log("userdata", response);
          } else if (response.status === 400) {
            console.log("showmodal");
            
          } 
        } catch (error) {
          setShowPop(false);
          setShowPopUp(true);
          setError(`Token not found: ${error.message}`);
        }
      } else {
        setError("Token not found");
      }
    };

    fetchData();
  }, [tokenId]);

  const handleAddUser = async (e) => {
    e.preventDefault();
    setErrorMessage("");
   
    if (password !== confirmPassword) {
      setErrorMessage("The passwords you entered do not match. Please make sure both fields contain the same password and try again.");
      return;
    }
    
    try {
      const api = new allApi();
      const user = {
        tokenId,
        userData,
        password,
        address,
        state,
        country,
      };
      const response = await api.postRequest(`api/user/edit-tempUser?tokenId=${tokenId}`, "", user);

      if (response) {
        setUser(response);
        console.log('User added successfully', response);
        setShowModal(true);
       
      } else {
        console.error("Failed to add user");
      }
    } catch (error) {
      console.error('Failed to add user:', error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
    
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  };

  const isValidPassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };
  const isSubmitButtonDisabled = !(
    password &&
    confirmPassword
    );
 
  const handleSuccessClose=()=>{
    setShowModal(false);
    setShowPopUp(false);
    navigate("/");
  }
  const handlePassword = (e) => {
    const value = e.target.value;
    setPassword(value)
  if ( value && !isValidPassword(value)) {
    setPasswordError("Password must be 8-20 characters long and include uppercase and lowercase letters, a number, and a special character..");
  } else {
    setPasswordError("");
  }
};

const resetForm = () => {
  setPassword("");
  setConfirmPassword("");
  setAddress("");
  setState("");
  setCountry("");
  setErrorMessage("");

};


  return (
    <>
     {showPop && (
    <div>
      <section className="inc-login">
        <div className="login-form">
          <div className="login-form-container userprofile">
            <div className="login-text">
              <img src={inclogo} alt="inc logo" className="inc-logo" />
              <h1>User Profile</h1>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <label htmlFor="firstname" className="col-form-label">
                  Firstname
                </label>
                <input type="text"
                  className="form-control read-only-input"
                  id="firstname"
                  value={userData?.firstname}
                  disabled
                />
              </div>
              <div className="col-lg-6">
                <label htmlFor="lastname" className="col-form-label">
                  Lastname
                </label>
                <input type="text"
                  className="form-control read-only-input"
                  id="lastname"
                  value={userData?.lastname}
                  disabled
                />
              </div>
              <div className="col-lg-6">
                <label htmlFor="username" className="col-form-label">
                  Username
                </label>
                <input type="text"
                  className="form-control read-only-input"
                  id="username"
                  value={userData?.username}
                  disabled
                />
              </div>
              <div className="col-lg-6">
                <label htmlFor="email" className="col-form-label">
                  Email
                </label>
                <input type="text"
                  className="form-control read-only-input"
                  id="email"
                  value={userData?.email}

                  disabled />
              </div>
              <div className="col-lg-6 ">
                <label htmlFor="password" className="col-form-label">
                  Password
                </label>
                <div className="input-wrapper">
                <input type={showPassword ? "text" : "password"}
                  className="form-control"

                  id="password"
                  value={password}
                  onChange={handlePassword} />
                <img
                  src={showPassword ? eyeIcon : eyeCloseIcon}
                  alt={showPassword ? "eye" : "closeeye"}
                  className="toggle-password"
                  onClick={togglePasswordVisibility}
                />
                {passwordError && (
                  <p className="text-danger">{passwordError}</p>
                )}
                </div>
              </div>
              
              
              <div className="col-lg-6">
                <label htmlFor="firstName" className="col-form-label">
                  Confirm Password
                </label>
                <div className="input-wrapper">
                <input type={showConfirmPassword ? "text" : "password"}
                  className="form-control"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)} />
                  <img
                  src={showConfirmPassword ? eyeIcon : eyeCloseIcon}
                  alt={showConfirmPassword ? "eye" : "closeeye"}
                  className="toggle-password"
                  onClick={toggleConfirmPasswordVisibility}
                />
                  {err && (
                  <p className="text-danger">{err}</p>
                )}modal fade inc-alert show
                </div>
              </div>
              <div className="col-lg-6">
                <label htmlFor="role" className="col-form-label">
                  Role
                </label>
                <input className="form-select read-only-input"
                  id="role"
                  value={userData?.role?.name}
                  disabled
                />
              </div>
              <div className="col-lg-6">
                <label htmlFor="address" className="col-form-label">
                  Address
                </label>
                <input type="text"
                  className="form-control"
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)} />
              </div>
              <div className="col-lg-6">
                <label htmlFor="state" className="col-form-label">
                  State
                </label>
                <input type="text"
                  className="form-control"
                  id="state"
                  value={state}
                  onChange={(e) => setState(e.target.value)} />
              </div>
              <div className="col-lg-6">
                <label htmlFor="country" className="col-form-label">
                  Country
                </label>
                <input type="text"
                  className="form-control"
                  id="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)} />
              </div>
              {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
              <div className="col-lg-12 user-footer">
                <button type="button" className="btn btn-outline"
                onClick={resetForm}>
                  Cancel
                </button>
                <button type="submit" className="text-btn primary-btn"
                disabled={isSubmitButtonDisabled}
                  onClick={handleAddUser}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

      </section>
      {showModal && (
            <div
              className="modal fade inc-alert show"
              id="mailConfirmation"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
              style={{ display: 'block' }}
            >
              <div className="modal-dialog email-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <h3>Success</h3>
                    <p>
                    Your account has been successfully created. 
                    Please proceed to the login page to access your account.
                    </p>
                    <div className="confirm-btn">
                      <button
                        type="button"
                        className="btn btn-fill"
                        onClick={() => handleSuccessClose()}
                        data-bs-dismiss="modal"
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
           
    </div>
     )}
    {showPopUp && (
      <div
        className="modal fade inc-alert customModal show"
        id="tokenExpired"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog email-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h3 style={{color:"red"}}>Link Expired</h3>
              <p>
              This link has already been used and is no longer valid.
              </p>
              <div className="confirm-btn">
                <button
                  type="button"
                  className="btn btn-fill"
                  onClick={() => handleSuccessClose()}
                  data-bs-dismiss="modal"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    </>
  );
};

export default UserProfile;
