import React, { useState, useEffect } from "react";
import allApi from "../../../api/allApi";
import eyeIcon from "../../../assets/images/eye-open.svg";
import eyeCloseIcon from "../../../assets/images/eye-close.svg";

const EditEdgePopup = ({
  showPopUp,
  handleClose,
  headerMessage1,
  handleSuccess,
  gateway,
  updateGatewayList,
}) => {
  const [gatewayFields, setGatewayFields] = useState({ ...gateway });
  const [missingFields, setMissingFields] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [gateways, setGateways] = useState([]);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [originalGatewayName,setOriginalGatewayName] = useState(gatewayFields.gateway_name);
  const [originalUsername,setOriginalUsername] = useState(gatewayFields.username);
  
  useEffect(() => {
    if (gateway) {
      setGatewayFields(gateway);
      setOriginalGatewayName(gatewayFields.gateway_name);
      setOriginalUsername(gatewayFields.username);
    }
  }, [gateway]);
 
  async function getEdgeDevices() {
    try {
      const api = new allApi();
      let response = await api.getRequest("api/gateway/all");
      if (response && Array.isArray(response)) {
        response = response.reverse();
        setGateways(response);
      }
    } catch (error) {
      setError("Error fetching gateways");
    }
  }
  const handleSave = async () => {
    try {
      const requiredFields = [
        "username",
        "password",
        "publisher_topic",
        "subscriber_topic",
        "site_name",
        "organisation_name",
      ];
      
      const missing = requiredFields.filter((field) => !gatewayFields[field]);
      setMissingFields(missing);
  
      if (missing.length > 0) {
        const errorMessage = `Please fill in the following required fields: ${missing.join(
          ", "
        )}.`;
        console.error(errorMessage);
        return;
      }
  
      const api = new allApi();
  
      const payload = { 
        password: gatewayFields.password,
        publisher_topic: gatewayFields.publisher_topic,
        subscriber_topic: gatewayFields.subscriber_topic,
        site_name: gatewayFields.site_name,
        organisation_name: gatewayFields.organisation_name,
      };
      if (gatewayFields.gateway_name && gatewayFields.gateway_name !== originalGatewayName) {
        payload.gateway_name = gatewayFields.gateway_name;
      }
      
      if (gatewayFields.username && gatewayFields.username !== originalUsername) {
        payload.username = gatewayFields.username;
      }
  
      const response = await api.putRequest(
        `api/gateway/update/${gatewayFields.request_id}`,
        payload
      );
  
      if (response) {
        handleSuccess();
        handleClose();
        setShowSuccessMessage(true);
        updateGatewayList(response);
        getEdgeDevices();
      } else {
        console.error("Failed to update gateway:", response);
      }
    } catch (error) {
      console.error("Error updating gateway:", error);
    }
  };
  
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGatewayFields({ ...gatewayFields, [name]: value });

    if (missingFields.includes(name)) {
      setMissingFields((prevMissingFields) =>
        prevMissingFields.filter((field) => field !== name)
      );
    }
  };

  useEffect(() => {
    if (showSuccessMessage) {
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage]);
  
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const isDisabled= true;

  const resetForm = ()=>{
    setMissingFields([]);
  };

  const handleCancel = () => {
    resetForm();
    handleClose();
  };

  return (
    <>
      {showPopUp && (
        <div
          className={"modal fade customModal " + (showPopUp ? " show" : "")}
          id="discover"
          style={{ display: showPopUp ? "block" : "none", zIndex: 1009 }}
        >
          <div className="modal-dialog modal-dialog-centered create-usr-pop">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {headerMessage1}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  onClick={handleCancel}
                ></button>
              </div>
              <div className="modal-body">
                <div className="tab-content">
                  <div className="tab-pane fade show active">
                    <form>
                      <div className="row">
                        <div className="col-lg-6">
                          <label
                            htmlFor="gatewayName"
                            className="col-form-label"
                          >
                            Gateway Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              missingFields.includes("gateway_name")
                                ? "missing-field"
                                : ""
                            }`}
                            id="gatewayName"
                            name="gateway_name"
                            value={gatewayFields.gateway_name}
                            onChange={handleInputChange}
                          />

                          {missingFields.includes("gateway_name") && (
                            <span className="required-message">
                              Gateway Name is required
                            </span>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="siteName" className="col-form-label">
                            Site Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              missingFields.includes("site_name")
                                ? "missing-field"
                                : ""
                            }`}
                            id="siteName"
                            name="site_name"
                            value={gatewayFields.site_name}
                            onChange={handleInputChange}
                          />
                          {missingFields.includes("site_name") && (
                            <span className="required-message">
                              Site Name is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <label
                            htmlFor="snmpTemplate"
                            className="col-form-label"
                          >
                            Publisher Topic<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              missingFields.includes("publisher_topic")
                                ? "missing-field"
                                : ""
                            }`}
                            id="snmpTemplate"
                            name="publisher_topic"
                            value={gatewayFields.publisher_topic}
                            onChange={handleInputChange}
                          />
                          {missingFields.includes("publisher_topic") && (
                            <span className="required-message">
                              Publisher Topic is required
                            </span>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="endIp" className="col-form-label">
                            Subscriber Topic<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              missingFields.includes("subscriber_topic")
                                ? "missing-field"
                                : ""
                            }`}
                            id="endIp"
                            name="subscriber_topic"
                            value={gatewayFields.subscriber_topic}
                            onChange={handleInputChange}
                          />
                          {missingFields.includes("subscriber_topic") && (
                            <span className="required-message">
                              Subscriber Topic is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="endIp" className="col-form-label">
                            Organisation Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              missingFields.includes("organisation_name")
                                ? "missing-field"
                                : ""
                            }`}
                            id="endIp"
                            name="organisation_name"
                            value={gatewayFields.organisation_name}
                            onChange={handleInputChange}
                          />
                          {missingFields.includes("organisation_name") && (
                            <span className="required-message">
                              Organisation Name is required
                            </span>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="endIp" className="col-form-label">
                            Password<span className="text-danger">*</span>
                          </label>
                          <div className="input-wrapper">
                          <input
                            type={showPassword ? "text" : "password"}
                            className={`form-control ${
                              missingFields.includes("password")
                                ? "missing-field"
                                : ""
                            }`}
                            id="password"
                            name="password"
                            value={gatewayFields.password}
                            onChange={handleInputChange}
                          />
                          <img
                          src={showPassword ? eyeIcon : eyeCloseIcon}
                          alt={showPassword ? "eye" : "closeeye"}
                          className="toggle-password"
                          onClick={togglePasswordVisibility}
                        />
                          {missingFields.includes("password") && (
                            <span className="required-message">
                              Password is required
                            </span>
                          )}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-lg-6">
                          <label htmlFor="startIp" className="col-form-label">
                            Username<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              missingFields.includes("username")
                                ? "missing-field"
                                : ""
                            }`}
                            id="username"
                            name="username"
                            value={gatewayFields.username}
                            onChange={handleInputChange}
                          />
                          {missingFields.includes("username") && (
                            <span className="required-message">
                              Username is required
                            </span>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="endIp" className="col-form-label ">
                            Mail ID<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            disabled={isDisabled}
                            style={{cursor: isDisabled ? 'not-allowed' : 'default'}}
                            className={`form-control ${
                              missingFields.includes("username")
                                ? "missing-field"
                                : ""
                            }`}
                            id="endIp"
                            name="agent_mail"
                            value={gatewayFields.agent_mail}
                            onChange={handleInputChange}
                          />
                          {missingFields.includes("username") && (
                            <span className="required-message">
                              Email is required
                            </span>
                          )}
                        </div>
                      </div>{" "}
                    </form>
                    {showSuccessMessage && (
                      <div className="alert alert-success" role="alert">
                        Gateway Updated successfully
                      </div>
                    )}
                    <div className="modal-footer pb-0">
                      <button
                        type="button"
                        className="btn btn-outline"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-fill"
                        onClick={() => handleSave()}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditEdgePopup;
