import React, { useState, useEffect } from "react";
import allApi from "../../../api/allApi";

const DiscoverDetailPopUp = ({
  showPopUp,
  handleClose,
  discoverResponseData,
}) => {
  const [snmpFields, setSnmpFields] = useState({
    deviceIP: "",
    deviceName: "",
    deviceMac: "",
    deviceType: "",
    deviceModel: "",
    isManaged: "",
    snmpID: "",
    snmpVer: "",
    workingMode: "",
    isManageable: "",
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const closePopUp = () => {
    setSnmpFields({
      deviceIP: "",
      deviceName: "",
      deviceMac: "",
      deviceType: "",
      deviceModel: "",
      isManaged: "",
      snmpID: "",
      snmpVer: "",
      workingMode: "",
      isManageable: "",
    });
  };

  useEffect(() => {
    if (discoverResponseData) {
      setSnmpFields({ ...discoverResponseData });
    }
  }, [discoverResponseData]);

  const handleAddSNMP = async () => {
    try {
      const api = new allApi();
      const payload = [
        {
          ...discoverResponseData,
        },
      ];
      const response = await api.postRequest("api/snmp/add_snmp", "", payload);
      if (response.status === 201) {
        getDeviceDetails();
      }
    } catch (error) {}
  };

  async function getDeviceDetails() {
    try {
      const api = new allApi();
      const devicesResponse = await api.getRequest("api/devices");
      console.log(devicesResponse);
    } catch (error) {
      console.error("Error fetching device data:", error);
    }
  }

  const handleShowSuccessPopup = () => {
    setShowSuccessModal(true);
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    handleClose();
    closePopUp();
  };

  return (
    <>
      <div
        className={"modal fade customModal " + (showPopUp ? " show" : "")}
        style={{ display: showPopUp ? "block" : "none", zIndex: 1009 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Device Discovery
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleClose();
                  closePopUp();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="start-date" className="col-form-label">
                    Device IP
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="deviceIP"
                    value={snmpFields.deviceIP}
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="deviceName" className="col-form-label">
                    Device Type
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={snmpFields.deviceType}
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="deviceMac" className="col-form-label">
                    Device Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={snmpFields.deviceName}
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Device Mac
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="deviceType"
                    value={snmpFields.deviceMac}
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Device Model
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="deviceModel"
                    value={snmpFields.deviceModel}
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Working Mode
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="snmpVer"
                    value={snmpFields.workingMode}
                    readOnly
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="ibtn btn-outline"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleClose();
                  closePopUp();
                }}
              >
                Discard
              </button>
              <button
                type="button"
                className="ibtn btn-fill"
                onClick={() => {
                  handleClose();
                  handleAddSNMP();
                  handleShowSuccessPopup();
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscoverDetailPopUp;
