import React, { useState } from "react";
import allApi from "../../../api/allApi";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
function CreatetemplatePopup({
  showPopUp,
  handleClose,
  headerMessage1,
  handleSuccess,
  onTemplateAdd,
}) {
  const initialFormValues = {
    template_name: "",
    snmp_port: 161,
    retry_count: 3,
    timeout: "",
    version: "",
    read_only_community: "",
    readwrite_community: "",
    trap_community: "HFcltD#123!@",
    trap_host: "",
    trap_port: 162,
    trap_host_version: "",
    username: "",
    security_level: "",
    authentication_password: "",
    encryption: "",
    access_privilege: "",
    privacy_key: "",
    status: "Active",
    dcdr_config: "incx_dcdr",
    dcdr_interval: 300,
    dcdr_retry_count: 5,
    dcdr_rev_retry_count: 5,
    dcdr_switch_rev_interval: 300,
    dcdr_rev_time: 30,
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [missingFields, setMissingFields] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [error, setError] = useState(null);
  const [version, setVersion] = useState("");
  const [trapVersion, setTrapVersion] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [snmpTemplates, setSnmpTemplates] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    if (
      ["snmp_port", "timeout", "trap_port", "retry_count"].includes(name) &&
      !/^\d+$/.test(value)
    ) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${name.replace(/_/g, " ")} must be a numeric value.`,
      }));
    } else if (name === "snmp_port" && !/^[a-zA-Z0-9_.-]*$/.test(value)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Trap host must be alphanumeric.",
      }));
    } else if (name === "trap_port" && !/^\w+$/.test(value)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${name.replace(/_/g, " ")} must be alphanumeric.`,
      }));
    } else if (name === "retry_count" && !/^\w+$/.test(value)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${name.replace(/_/g, " ")} must be alphanumeric.`,
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const handleTrapVersionChange = (e) => {
    setTrapVersion(e.target.value);
    setFormValues((prevValues) => ({
      ...prevValues,
      trap_host_version: e.target.value,
    }));
  };

  const handleVersionChange = (e) => {
    setVersion(e.target.value);
    setFormValues((prevValues) => ({
      ...prevValues,
      version: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    const hasErrors = Object.values(validationErrors).some(
      (error) => error !== undefined
    );
    if (hasErrors) {
      return;
    }
    let payload = {
      template_name: formValues.template_name,
      snmp_port: formValues.snmp_port,
      retry_count: formValues.retry_count,
      timeout: formValues.timeout,
      version: formValues.version,
      read_only_community: formValues.read_only_community,
      readwrite_community: formValues.readwrite_community,
      trap_community: formValues.trap_community,
      trap_host: formValues.trap_host,
      trap_port: formValues.trap_port,
      trap_host_version: formValues.trap_host_version,
      status: formValues.status,
      dcdr_config: "incx_dcdr",
      dcdr_interval: 300,
      dcdr_retry_count: 5,
      dcdr_rev_retry_count: 5,
      dcdr_switch_rev_interval: 300,
      dcdr_rev_time: 30,
    };

    if (formValues.trap_host_version === "v3") {
      payload = {
        ...payload,
        username: formValues.username,
        security_level: formValues.security_level,
        authentication_password: formValues.authentication_password,
        encryption: formValues.encryption,
        access_privilege: formValues.access_privilege,
        privacy_key: formValues.privacy_key,
      };
    }

    try {
      const api = new allApi();
      const response = await api.postRequest(
        `api/snmp/template/add`,
        "",
        payload
      );
      if (response.status === 201) {
        handleClose();
        handleSuccess();
        onTemplateAdd(response.data);
        resetForm();
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const disableAdd =
    trapVersion === "v3"
      ? !(
          formValues.template_name &&
          formValues.snmp_port &&
          formValues.retry_count &&
          formValues.timeout &&
          formValues.version &&
          formValues.read_only_community &&
          formValues.readwrite_community &&
          formValues.trap_community &&
          formValues.trap_host &&
          formValues.trap_port &&
          formValues.trap_host_version &&
          formValues.username &&
          formValues.security_level &&
          formValues.authentication_password &&
          formValues.encryption &&
          formValues.access_privilege &&
          formValues.privacy_key
        )
      : !(
          formValues.template_name &&
          formValues.snmp_port &&
          formValues.retry_count &&
          formValues.timeout &&
          formValues.version &&
          formValues.read_only_community &&
          formValues.readwrite_community &&
          formValues.trap_community &&
          formValues.trap_host &&
          formValues.trap_port &&
          formValues.trap_host_version
        );

  const resetForm = () => {
    setFormValues(initialFormValues);
    setValidationErrors({});
    setVersion("");
    setTrapVersion("");
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    handleClose();
  };

  return (
    <>
      {showPopUp && (
        <div
          className={"modal fade customModal " + (showPopUp ? " show" : "")}
          id="discover"
          style={{ display: showPopUp ? "block" : "none", zIndex: 1009 }}
        >
          <div className="modal-dialog modal-dialog-centered create-usr-pop">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {headerMessage1}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    resetForm();
                    handleClose();
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <div className="tab-content">
                  <div className="tab-pane fade show active">
                    <form>
                      <div className="row">
                        <div className="col-lg-6">
                          <label
                            htmlFor="templateName"
                            className="col-form-label"
                          >
                            Template Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="templateName"
                            name="template_name"
                            value={formValues.template_name}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="port" className="col-form-label">
                            SNMP Port<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control readonly-field"
                            id="port"
                            name="snmp_port"
                            value={formValues.snmp_port}
                            onChange={handleInputChange}
                            readOnly
                          />
                          {validationErrors.snmp_port && (
                            <div className="text-danger">
                              {validationErrors.snmp_port}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <label
                            htmlFor="retryCount"
                            className="col-form-label"
                          >
                            Retry Count<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control readonly-field"
                            id="retryCount"
                            name="retry_count"
                            value={formValues.retry_count}
                            onChange={handleInputChange}
                            readOnly
                          />
                          {validationErrors.retry_count && (
                            <div className="text-danger">
                              {validationErrors.retry_count}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="timeOut" className="col-form-label">
                            Time Out<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="timeOut"
                            name="timeout"
                            value={formValues.timeout}
                            onChange={handleInputChange}
                          />
                          {validationErrors.timeout && (
                            <div className="text-danger">
                              {validationErrors.timeout}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="version" className="col-form-label">
                            Trap Host Version
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            id="version"
                            name="version"
                            value={formValues.trap_host_version}
                            onChange={handleTrapVersionChange}
                          >
                            <option value="">Select Version</option>
                            <option value="IPv4">ipv4</option>
                            <option value="IPv6">ipv6</option>
                          </select>
                        </div>
                        <>
                          <div className="col-lg-6">
                            <label
                              htmlFor="readOnly"
                              className="col-form-label"
                            >
                              Read Only Community
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="readOnly"
                              name="read_only_community"
                              value={formValues.read_only_community}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="readWrite"
                              className="col-form-label"
                            >
                              Read Write Community
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="readWrite"
                              name="readwrite_community"
                              value={formValues.readwrite_community}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="trapCommunity"
                              className="col-form-label"
                            >
                              Trap Community
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control readonly-field"
                              id="trapCommunity"
                              name="trap_community"
                              value={formValues.trap_community}
                              onChange={handleInputChange}
                              readOnly
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="trapHost"
                              className="col-form-label"
                            >
                              Trap Host<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="trapHost"
                              name="trap_host"
                              value={formValues.trap_host}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="trapPort"
                              className="col-form-label"
                            >
                              Trap Port<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control readonly-field"
                              id="trapPort"
                              name="trap_port"
                              value={formValues.trap_port}
                              onChange={handleInputChange}
                              readOnly
                            />
                            {validationErrors.trap_port && (
                              <div className="text-danger">
                                {validationErrors.trap_port}
                              </div>
                            )}
                          </div>

                          <div className="col-lg-6">
                            <label
                              htmlFor="trapVersion"
                              className="col-form-label"
                            >
                              Version
                              <span className="text-danger">*</span>
                            </label>

                            <select
                              className="form-select"
                              id="version"
                              name="trap_host_version"
                              value={version}
                              onChange={handleVersionChange}
                            >
                              <option value="">Select Version</option>
                              <option value="v1">v1</option>
                              <option value="v2c">v2c</option>
                              <option value="v3">v3</option>
                            </select>
                          </div>
                        </>

                        {version === "v3" && (
                          <>
                            <div className="col-lg-6">
                              <label
                                htmlFor="username"
                                className="col-form-label"
                              >
                                Username<span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="username"
                                name="username"
                                value={formValues.username}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-lg-6">
                              <label
                                htmlFor="securityLevel"
                                className="col-form-label"
                              >
                                Security Level
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="securityLevel"
                                name="security_level"
                                value={formValues.security_level}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-lg-6">
                              <label
                                htmlFor="authMethod"
                                className="col-form-label"
                              >
                                Authentication Method
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="authMethod"
                                name="auth_method"
                                value={formValues.auth_method}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-lg-6">
                              <label
                                htmlFor="authPassword"
                                className="col-form-label"
                              >
                                Authentication Password
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="authPassword"
                                name="authentication_password"
                                value={formValues.authentication_password}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-lg-6">
                              <label
                                htmlFor="encryption"
                                className="col-form-label"
                              >
                                Encryption<span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="encryption"
                                name="encryption"
                                value={formValues.encryption}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-lg-6">
                              <label
                                htmlFor="privacyKey"
                                className="col-form-label"
                              >
                                Privacy Key
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="privacyKey"
                                name="privacy_key"
                                value={formValues.privacy_key}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-lg-6">
                              <label
                                htmlFor="accessPrivilege"
                                className="col-form-label"
                              >
                                Access Privilege
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="accessPrivilege"
                                name="access_privilege"
                                value={formValues.access_privilege}
                                onChange={handleInputChange}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </form>
                    <div className="modal-footer pb-0">
                      <button
                        type="button"
                        className="btn btn-outline"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          resetForm();
                          handleClose();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className={`text-btn primary-btn`}
                        disabled={disableAdd}
                        onClick={() => {
                          handleSubmit();
                        }}
                        aria-label="Close"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={showSuccessModal}
            onHide={handleSuccessModalClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="inc-alert"
          >
            <span className="success-icon">
              <i className="icon done"></i>
            </span>
            <h3>Success</h3>
            <p>Template created Successfully!</p>
            <Button onClick={handleSuccessModalClose}>OK</Button>
          </Modal>
        </div>
      )}
    </>
  );
}

export default CreatetemplatePopup;
