import React, { useEffect, useState } from "react";
import portpic from "../../../../assets/images/wired.svg";
import errorIcon from "../../../../assets/images/error-2.png";
import allApi from "../../../../api/allApi";
import { all } from "axios";
import { useViewTransitionState } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { arrayIncludes } from "@mui/x-date-pickers/internals/utils/utils";
import { responsiveFontSizes } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function DowntimeSchedules({ scheduleModal, setScheduleModal }) {
  const [portModel, setPortModel] = useState(false);
  const [listModel, setListModel] = useState(true);
  const [initialPage, setInitialPage] = useState(false);
  const [editScheduleModel, setEditScheduleModel] = useState(false);
  const [editPortModel, setEditPortModel] = useState(false);
  const [scheduleList, setScheduleList] = useState([]);
  const [scheduleData, setScheduleData] = useState({});
  const [portDetailsData, setPortDetailsData] = useState([]);
  const [deleteScheduleModel, setDeleteScheduleModel] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showEditPortSuccessPopup,setShowEditPortSuccessPopup] = useState(false);
  const [showEditSuccessPopup,setShowEditSuccessPopup] = useState(false);

  const [schedule, setSchedule] = useState({
    name: "",
    allDay: "0",
    startTime: null,
    endTime: null,
    recurrence: {
      type: 0,
      startDate: null,
      endDate: null,
      weekDay: [],
    }
  });
  const [editScheduleData, setEditScheduleData] = useState({
    schdName: "",
    allDay: "0",
    startTime: null,
    endTime: null,
    recurrenceType: 0,
    recurrenceStartDate: null,
    recurrenceEndDate: null,
    recurrenceWeekDay: []
  });
  const [applySchedule, setApplySchedule] = useState({
    scheduleId: 8,
    portDetails: [
      {
        deviceId: 2103,
        ports: [
          "3",
          "6"
        ]
      }
    ]
  });
  const [editPortsData, setEditPortsData] = useState({
    deviceId: null,
    portDetails: [],
  });
  const [assignedEditPorts, setAssignedEditPorts] = useState({});
  const [enableSchedule, setEnableSchedule] = useState(false);
  const [scheduleId, setScheduleId] = useState();
  const [assignedPorts, setAssignedPorts] = useState({});
  const [info, setInfo] = useState(null);
  const [selectedPorts, setSelectedPorts] = useState([]);
  const [responseId, setResponseId] = useState(null);

  useEffect(() => {
    fetchSchedule();
  }, []);

  const handleEditClick = (schedule) => {
    editSchedule(schedule.swSchdId);
  };
  const handleEditPortClick = (schedule) => {
    editPortDetails(schedule.swSchdId);
  };

  const handleUpdatePortClick = async (editPortsData, assignedEditPorts, swSchdId) => {
    try {
      await updateApplySchedule(editPortsData, assignedEditPorts, swSchdId);
      setEditPortModel(false); 
    } catch (error) {
      console.error("Failed to update port details", error);
    }
  };
  const handleCancelPopUp = () => {
    setShowSuccessPopup(false);
    setShowEditPortSuccessPopup(false);
    setShowEditSuccessPopup(false);
  }


  const updateScheduleField = (field, value) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [field]: field === "startTime" || field === "endTime"
        ? value
          ? dayjs(value).format("hh:mm")
          : null
        : value,
    }));
  };


  const updateRecurrenceField = (field, value) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      recurrence: {
        ...prevSchedule.recurrence,
        [field]: field === "startDate" || field === "endDate"
          ? value
            ? dayjs(value).format("YYYY-MM-DD")
            : null
          : value,
      },
    }));
  };
  const updateEditScheduleField = (field, value) => {
    setEditScheduleData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };


  const updateEditRecurrenceField = (field, value) => {
    setEditScheduleData((prev) => ({
      ...prev,
      recurrence: { ...prev.recurrence, [field]: value },
    }));
  };



  const updateApplyScheduleFields = (field, value) => {
    setApplySchedule({
      ...applySchedule, [field]: value
    });
  };

  const fetchSchedule = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/wired/conf/schedules/all");
      if (response && Array.isArray(response)) {
        setScheduleList(response);
      } else {
        console.error("Invalid response format", response);
      }
    } catch (error) {
      console.error("Error fetching schedules:", error);
    }
  };

  const handleSwitch = (data) => {
    if (data && data.length > 0) {
      setListModel(true);
      setInitialPage(false);
    } else {
      setListModel(false);
      setInitialPage(true);
    }
  };

  const handleScheduleTime = () => {
    setScheduleModal(false);
    setPortModel(false);
    setInitialPage(false);
    setListModel(true);
  }

  const addApplySchedule = async () => {
    try {
      const api = new allApi();

      const portDetails = info?.switchDetails?.map((device) => ({
        deviceId: device.device_id,
        ports: Object.keys(assignedPorts)
          .filter((port) => assignedPorts[port])
          .map((port) => port.toString()),
      }));

      const payload = {
        scheduleId: responseId,
        portDetails: portDetails || [],
      };
      const response = await api.postRequest("api/wired/conf/poePortDetails/add", " ", payload);
      if (response) {
        setPortModel(false);
        setShowSuccessPopup(true);
      }
      // alert("Schedule applied successfully!");
    } catch (error) {
      console.error("Error applying schedule:", error);
      alert("Failed to apply schedule.");
    }
  };



  // const fetchSchedule = async() =>{
  //   try{
  //    const api = new allApi();
  //    const response = await api.getRequest("api/wired/conf//schedules/all");
  //    if(response){
  //     setScheduleList(response);
  //    }
  //   }catch(error){
  //    console.log("Error in fetching schedule",error);
  //   
  // }

  const addSchedule = async (param) => {
    try {
      const api = new allApi();
      const payload = {
        poeSchedule: {
          name: schedule.name,
          allDay: schedule.allDay,
          startTime: schedule.startTime,
          endTime: schedule.endTime,
          recurrence: {
            type: schedule.recurrence.type,
            startDate: schedule.recurrence.startDate,
            endDate: schedule.recurrence.endDate,
            weekDay: schedule.recurrence.weekDay,
          }
        }
      };
      const response = await api.postRequest("api/wired/conf/poeSchedule/addAndChoosePorts", "", payload);
      if (response) {
        param == "save" && setShowSuccessPopup(true) && fetchSchedule();
        setResponseId(response.scheduleId);
        setInfo(response);
        //setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("Error adding downtime schedule", error);
    }
  };

  const updateSchedule = async () => {
    try {
      const api = new allApi();
      const payload = {
        poeSchedule: {
          name: editScheduleData.schdName,
          allDay: String(editScheduleData.allDay),
          startTime: editScheduleData.startTime,
          endTime: editScheduleData.endTime,
          recurrence: {
            type: editScheduleData.recurrenceType,
            startDate: editScheduleData.recurrenceStartDate
              ? dayjs(editScheduleData.recurrenceStartDate).format("YYYY-MM-DD")
              : null,
            endDate: editScheduleData.recurrenceEndDate
              ? dayjs(editScheduleData.recurrenceEndDate).format("YYYY-MM-DD")
              : null,
            weekDay: editScheduleData.recurrenceWeekDay || [],
          },
        },
      };

      console.log("Payload before sending API request:", payload);
      const response = await api.putRequest(
        "api/wired/conf/poeSchedule/edit",
        payload
      );

      if (response) {
        setEditScheduleModel(false);
        setShowEditSuccessPopup(true);
        console.log("Schedule successfully updated:", response);
      } else {
        console.error("API response is empty or invalid.");
      }
    } catch (error) {
      console.error("Error in updating schedules:", error);
    }
  };


  const updateApplySchedule = async (editPortsData, assignedEditPorts) => {
    try {
      const selectedPorts = Object.keys(assignedEditPorts)
        .filter((port) => assignedEditPorts[port])
        .map(Number);

      const payload = {
        scheduleId: editPortsData.idss,
        portDetails: [
          {
            deviceId: editPortsData.deviceId,
            ports: selectedPorts,
          },
        ],
      };
      const api = new allApi();
      const response = await api.putRequest(
        "api/wired/conf/poePortDetails/edit",
        payload
      );

      if (response) {
        console.log("Port details updated successfully", response);
        setShowEditPortSuccessPopup(true);
        return response;
      }
    } catch (error) {
      console.error("Error in updating schedule", error);
      throw error;
    }
  };



  const deleteSchedule = async (swSchdId) => {
    try {
      const api = new allApi();
      const response = await api.deleteRequest(`api/wired/conf/poePortDetails/delete?scheduleId=${swSchdId}`);
      if (response) {
        console.log("Downtime schedule successfully deleted");
        fetchSchedule();
        setDeleteScheduleModel(false);
      }
    } catch (error) {
      console.error("Error in deleting downtime schedule", error);
    }
  };

  const toggleScheduleApi = async (swSchdId, enable) => {
    try {
      const api = new allApi();
      const payload = {
        scheduleId: swSchdId,
        enable: enable, 
      };
      const response = await api.postRequest(
        "api/wired/conf/managePoE",
        "",
        payload
      );
      if (response) {
        setEnableSchedule(enable); 
      }
    } catch (error) {
      console.error(`Error ${enable ? "enabling" : "disabling"} schedule with ID ${swSchdId}:`, error);
    }
  };

  // const getInfo = async (responseId) => {
  //   try {
  //     const api = new allApi();
  //     const response = await api.getRequest(`api/wired/conf/switchDetails?scheduleId=${responseId}`);
  //     if (response && Array.isArray) {
  //       setInfo(response);
  //     }
  //   } catch (error) {
  //     console.error("Error in fetching info", error);
  //   }
  // };


  const handleToggle = (schedule) => {
    toggleScheduleApi(schedule.swSchdId, !enableSchedule); 
  };


  const updateScheduleState = (swSchdId, newState) => {
    setScheduleList((prevList) =>
      prevList.map((schedule) =>
        schedule.swSchdId === swSchdId
          ? { ...schedule, enabled: newState }
          : schedule
      )
    );
  };



  const editSchedule = async (swSchdId) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/wired/conf/schedules/${swSchdId}`);
      if (response) {
        setEditScheduleData(response);
        setEditScheduleModel(true);
      }
    } catch (error) {
      console.error("Error fetching schedule data:", error);
    }
  };


  const editPortDetails = async (swSchdId) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/wired/conf/poePortDetails/${swSchdId}`);

      if (response && Array.isArray(response)) {
        const fetchedSwSchdId = response[0]?.swSchdId.swSchdId || null;
        console.log("Fetched swSchdId:", fetchedSwSchdId);

        const portDetails = response[0]?.portDetails || [];
        const deviceId = response[0]?.deviceId || null;

        setEditPortsData({
          deviceId,
          portDetails,
          idss: fetchedSwSchdId,
        });

        const initialAssignedPorts = {};
        portDetails.forEach((port) => {
          initialAssignedPorts[port] = true;
        });
        setAssignedEditPorts(initialAssignedPorts);
        setEditPortModel(true);
      } else {
        console.warn("Unexpected API response format", response);
      }
    } catch (error) {
      console.error("Error fetching port details", error);
    }
  };




  const cancelSchedule = () => {
    setSchedule({
      name: "",
      allDay: "0",
      startTime: null,
      endTime: null,
      recurrence: {
        type: 2,
        startDate: null,
        endDate: null,
        weekDay: [],
      }
    });
    setScheduleModal(false);
  }

  const togglePort = (portNumber) => {
    setAssignedPorts((prev) => ({
      ...prev,
      [portNumber]: !prev[portNumber], 
    }));
  };
  const toggleEditPort = (portNumber) => {
    setAssignedEditPorts((prev) => ({
      ...prev,
      [portNumber]: !prev[portNumber], 
    }));
  };
  return (
    <div>
      {listModel && scheduleList.length > 0 ? (
        <div className="poe-schedule w-scroll">
          <div className="accordion mt-4" id="poeSchedule">
            {scheduleList.map((schedule, index) => (
              <div className="accordion-item" key={schedule.swSchdId}>
                <h2 className="accordion-header" id={`heading${index}`}>
                  <button
                    className={`accordion-button ${index !== 0 ? "collapsed" : ""}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded={index === 0}
                    aria-controls={`collapse${index}`}
                  >
                    {schedule.schdName}
                  </button>
                </h2>
                <div
                  id={`collapse${index}`}
                  className={`accordion-collapse collapse ${index === 0 ? "show" : ""}`}
                  aria-labelledby={`heading${index}`}
                  data-bs-parent="#poeSchedule"
                >
                  <div className="accordion-body">
                    <div className="schedule-action">
                      <div className="tab-form">
                        <div className="switch-box">
                          <strong>Enable Scheduled Downtime</strong>
                          <label className="ice-switch">
                            <input
                              type="checkbox"
                              checked={enableSchedule}
                              onChange={() => handleToggle(schedule)}
                            />
                            <span className="slider"></span>
                          </label>
                        </div>

                      </div>
                      <div className="schedule-btn">
                        <button
                          type="button"
                          className="btn btn-outline"
                          onClick={() => handleEditClick(schedule)}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline"
                          onClick={() =>
                            handleEditPortClick(schedule)
                          }
                        >
                          Edit Ports
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline"
                          onClick={() => {
                            setScheduleId(schedule.swSchdId);
                            setDeleteScheduleModel(true);
                          }}
                        >
                          Delete
                        </button>

                      </div>
                      {schedule.recurrenceType === "2" && (
                        <div className="row mt-3 mb-3">
                          <div className="col-lg-12">
                            <div className="weekdays">
                              {["S", "M", "T", "W", "TH", "F", "S"].map((day, index) => {
                                const dayNumber = (index + 1).toString();
                                return (
                                  <label key={dayNumber}>
                                    <input
                                      type="checkbox"
                                      checked={schedule.recurrenceWeekDay.includes(dayNumber)}
                                      value={schedule.recurrenceWeekDay}
                                    />
                                    <span>{day}</span>
                                  </label>
                                );
                              })}
                            </div>

                          </div>
                        </div>
                      )}
                    </div>
                    <div className="poe-schdule-time">
                      <div>
                        <span>Time Range</span>
                        <strong>
                          {schedule.startTime} - {schedule.endTime}
                        </strong>
                      </div>
                      <div>
                        <span>Recurrence</span>
                        <strong>
                          {schedule.recurrenceStartDate} - {schedule.recurrenceEndDate}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <p className="mt-4">
            To reset a switch port PoE, go to switch Device Dashboard{" "}
            <i className="fa fa-angle-right" aria-hidden="true"></i> PoE Management.
          </p>
        </div>
      ) : (
        <div className="poe-schedule-banner">
          <div>
            <img src={portpic} alt="Port" />
            <p>Create a PoE downtime schedule to disable PoE ports for enhanced security and energy savings.</p>
            <button className="text-btn primary-btn" onClick={() => setScheduleModal(true)}>
              Create PoE Downtime Schedule
            </button>
          </div>
        </div>
      )}

      {scheduleModal && (
        <div className="customModal">
          <div className="c-modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create New PoE Downtime Schedule</h5>
                <button type="button" className="btn-close" onClick={() => cancelSchedule()}></button>
              </div>
              <div className="modal-body add-ssid-body">

                <p>Set up a schedule to disable PoE power on selected ports.</p>
                <div className="row mb-4">
                  <div className="col-lg-6">
                    <label htmlFor="ScheduleName" className="col-form-label">Schedule Name</label>
                    <input type="text"
                      className="form-control"
                      name="ScheduleName"
                      placeholder="Schedule Name"
                      value={schedule.name}
                      onChange={(e) => updateScheduleField("name", e.target.value)} />
                  </div>
                </div>

                <div className="border-line"></div>
                <h4 className="mt-3">Downtime Range</h4>
                <p>Select the start and end of the time range in which PoE ports are disabled for the day.</p>

                <div className="row mb-3">
                  <div className="col-lg-6">
                    <div className="tab-form">
                      <div className="switch-box">
                        <strong>All Day</strong>
                        <label className="ice-switch">
                          <input type="checkbox"
                            checked={schedule.allDay === "1"}
                            onChange={(e) => updateScheduleField("allDay", e.target.checked ? "1" : "0")} />
                          <span className="slider"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {schedule.allDay === "0" &&(
                <div className="row mb-4">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="col-lg-4">
                      {/* Start Time */}
                      <label htmlFor="StartOn" className="col-form-label">
                        Start On
                      </label>
                      <TimePicker
                        renderInput={(params) => <TextField {...params} />}
                        value={schedule.startTime ? dayjs(schedule.startTime, "HH:mm") : null} // Convert back for TimePicker
                        onChange={(newValue) => updateScheduleField("startTime", newValue)}
                      />
                    </div>
                    <div className="col-lg-4">
                      {/* End Time */}
                      <label htmlFor="EndOn" className="col-form-label">
                        End On
                      </label>
                      <TimePicker
                        renderInput={(params) => <TextField {...params} />}
                        value={schedule.endTime ? dayjs(schedule.endTime, "HH:mm") : null} // Convert back for TimePicker
                        onChange={(newValue) => updateScheduleField("endTime", newValue)}
                      />
                    </div>
                  </LocalizationProvider>
                </div>
                )}
                <div className="border-line"></div>

                <h4 className="mt-3">Recurrence</h4>
                <div className="row">
                  <div className="col-lg-6">
                    <label htmlFor="Recurrence" className="col-form-label">Recurrence Type</label>
                    <select
                      id="securityAuthentication"
                      className="form-select"
                      value={schedule.recurrence.type}
                      onChange={(e) => updateRecurrenceField("type", parseInt(e.target.value, 10))} // Convert to integer
                    >
                      <option value={0}>None</option>
                      <option value={1}>Daily</option>
                      <option value={2}>Weekly</option>
                    </select>
                  </div>
                </div>

                {schedule.recurrence.type == 2 && (
                  <div className="row mt-3 mb-3">
                    <div className="col-lg-12">
                      <p>Select one or more days for the recurring schedule.</p>
                      <div className="weekdays">
                        {["S", "M", "T", "W", "TH", "F", "S"].map((day, index) => {
                          const dayNumber = (index + 1).toString();
                          return (
                            <label key={dayNumber}>
                              <input
                                type="checkbox"
                                checked={schedule.recurrence.weekDay.includes(dayNumber)}
                                onChange={() => {
                                  const updatedDays = schedule.recurrence.weekDay.includes(dayNumber)
                                    ? schedule.recurrence.weekDay.filter((d) => d !== dayNumber)
                                    : [...schedule.recurrence.weekDay, dayNumber];
                                  setSchedule((prev) => ({
                                    ...prev,
                                    recurrence: { ...prev.recurrence, weekDay: updatedDays },
                                  }));
                                }}
                              />
                              <span>{day}</span>
                            </label>
                          );
                        })}
                      </div>

                    </div>
                  </div>
                )}
                <div className="row">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="col-lg-4">
                      <label htmlFor="StartOn" className="col-form-label">
                        Start On
                      </label>
                      <DatePicker
                        renderInput={(params) => <TextField {...params} />}
                        value={schedule.recurrence.startDate ? dayjs(schedule.recurrence.startDate) : null} // Convert back to Day.js object for the picker
                        onChange={(newValue) => updateRecurrenceField("startDate", newValue)}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="EndOn" className="col-form-label">
                        End On
                      </label>
                      <DatePicker
                        renderInput={(params) => <TextField {...params} />}
                        value={schedule.recurrence.endDate ? dayjs(schedule.recurrence.endDate) : null} // Convert back to Day.js object for the picker
                        onChange={(newValue) => updateRecurrenceField("endDate", newValue)}
                      />
                    </div>
                  </LocalizationProvider>
                </div>


              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline" onClick={() => cancelSchedule()}>
                  Cancel
                </button>
                <button type="button" className="text-btn primary-btn" onClick={() => {
                  setPortModel(true);
                  setScheduleModal(false)
                  addSchedule("save&choose");
                }}>
                  Save and Choose Ports
                </button>
                <button type="button"
                  className="text-btn primary-btn"
                  onClick={() => {
                    addSchedule("save");
                    setScheduleModal(false);
                    setInitialPage(false);
                    setListModel(true);
                  }}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {editScheduleModel && (
        <div className="customModal">
          <div className="c-modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit PoE Downtime Schedule</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setEditScheduleModel(false)}
                ></button>
              </div>
              <div className="modal-body add-ssid-body">
                <p>Set up a schedule to disable PoE power on selected ports.</p>
                <div className="row mb-4">
                  <div className="col-lg-6">
                    <label htmlFor="ScheduleName" className="col-form-label">
                      Schedule Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="ScheduleName"
                      placeholder="Schedule Name"
                      value={editScheduleData?.schdName}
                      onChange={(e) => updateEditScheduleField("schdName", e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-6">
                    <div className="switch-box">
                      <strong>All Day</strong>
                      <label className="ice-switch">
                        <input
                          type="checkbox"
                          checked={editScheduleData?.allDay == 1}
                          onChange={(e) =>
                            updateEditScheduleField("allDay", e.target.checked ? 1 : 0)
                          }
                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="col-lg-4">
                      <label htmlFor="StartOn" className="col-form-label">
                        Start On
                      </label>
                      <TimePicker
                        renderInput={(params) => <TextField {...params} />}
                        value={editScheduleData?.startTime ? dayjs(editScheduleData?.startTime, "HH:mm") : null}
                        onChange={(newValue) =>
                          updateEditScheduleField(
                            "startTime",
                            newValue ? newValue.format("HH:mm") : ""
                          )
                        }
                      />
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="EndOn" className="col-form-label">
                        End On
                      </label>
                      <TimePicker
                        renderInput={(params) => <TextField {...params} />}
                        value={editScheduleData?.endTime ? dayjs(editScheduleData?.endTime, "HH:mm") : null}
                        onChange={(newValue) =>
                          updateEditScheduleField(
                            "endTime",
                            newValue ? newValue.format("HH:mm") : ""
                          )
                        }
                      />
                    </div>
                  </LocalizationProvider>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <label htmlFor="Recurrence" className="col-form-label">
                      Recurrence Type
                    </label>
                    <select
                      id="recurrenceType"
                      className="form-select"
                      value={editScheduleData?.recurrenceType}
                      onChange={(e) => {
                        const newType = parseInt(e.target.value, 10);
                        setEditScheduleData((prev) => ({
                          ...prev,
                          recurrenceType: newType,
                          recurrenceWeekDay: newType === 2 ? prev.recurrenceWeekDay : [],
                        }));
                      }}
                    >
                      <option value={0}>None</option>
                      <option value={1}>Daily</option>
                      <option value={2}>Weekly</option>
                    </select>

                  </div>
                </div>
                {editScheduleData?.recurrenceType == 2 && (
                  <div className="row mt-3 mb-3">
                    <div className="col-lg-12">
                      <p>Select one or more days for the recurring schedule.</p>
                      <div className="weekdays">
                        {["S", "M", "T", "W", "TH", "F", "S"].map((day, index) => {
                          const dayNumber = (index + 1).toString();
                          return (
                            <label key={dayNumber}>
                              <input
                                type="checkbox"
                                checked={editScheduleData?.recurrenceWeekDay.includes(dayNumber)}
                                onChange={() => {
                                  setEditScheduleData((prev) => {
                                    const updatedDays = prev.recurrenceWeekDay.includes(dayNumber)
                                      ? prev.recurrenceWeekDay.filter((d) => d !== dayNumber) // Remove if already selected
                                      : [...prev.recurrenceWeekDay, dayNumber]; // Add if not selected
                                    return {
                                      ...prev,
                                      recurrenceWeekDay: updatedDays,
                                    };
                                  });
                                }}
                              />
                              <span>{day}</span>
                            </label>

                          );
                        })}
                      </div>

                    </div>
                  </div>
                )}
                <div className="row">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="col-lg-6">
                      <label htmlFor="StartOn" className="col-form-label">
                        Start On
                      </label>
                      <DatePicker
                        renderInput={(params) => <TextField {...params} />}
                        value={
                          editScheduleData?.recurrenceStartDate
                            ? dayjs(editScheduleData?.recurrenceStartDate)
                            : null
                        }
                        onChange={(newValue) =>
                          updateEditScheduleField(
                            "recurrenceStartDate",
                            newValue ? newValue.toISOString() : null
                          )
                        }
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="EndOn" className="col-form-label">
                        End On
                      </label>
                      <DatePicker
                        renderInput={(params) => <TextField {...params} />}
                        value={
                          editScheduleData?.recurrenceEndDate
                            ? dayjs(editScheduleData?.recurrenceEndDate)
                            : null
                        }
                        onChange={(newValue) =>
                          updateEditScheduleField(
                            "recurrenceEndDate",
                            newValue ? newValue.toISOString() : null
                          )
                        }
                      />
                    </div>
                  </LocalizationProvider>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={() => setEditScheduleModel(false)}
                >
                  Cancel
                </button>
                <button type="button"
                  className="text-btn primary-btn"
                  onClick={() => updateSchedule()}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteScheduleModel && (
        <div className="customModal confirm-modal">
          <div className="modal-dialog modal-dialog-centered small">
            <div className="modal-content inc-modal">
              <div className="modal-body">
                <img src={errorIcon} alt="error" />
                <p>This schedule will be deleted from the network, Device will no longer follow any PoE Schedules.</p>
                <h3>Do you want to continue?</h3>
                <div className="modal-footer">
                  <button type="button" className="btn btn-outline" onClick={() => setDeleteScheduleModel(false)}>No</button>
                  <button className="text-btn primary-btn" onClick={() => deleteSchedule(scheduleId)}>Yes</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {portModel && info && (
        <div className="customModal">
          <div className="c-modal-dialog modal-dialog-centered medium">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Apply Schedule</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setPortModel(false)}
                ></button>
              </div>
              <div className="modal-body">
                {/* Display device information */}
                {info?.switchDetails?.length > 0 ? (
                  info.switchDetails.map((device, idx) => (
                    <div className="device-info" key={`device-${idx}`}>
                      <p>
                        <strong>Device Serial Number:</strong> {device.serial_number || "N/A"}
                      </p>
                      <p>
                        <strong>Device ID:</strong> {device.device_id || "N/A"}
                      </p>
                    </div>
                  ))
                ) : (
                  <div className="no-device-info">
                    <p>No device information found. Please check the response data.</p>
                  </div>
                )}


                <p>Select the ports to disable for "Testing".</p>
                <ul>
                  <li>Selected ports will be disabled according to the schedule.</li>
                  <li>Unselected ports are not affected by this schedule.</li>
                </ul>

                {/* Render Ports */}
                {info?.switchDetails?.length > 0 &&
                  info.switchDetails.map((device) => (
                    <div key={device.device_id}>
                      <div className="mb-2 router-port">
                        <div className="mxw-850">
                          <div className="polygon"></div>
                          <div className="port-box">
                            <ul className="port24">
                              {[...Array(device.no_of_ports)].map((_, index) => {
                                const portNumber = (index + 1).toString();
                                const isAssigned =
                                  assignedPorts[portNumber] || false; // Default to unassigned
                                return (
                                  <li
                                    key={portNumber}
                                    className={isAssigned ? "assign" : "unassign"}
                                    onClick={() => togglePort(portNumber)}
                                  >
                                    {portNumber}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={() => setPortModel(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="text-btn primary-btn"
                  onClick={() => addApplySchedule()} 
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}



      {editPortModel && (
        <div className="customModal">
          <div className="c-modal-dialog modal-dialog-centered medium">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Ports</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setEditPortModel(false)}
                ></button>
              </div>
              <div className="modal-body">
                {/* Display Device ID */}
                <p><strong>Device ID:</strong> {editPortsData.deviceId}</p>
                <p>Select the ports to disable for "Testing".</p>
                <ul>
                  <li>Selected ports will be disabled according to the schedule.</li>
                  <li>Unselected ports are not affected by this schedule.</li>
                </ul>
                {editPortsData.portDetails.length > 0 ? (
                  <div className="mb-2 router-port">
                    <div className="mxw-650">
                      <div className="polygon"></div>
                      <div className="port-box">
                        <ul className="port54">
                          {[...Array(54)].map((_, index) => {
                            const portNumber = (index + 1).toString();
                            const isAssigned = assignedEditPorts[portNumber] || false;
                            return (
                              <li
                                key={portNumber}
                                className={isAssigned ? "assign" : "unassign"}
                                onClick={() => toggleEditPort(portNumber)}
                              >
                                {portNumber}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p>No switch found to configure.</p>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={() => setEditPortModel(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="text-btn primary-btn"
                  onClick={(schedule) => handleUpdatePortClick(editPortsData, assignedEditPorts, schedule.swSchdId)} // Replace 3 with dynamic scheduleId
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={showSuccessPopup}
        onHide={handleCancelPopUp}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Schedule created successfully</p>
        <Button onClick={handleCancelPopUp}>
          OK
        </Button>
      </Modal>
      <Modal
        show={showEditPortSuccessPopup}
        onHide={handleCancelPopUp}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Port Details changed successfully</p>
        <Button onClick={handleCancelPopUp}>
          OK
        </Button>
      </Modal>
      <Modal
        show={showEditSuccessPopup}
        onHide={handleCancelPopUp}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Schedule edited successfully</p>
        <Button onClick={handleCancelPopUp}>
          OK
        </Button>
      </Modal>
    </div>
  );
}

export default DowntimeSchedules;