import React, { useEffect, useState } from "react";
import allApi from "../../../../api/allApi";
import errorIcon from "../../../../assets/images/error-2.png";

function FastRoaming() {
  const [fastRoaming, setFastRoaming] = useState(null);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [pendingStatus, setPendingStatus] = useState(null); 

  useEffect(()=>{
  getFastRoaming();
  },[]);

  const getFastRoaming = async() =>{
    try{
     const api = new allApi();
     const result = await api.getRequest("api/wireless/getFastRoaming?networkId=6001");
     console.log("status",result.response.fastRoaming.status);
     if (result && result.response && result.response.fastRoaming && result.response.fastRoaming.status == 1) {
      setFastRoaming(true);
      
    } else {
      setFastRoaming(false);
    }
    
    }catch(error){
      console.error("Error fetching fast roaming",error);
    }
  };

  const inputFastRoaming = async(status) =>{
    try{
     const api = new allApi();
     const payload = {
       fastRoaming: status,
     }
     const response = await api.postRequest("api/wireless/fastRoaming?networkId=6001","",payload);
     if(response){
      setFastRoaming(status);
     }
    }catch(error){
    console.error("Error in  enabling/disabling Fast Roaming",error);
    }
  };

  const handleEnableRoaming = () => {
    if (fastRoaming) {
      // Show confirmation modal when toggling from true to false
      setPendingStatus(false);
      setShowModal(true);
    } else {
      inputFastRoaming(!fastRoaming);
    }
  };

  const handleModalConfirm = () => {
    setShowModal(false);
    if (pendingStatus !== null) {
      inputFastRoaming(pendingStatus);
      setPendingStatus(null);
    }
  };

  const handleModalCancel = () => {
    setShowModal(false);
    setPendingStatus(null);
  };

  return (
    <div className="tab-form mt-3">
      <p>Enables roaming at the network level, including OKC roaming. When enabled, you can also enable
        Fast Roaming (802.11r) for <br />an SSID in Wireless Settings <i className="fa fa-angle-right" aria-hidden="true"></i> Radio Settings.</p>

      <div className="row mt-4">
        <div className="col-lg-4">
          <div className="switch-box">
            <strong>Enable</strong>
            {fastRoaming !== null ? (
              <label className="ice-switch">
                <input
                  type="checkbox"
                  checked={fastRoaming}
                  onChange={handleEnableRoaming}
                />
                <span className="slider"></span>
              </label>
            ) : (
              <p>Loading...</p> // Display a placeholder while the state is being fetched
            )}
          </div>
        </div>
      </div>

      {showModal && (
        <div className="customModal confirm-modal">
          <div className="modal-dialog modal-dialog-centered small">
            <div className="modal-content inc-modal">
              <div className="modal-body">
                <img src={errorIcon} alt="error" />
                <p>
                  Disabling Fast Roaming will also disable Fast Roaming (802.11r) for all SSIDs in this
                  network location.
                </p>
                <h3>Do you want to continue?</h3>
                <div className="modal-footer">
                  <button type="button" className="btn btn-outline" onClick={handleModalCancel}>
                    No
                  </button>
                  <button className="text-btn primary-btn" onClick={handleModalConfirm}>
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FastRoaming;
