import React from 'react';
const NotFound = () => {
  return (
    <div className="not-found-container">
      <h2 className="not-found-title">404 Page Not Found</h2>
      <h5 className="not-found-message">The page you are looking for does not exist.</h5>
    </div>
  );
};

export default NotFound;
