import React from 'react'
import { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import Loader from '../../common/Loader';
import allApi from '../../../../api/allApi';

function WiredPowerExpansion() {
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPoeUsage = async () => {
      try {
        setLoading(true);
        // setRefreshing(true);
        const api = new allApi();
        const poeData = await api.getRequest(`api/wired/dashboard/poeUsage`);

        if (poeData && poeData.success && Array.isArray(poeData.success)) {
          const seriesData = poeData.success.map(item => ({
            x: item.serialNo,
            y: item.poe,
            z: item.total_power
          }));

          setSeries([{ data: seriesData }]);

          // Handle failure cases if needed
          if (poeData.failure && Array.isArray(poeData.failure)) {
            console.warn("Failed serial numbers:", poeData.failure);
          }
        } else {
          console.error("Unexpected poeData format:", poeData);
        }
      } catch (error) {
        console.error("Error fetching POE usage", error);
      } finally {
        setLoading(false);
        // setRefreshing(false);
      }
    };
    getPoeUsage();
  }, []);


  const poeOptions = {
    chart: {
      type: "bar",
      height: 135,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: "Power Usage",
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
    },
    tooltip: {
      //shared: true,
      intersect: false,
      x: {
        show: true,
      },
      y: {
        formatter: function (value) {
          return `POE: ${value} `;
        }
      },
      z: {
        title: 'Total Power: ',
        formatter: function (value) {
          return `${value} W`;
        }
      }
    },
    fill: {
      opacity: 1,
    },
  };


  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Power Usage</h3>
            <div className="inc-card-button">
              {/* <input className="list-search" type="text" /> */}
              <button className="icon-btn" title="Refresh">
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
              {/* <button className="icon-btn" title="Filter">
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button> */}
            </div>
          </div>
          <div className="inc-card-body">
            <div className='row'>
              <div className='col-lg-8'>
                <div className="inc-card-body">
                  {loading ? (
                    <Loader />
                  ) : (
                    <div>
                      {series.length === 0 || !series[0].data.length ? (
                        <div className="no-data-box">
                          <div className="no-data-text">No Data Available</div>
                        </div>
                      ) : (
                        <ApexCharts
                          options={poeOptions}
                          series={series}
                          type="bar"
                          height={250}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='round-border'>
                  <div className="inc-card-table">
                    <table className="m-head">
                      <thead>
                        <tr>
                          <th>Serial No</th>
                          <th>POE Usgae</th>
                          <th>Total Power</th>
                        </tr>
                      </thead>
                      <tbody>
                        {series && series.length > 0 &&
                          series[0].data &&
                          series[0].data.map((item, index) => (
                            <tr key={index}>
                              <td>{item.x}</td>
                              <td>{item.y}</td>
                              <td>{item.z}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WiredPowerExpansion;
