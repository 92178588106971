import React, { useState, useEffect } from "react";
import allApi from "../../../api/allApi";

const EditTemplateModal = ({
  showPopUp,
  handleClose,
  headerMessage1,
  handleSuccess,
  template,
}) => {
  const [formValues, setFormValues] = useState({
    template_name: "",
    snmp_port: 161,
    retry_count: 3,
    timeout: "",
    version: "",
    read_only_community: "",
    readwrite_community: "",
    trap_community: "HFcltD#123!@",
    trap_host: "",
    trap_port: 162,
    trap_host_version: "",
    username: "",
    security_level: "",
    authentication_password: "",
    encryption: "",
    access_privilege: "",
    privacy_key: "",
    status: "Active",
    dcdr_config: "incx_dcdr",
    dcdr_interval: 300,
    dcdr_retry_count: 5,
    dcdr_rev_retry_count: 5,
    dcdr_switch_rev_interval: 300,
    dcdr_rev_time: 30,
  });

  const [error, setError] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [version, setVersion] = useState("");
  const [trapVersion, setTrapVersion] = useState("");

  useEffect(() => {
    if (template) {
      setFormValues({
        template_name: template.template_name,
        snmp_port: template.snmp_port,
        retry_count: template.retry_count,
        timeout: template.timeout,
        version: template.version,
        read_only_community: template.read_only_community,
        readwrite_community: template.readwrite_community,
        trap_community: template.trap_community,
        trap_host: template.trap_host,
        trap_port: template.trap_port,
        trap_host_version: template.trap_host_version,
        username: template.username || "",
        security_level: template.security_level || "",
        authentication_password: template.authentication_password || "",
        encryption: template.encryption || "",
        access_privilege: template.access_privilege || "",
        privacy_key: template.privacy_key || "",
        status: template.status || "Active",
        dcdr_config: template.dcdr_config || "incx_dcdr",
        dcdr_interval: template.dcdr_interval || 300,
        dcdr_retry_count: template.dcdr_retry_count || 5,
        dcdr_rev_retry_count: template.dcdr_rev_retry_count || 5,
        dcdr_switch_rev_interval: template.dcdr_switch_rev_interval || 300,
        dcdr_rev_time: template.dcdr_rev_time || 30,
      });
    }
  }, [template]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: isNaN(value) ? value : Number(value),
    });
  };

  const handleTrapVersionChange = (e) => {
    setTrapVersion(e.target.value);
    setFormValues((prevValues) => ({
      ...prevValues,
      trap_host_version: e.target.value,
    }));
  };
  const handleVersionChange = (e) => {
    setVersion(e.target.value);
    setFormValues((prevValues) => ({
      ...prevValues,
      version: e.target.value,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = [
      "template_name",
      "snmp_port",
      "retry_count",
      "timeout",
      "trap_host_version",
      "read_only_community",
      "readwrite_community",
      "trap_community",
      "trap_host",
      "trap_port",
    ];
    for (const field of requiredFields) {
      if (!formValues[field]) {
        setError(`Field ${field} is required.`);
        return;
      }
    }

    const payload = {
      template_name: formValues.template_name,
      snmp_port: formValues.snmp_port,
      retry_count: formValues.retry_count,
      timeout: formValues.timeout,
      version: formValues.version,
      read_only_community: formValues.read_only_community,
      readwrite_community: formValues.readwrite_community,
      trap_community: formValues.trap_community,
      trap_host: formValues.trap_host,
      trap_port: formValues.trap_port,
      trap_host_version: formValues.trap_host_version,
      status: formValues.status,
      dcdr_config: "incx_dcdr",
      dcdr_interval: 300,
      dcdr_retry_count: 5,
      dcdr_rev_retry_count: 5,
      dcdr_switch_rev_interval: 300,
      dcdr_rev_time: 30,
    };

    if (formValues.trap_host_version === "v3") {
      payload.username = formValues.username;
      payload.security_level = formValues.security_level;
      payload.authentication_password = formValues.authentication_password;
      payload.encryption = formValues.encryption;
      payload.access_privilege = formValues.access_privilege;
      payload.privacy_key = formValues.privacy_key;
    }
    const api = new allApi();
    try {
      let response;
      if (template) {
        response = await api.putRequest(
          `api/snmp/template/update/${template.snmp_template_id}`,
          payload
        );
      }
      handleClose();
      setShowSuccessMessage(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {showPopUp && (
        <div
          className={"modal fade customModal " + (showPopUp ? " show" : "")}
          id="editUserModal"
          style={{ display: showPopUp ? "block" : "none", zIndex: 1009 }}
        >
          <div className="modal-dialog modal-dialog-centered create-usr-pop">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit User</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body">
                <div className="tab-content">
                  <div className="tab-pane fade show active">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <label
                            htmlFor="templateName"
                            className="col-form-label"
                          >
                            Template Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="templateName"
                            name="template_name"
                            value={formValues.template_name}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="port" className="col-form-label">
                            Port<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="port"
                            name="snmp_port"
                            value={formValues.snmp_port}
                            onChange={handleInputChange}
                          />
                          {error && error.includes("snmp_port") && (
                            <div className="text-danger">{error}</div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <label
                            htmlFor="retryCount"
                            className="col-form-label"
                          >
                            Retry Count<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="retryCount"
                            name="retry_count"
                            value={formValues.retry_count}
                            onChange={handleInputChange}
                          />
                          {error && error.includes("retry_count") && (
                            <div className="text-danger">{error}</div>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="timeOut" className="col-form-label">
                            Time Out<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="timeOut"
                            name="timeout"
                            value={formValues.timeout}
                            onChange={handleInputChange}
                          />
                          {error && error.includes("timeout") && (
                            <div className="text-danger">{error}</div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="version" className="col-form-label">
                            Trap Host Version
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            id="version"
                            name="version"
                            value={formValues.trap_host_version}
                            onChange={handleTrapVersionChange}
                          >
                            <option value="">Select Version</option>
                            <option value="IPv4">ipv4</option>
                            <option value="IPv6">ipv6</option>
                          </select>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="readOnly" className="col-form-label">
                            Read Only Community
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="readOnly"
                            name="read_only_community"
                            value={formValues.read_only_community}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="readWrite" className="col-form-label">
                            Read Write Community
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="readWrite"
                            name="readwrite_community"
                            value={formValues.readwrite_community}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label
                            htmlFor="trapCommunity"
                            className="col-form-label"
                          >
                            Trap Community
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="trapCommunity"
                            name="trap_community"
                            value={formValues.trap_community}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="trapHost" className="col-form-label">
                            Trap Host<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="trapHost"
                            name="trap_host"
                            value={formValues.trap_host}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="trapPort" className="col-form-label">
                            Trap Port<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="trapPort"
                            name="trap_port"
                            value={formValues.trap_port}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <label
                            htmlFor="trapVersion"
                            className="col-form-label"
                          >
                            Version
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            id="version"
                            name="trap_host_version"
                            value={version}
                            onChange={handleVersionChange}
                          >
                            <option value="">Select Version</option>
                            <option value="v1">v1</option>
                            <option value="v2c">v2c</option>
                            <option value="v3">v3</option>
                          </select>
                        </div>
                      </div>
                      {version === "v3" && (
                        <div className="row">
                          <div className="col-lg-6">
                            <label
                              htmlFor="username"
                              className="col-form-label"
                            >
                              Username<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              name="username"
                              value={formValues.username}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="securityLevel"
                              className="col-form-label"
                            >
                              Security Level
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="securityLevel"
                              name="security_level"
                              value={formValues.security_level}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="authMethod"
                              className="col-form-label"
                            >
                              Authentication Method
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="authMethod"
                              name="auth_method"
                              value={formValues.auth_method}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="authPassword"
                              className="col-form-label"
                            >
                              Authentication Password
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="authPassword"
                              name="authentication_password"
                              value={formValues.authentication_password}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="encryption"
                              className="col-form-label"
                            >
                              Encryption<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="encryption"
                              name="encryption"
                              value={formValues.encryption}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="privacyKey"
                              className="col-form-label"
                            >
                              Privacy Key
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="privacyKey"
                              name="privacy_key"
                              value={formValues.privacy_key}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="accessPrivilege"
                              className="col-form-label"
                            >
                              Access Privilege
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="accessPrivilege"
                              name="access_privilege"
                              value={formValues.access_privilege}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      )}
                      <div className="modal-footer pb-0">
                        <button
                          type="button"
                          className="btn btn-outline"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-fill"
                          aria-label="Close"
                        >
                          {template ? "Update" : "Add"}
                        </button>
                      </div>
                      {showSuccessMessage && (
                        <div className="alert alert-success mt-3" role="alert">
                          Template {template ? "updated" : "created"}{" "}
                          successfully!
                        </div>
                      )}
                      {error && (
                        <div className="alert alert-danger mt-3" role="alert">
                          {error}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditTemplateModal;
