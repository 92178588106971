import React, { useState } from "react";


const PopupTagDevice = ({
    showPopUp,
    handleClose,
    headerMessage,
    getTagValue,
    handleSaveTag
}) => {
    const [tag, setTag] = useState("");


    const handleChangeTagPopup = (event) =>{
        const {value} = event.target;
        setTag(value);
        getTagValue(value);
    }

    async function closePopUp(){
        setTag("");
    }
    if (!showPopUp) return null;
    
    return(
      <div
      className={"modal fade customModal" + (showPopUp ? " show" : "")}
      style={{ display: showPopUp ? "block" : "none", zIndex: 1009 }}
    >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {headerMessage}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  onClick={()=>{handleClose();closePopUp()}}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="recipient-name" className="col-form-label">
                      Enter tag to assign to device
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      value={tag}
                      onChange={handleChangeTagPopup}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="ibtn btn-outline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={()=>{handleClose();closePopUp()}}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="ibtn btn-fill"
                  onClick={() => {
                    handleSaveTag();
                    handleClose();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
    )
};

export default PopupTagDevice;