// Layout.js
import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";

function Layout({ children, heading }) {
  const location = useLocation();

  // Define which headings should display breadcrumbs
  const showBreadcrumb = heading === "Device Management" || heading === "Model Management";

  return (
    <div className="app-layout">
      <div id="layout-wrapper">
        <Header heading={heading} showBreadcrumb={showBreadcrumb} />
        <Sidebar />
        <main className="body-container">
          {children}
          <div className="copyright-text">
            INC &copy; 2024, VVDN All right reserved
          </div>
        </main>
      </div>
    </div>
  );
}

export default Layout;
