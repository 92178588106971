import React, { useEffect, useState } from "react";
import allApi from "../../../../api/allApi";
import Loader from "../../common/Loader";
import errorIcon from "../../../../assets/images/error-2.png";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const UrlFiltering = ({ addUrl, setAddUrl }) => {
  const [isBlockedListEnabled, setIsBlockedListEnabled] = useState(false);
  const [blockedList, setBlockedList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newDomain, setNewDomain] = useState("");
  const [domain, setDomain] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Fetch blocked list on initial load
  useEffect(() => {
    fetchBlockedList();
  }, []);

  const fetchBlockedList = async () => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(
        "api/wireless/getUrlFiltering?networkId=6001"
      );

      if (response && response.status === "OK") {
        setIsBlockedListEnabled(response.response.urlAclStatus === 1);
        setBlockedList(response.response.urlBlackList || []);
      } else {
        console.error("Failed to fetch blocked list:", response?.message);
      }
    } catch (error) {
      console.error("Error in fetching blocked list:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBlockedListToggle = async () => {
    const newStatus = !isBlockedListEnabled;
    await toggleBlockedList(newStatus);
  };

  const handleCloseModal = () => {
    setAddUrl(false);
    setNewDomain("");
  };

  const handleDomainChange = (e) => {
    setNewDomain(e.target.value);
  };

  const handleAddDomain = async () => {
    if (!newDomain.trim()) return;

    setLoading(true);
    try {
      const api = new allApi();
      const payload = {
        urlStatus: 1,
        domain: [newDomain.trim()],
      };

      const response = await api.postRequest(
        "api/wireless/urlFiltering?networkId=6001",
        "",
        payload
      );

      if (response && response.status === "OK") {
        setBlockedList(response.response.wn_black_list || []);
        setAddUrl(false);
        setNewDomain("");
        setShowSuccessModal(true);
      } else {
        console.error("Failed to add domain:", response?.message);
      }
    } catch (error) {
      console.error("Error in adding domain:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDomain = async (domain) => {
    setLoading(true);
    try {
      const api = new allApi();
      const payload = {
        domain: [domain],
        urlStatus: 1,
      };

      const response = await api.deleteRequest(
        "api/wireless/deleteUrlFilter?networkId=6001",
        payload
      );

      if (response && response.status === "OK") {
        setBlockedList(response.response.wn_black_list || []); // Update the blocked list
      } else {
        console.error("Failed to delete domain:", response?.message);
      }
    } catch (error) {
      console.error("Error in deleting domain:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleBlockedList = async (status) => {
    setLoading(true);
    try {
      const api = new allApi();
      const payload = { urlStatus: status ? 1 : 0 };
      const response = await api.postRequest(
        "api/wireless/urlFiltering?networkId=6001",
        "",
        payload
      );

      if (response && response.status === "OK") {
        setIsBlockedListEnabled(status);
        setBlockedList(response.response.wn_black_list || []);
      } else {
        console.error("Failed to update URL filtering:", response?.message);
      }
    } catch (error) {
      console.error("Error in API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const openDeleteModal = (domain) => {
    setDomain(domain);
  };

  return (
    <div className="tab-form">
      <div className="row mb-4">
        <div className="col-lg-4">
          <div className="switch-box">
            <strong>Blocked list</strong>
            <label className="ice-switch">
              <input
                type="checkbox"
                checked={isBlockedListEnabled}
                onChange={handleBlockedListToggle}
                disabled={loading}
              />
              <span className="slider"></span>
            </label>
          </div>
        </div>
      </div>

      {loading && <Loader />}

      {isBlockedListEnabled && (
        <div className="domain-wrapper">
          {blockedList.length > 0 ? (
            blockedList.map((domain, index) => (
              <div className="domain-list" key={index}>
                <h3>{domain}</h3>
                <button
                  title="Delete"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteUrlModal"
                  onClick={() => openDeleteModal(domain)}
                  disabled={loading}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            ))
          ) : (
            <p>No domains in the blocked list.</p>
          )}
        </div>
      )}

      {addUrl && (
        <div className="customModal">
          <div className="c-modal-dialog modal-dialog-centered small">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Domain</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label className="form-label">Add Domain</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="www.example.com"
                    value={newDomain}
                    onChange={handleDomainChange}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="text-btn primary-btn"
                  onClick={handleAddDomain}
                  disabled={loading}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`modal fade`}
        id="deleteUrlModal"
        tabIndex="-1"
        aria-labelledby="deleteUrlLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <img src={errorIcon} alt="error" />
              <p>
                Are you sure you want to delete the domain:{" "}
                <strong>{domain}</strong>?
              </p>
              <button
                className="text-btn primary-btn"
                data-bs-dismiss="modal"
                onClick={() => handleDeleteDomain(domain)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Domain URL added successfully</p>
        <Button
          onClick={() => {
            setShowSuccessModal(false);
            handleAddDomain();
          }}
        >
          OK
        </Button>
      </Modal>
    </div>
  );
};

export default UrlFiltering;
