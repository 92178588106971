import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import allApi from "../../../api/allApi";
import Loader from "../common/Loader";

function LinkDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { link_id } = location.state || {};

  const [linkDetails, setLinkDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!link_id) {
      navigate("/");
      return;
    }
    const fetchLinkDetails = async () => {
      try {
        const api = new allApi();
        const response = await api.getRequest(`api/snmp/links/${link_id}`);
        setLinkDetails(response);
      } catch (error) {
        console.error("Error fetching link details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLinkDetails();
  }, [link_id, navigate]);

  if (loading) {
    return <Loader />;
  }

  if (!linkDetails) {
    return <div>No link details available</div>;
  }

  const renderDeviceDetails = (device) => (
    <tr>
      <td>{device.ipv4_address || "N/A"}</td>
      <td>{device.mac_address || "N/A"}</td>
      <td>{device.dev_product_model || "N/A"}</td>
      <td>{device.managed_by_gateway || "N/A"}</td>
      <td>{device.device_up_time || "N/A"}</td>
      <td>
        <span
          className={`d-status ${
            device?.dev_status === 1 ? "online" : "offline"
          }`}
        >
          {device?.dev_status === 1 ? "Active" : "Inactive"}
        </span>
      </td>
    </tr>
  );

  return (
    <div>
      <div className="inc-card ch-100">
        <div className="inc-card-title">
          <h3>Link Details</h3>
        </div>
        <div className="inc-card-body">
          <div className="inc-card-table">
            <table className="m-head">
              <thead>
                <tr>
                  <th>Link Name</th>
                  <th>Link Uptime</th>
                  <th>Device Count</th>
                  <th>Created Time</th>
                  <th>Link Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{linkDetails.link_name || "N/A"}</td>
                  <td>{linkDetails.link_uptime || "N/A"}</td>
                  <td>{linkDetails.device_count}</td>
                  <td>{linkDetails.created_at || "N/A"}</td>
                  <td>
                    <span
                      className={
                        "d-status " +
                        (linkDetails?.link_status === 1 ? "offline" : "online")
                      }
                    >
                      {linkDetails?.link_status === 1 ? "Inactive" : "Active"}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="link-module">
            <div className="master-module">
              <div className="mm-title">Master</div>
              <div className="mm-body">
                <div className="inc-card-table">
                  <table className="m-head">
                    <thead>
                      <tr>
                        <th>Device IP</th>
                        <th>Device Mac</th>
                        <th>Product Model</th>
                        <th>Managed By</th>
                        <th>Device Uptime</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {linkDetails.master_device &&
                        renderDeviceDetails(linkDetails.master_device)}
                    </tbody>
                  </table>
                </div>
              </div>
              <span className="link">
                <i className="fa fa-link" aria-hidden="true"></i>
              </span>
            </div>

            {["slave_device1", "slave_device2", "slave_device3"].map(
              (slaveKey, index) =>
                linkDetails[slaveKey] && (
                  <div className="slave-module" key={index}>
                    <div className="sm-title">
                      Slave {index + 1}
                      <span className="line"></span>
                    </div>
                    <div className="sm-body">
                      <div className="inc-card-table">
                        <table className="m-head">
                          <thead>
                            <tr>
                              <th>Device IP</th>
                              <th>Device Mac</th>
                              <th>Product Model</th>
                              <th>Managed By</th>
                              <th>Device Uptime</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {renderDeviceDetails(linkDetails[slaveKey])}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LinkDetails;
