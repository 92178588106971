import React, { useState, useEffect } from "react";
import ConfigNet from "./ConfigNet";
import WiredSettings from "./WiredSettings";
import IpConfiguration from "./IpConfiguration";
import WirelessSettings from "./WirelessSettings";
import allApi from "../../../../api/allApi";

function ConfigureNetwork() {
  const [currentStep, setCurrentStep] = useState(1);
  const [configNetData, setConfigNetData] = useState({});
  const [wiredSettingsData, setWiredSettingsData] = useState(null);
  const [wirelessSettingsData, setWirelessSettingsData] = useState({});
  const [ipConfigurationData, setIpConfigurationData] = useState({});
  const [isStep1Valid, setIsStep1Valid] = useState(false);
  const [onDataChange,setOnDataChange]=useState("");
  useEffect(()=>{
    console.log("wiredSettingsData :",JSON.stringify(wiredSettingsData));
  },[wiredSettingsData]);
  const [formData, setFormData] = useState({
    vlanId: "",
    serialNumber: "",
    taggedPorts: "",
    untaggedPorts: "",
  });
  const [schedule, setSchedule] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });
  const [loading, setLoading] = useState(true);
  const [ssidList, setSsidList] = useState([]);
  const [basicSwitchInfo, setBasicSwitchInfo] = useState(null);
  const [refreshing, setRefreshing] = useState(true);
  const [assignedPorts, setAssignedPorts] = useState({});
  const [voiceOptimization, setVoiceOptimization] = useState(false);
  const [videoOptimization, setVideoOptimization] = useState(false);
  const [vlanId, setVlanId] = useState("");
  const [vlanName, setVlanName] = useState("");



  const handleWiredSettingsDataChange = (updatedPayloadList) => {
    setWiredSettingsData(updatedPayloadList);
  };

  // const fetchSwitchInfo = async () => {
  //   try {
  //     setLoading(true);
  //     const api = new allApi();
  //     const switchInfoResponse = await api.getRequest(
  //       "api/vlan/get/allSwitchBasicInfo"
  //     );
  //     console.log(switchInfoResponse, "info");
  //     if (
  //       switchInfoResponse &&
  //       switchInfoResponse.portMembers &&
  //       switchInfoResponse.portMembers.length > 0
  //     ) {
  //       const serialNumber = switchInfoResponse.portMembers[0].serialNo;
  //       setFormData((prev) => ({ ...prev, serialNumber }));
  //       setBasicSwitchInfo(switchInfoResponse);
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch switch info:", error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };


  const createScheduleSetting = (schedule) => {
    return {
      schDays: "1111111", // Customize as per selected days
      schOnTime0: schedule.Monday[0]?.startTime || "0000",
      schOffTime0: schedule.Monday[0]?.endTime || "0000",
      schOnTime1: schedule.Tuesday[0]?.startTime || "0000",
      schOffTime1: schedule.Tuesday[0]?.endTime || "0000",
      schOnTime2: schedule.Wednesday[0]?.startTime || "0000",
      schOffTime2: schedule.Wednesday[0]?.endTime || "0000",
      schOnTime3: schedule.Thursday[0]?.startTime || "0000",
      schOffTime3: schedule.Thursday[0]?.endTime || "0000",
      schOnTime4: schedule.Friday[0]?.startTime || "0000",
      schOffTime4: schedule.Friday[0]?.endTime || "0000",
      schOnTime5: schedule.Saturday[0]?.startTime || "0000",
      schOffTime5: schedule.Saturday[0]?.endTime || "0000",
      schOnTime6: schedule.Sunday[0]?.startTime || "0000",
      schOffTime6: schedule.Sunday[0]?.endTime || "0000",
    };
  };

  const validateStep = async (step) => {
    console.log(`Validating step ${step}`);
    switch (step) {
      case 1:
        console.log("Step 1 valid:", isStep1Valid);
        return isStep1Valid;
      case 2:
        const isValidStep2 =
          wiredSettingsData !== null &&
          wiredSettingsData.someRequiredField !== undefined;
        console.log("Step 2 valid:", isValidStep2);
        return isValidStep2;
      case 3:
        const isValidStep3 =
          wirelessSettingsData.ssid && wirelessSettingsData.ssid.trim() !== "";
        console.log("Step 3 valid:", isValidStep3);
        
        return isValidStep3;
      case 4:
        const isValidStep4 =
          ipConfigurationData.ipAddress && ipConfigurationData.subnetMask;
        console.log("Step 4 valid:", isValidStep4);
        console.log("wiredSettingsData :"+wiredSettingsData);
        return isValidStep4;
      default:
        return false;
    }
  };

  const handleNext = async () => {
    const isValid = await validateStep(currentStep);
    if (isValid) {
      setCurrentStep((prev) => prev + 1);
    }
    if (currentStep === 2) {
      setCurrentStep(currentStep + 1);
      return;
    }
    if (currentStep === 4) {
      await handleSubmit();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleSaveOptimization = async (type, value) => {
    try {
      const payload = {
        vlanId: vlanId,
        vlanName: vlanName,
        voiceOptimization: voiceOptimization,
        videoOptimization: videoOptimization,
      };

      const api = new allApi();
      const response = await api.postRequest(
        "api/vlan/voiceVideoOptimization",
        "",
        payload
      );

      if (response) {
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleSubmit = async () => {
    debugger;
    try {
      setLoading(true);
      const api = new allApi();
      // Step 1: Call the `api/vlan/add/vlan` endpoint
      const vlanPayload = {
        vlan: {
          igmpSnooping: "1",
          name: configNetData.vlanName,
          overrideTrafficPriority: "0",
          qosConfig: configNetData.networkType,
          trafficClass: "0",
          vlanId: configNetData.vlanId,
          vlanNwDesc: configNetData.description,
          vlanNwName: configNetData.networkName,
          vlanType: "6",
          voipOptimization: "0",
        },
      };

      const vlanResponse = await api.postRequest(
        "api/vlan/add/vlan",
        "",
        vlanPayload
      );

      if (!vlanResponse?.success) {
        throw new Error(
          `VLAN creation failed: ${
            vlanResponse?.errorMessage || "Unknown error"
          }`
        );
      }

      const vlanId = vlanPayload.vlan.vlanId;

      // Step 2: Call `api/vlan/add/accessPort` using the VLAN ID
      const accessPortPayload = {
        vlanId,
        vlanInfo: wiredSettingsData
      };
      console.log("Access Port Payload:", accessPortPayload);

      const accessPortResponse = await api.postRequest(
        "api/vlan/add/accessPort",
        "",
        accessPortPayload
      );

      if (accessPortResponse?.body?.failure?.length > 0) {
        console.warn(
          "Access Port API encountered failures:",
          accessPortResponse.body.failure
        );
        throw new Error("Access Port API partially failed. Check logs for details.");
      }
  
      if (!accessPortResponse?.body?.success?.length) {
        throw new Error("Access Port API did not succeed for any devices.");
      }

      // Step 2: second API Call the optimization API
      const optimizationPayload = {
        vlanId,
        vlanName: configNetData.vlanName,
        voiceOptimization,
        videoOptimization,
      };
      const optimizationResponse = await api.postRequest(
        "api/vlan/voiceVideoOptimization",
        "",
        optimizationPayload
      );

      if (!optimizationResponse.success) {
        throw new Error(
          `Voice/Video Optimization API request failed: ${
            optimizationResponse.errorMessage ||
            (await optimizationResponse.text())
          }`
        );
      }
      console.log(
        "Voice/Video optimization applied successfully for VLAN ID:",
        vlanId
      );

      // Step 3: Call `api/wireless/configuration/addSsid`
      const ssidPayload = {
        ssid: formData.ssid,
        broadcastStatus: formData.broadcastStatus,
        band: formData.band.join(", "),
        vlanId, // Pass the same VLAN ID dynamically
        bandSteeringSt: formData.bandSteeringSt,
        fastRoamingSt: formData.fastRoamingSt,
        clientIsolation: formData.clientIsolation,
        allowLocalUIAccess: formData.allowLocalUIAccess,
        scheduleEnabled: formData.scheduleEnabled,
        kvrStatus: formData.kvrStatus,
        securityAuthentication: formData.securityAuthentication,
        password: formData.password,
        schedule:
          formData.scheduleEnabled === "1"
            ? {
                scheduleName: formData.selectedSchedule || "custom_schedule",
                allDay: formData.allDay || "0",
                scheduleSetting: createScheduleSetting(schedule),
              }
            : undefined,
      };

      const ssidResponse = await api.postRequest(
        "api/wireless/configuration/addSsid?networkId=6001",
        "",
        ssidPayload
      );

      if (!ssidResponse.success) {
        throw new Error(
          `Step 4 API request failed: ${
            ssidResponse.errorMessage || (await ssidResponse.text())
          }`
        );
      }
    } catch (error) {
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <ConfigNet
            data={configNetData}
            setData={setConfigNetData}
            setIsValid={setIsStep1Valid}
          />
        );
      case 2:
        return (
          <WiredSettings
            // data={wiredSettingsData}
            setData={setOnDataChange}
            serialNumber={formData.serialNumber}
            formData={formData}
            setFormData={setFormData}
            onDataChange={setWiredSettingsData}
            portDetails={basicSwitchInfo?.portMembers || []}
            voiceOptimization={voiceOptimization}
            setVoiceOptimization={setVoiceOptimization}
            videoOptimization={videoOptimization}
            setVideoOptimization={setVideoOptimization}
            handleSaveOptimization={handleSaveOptimization}
          />
        );
      case 3:
        return (
          <WirelessSettings
            data={wirelessSettingsData}
            setData={setWirelessSettingsData}
            ssidList={ssidList}
          />
        );
      case 4:
        return (
          <IpConfiguration
            data={ipConfigurationData}
            setData={setIpConfigurationData}
          />
        );
      default:
        return null;
    }
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleCancel = () => {
    // Handle cancel action
    alert("Cancelled");
  };

  return (
    <div className="configure-network">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Configure Network</h3>
              </div>
              <div className="inc-card-body">
                <div className="inc-report">
                  <div className="report-tab">
                    {[1, 2, 3, 4].map((step) => (
                      <div
                        className={`step ${
                          currentStep === step
                            ? "selected"
                            : currentStep > step
                            ? "completed"
                            : ""
                        }`}
                        key={step}
                        onClick={() => handleStepClick(step)}
                      >
                        <div
                          className="s-text"
                          style={{ cursor: "pointer", color: "purple" }}
                        >
                          <span>STEP {step}</span>
                          <strong>
                            {step === 1
                              ? "Configure Network"
                              : step === 2
                              ? "Wired Settings"
                              : step === 3
                              ? "Wireless Settings"
                              : "IP Configuration"}
                          </strong>
                        </div>
                        <div className="s-dot">
                          <i
                            className={`fa ${
                              currentStep >= step ? "fa-check" : "fa-circle"
                            }`}
                            aria-hidden="true"
                          ></i>
                        </div>
                        {step < 4 && (
                          <i className="line">
                            <span></span>
                          </i>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="report-section">
                    <div className="report-container">{renderStep()}</div>
                    <div className="report-button">
                      {currentStep > 1 && (
                        <button
                          type="button"
                          className="btn btn-outline"
                          onClick={handleBack}
                        >
                          Back
                        </button>
                      )}
                      {currentStep === 1 && (
                        <button
                          type="button"
                          className="btn btn-outline"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-fill"
                        onClick={handleNext}
                      >
                        {currentStep === 4 ? "Submit" : "Next"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfigureNetwork;
