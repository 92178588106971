import React from "react";

function resetCounters() {
  return (
    <div className="inc-card-body">
      <div className="under-development">Reset Counters</div>
    </div>
  );
}

export default resetCounters;