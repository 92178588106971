import React, { useState, useEffect } from "react";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import allApi from "../../../../api/allApi";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const WifiSchedule = ({ isVisible, onClose, onScheduleSave }) => {
  const [showScheduleForm, setShowScheduleForm] = useState(true);
  const [slotsBooked, setSlotsBooked] = useState(false);
  const [schedule, setSchedule] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });
  const [scheduleName, setScheduleName] = useState("");
  const [schedulesList, setSchedulesList] = useState([]);

  useEffect(() => {
    setShowScheduleForm(true);
  }, []);

  useEffect(() => {
    if (isVisible) {
      setShowScheduleForm(true);
      fetchSchedules();
    }
  }, [isVisible]);

  const closeSchedule = () => {
    setShowScheduleForm(false);
    setSlotsBooked(false);
    if (onClose) onClose();
  };

  const MAX_TIME_SLOTS = 3;
  const addTimeSlot = (day) => {
    const updatedSchedule = { ...schedule };

    // Check if the day has fewer than 3 time slots before adding a new one
    if (updatedSchedule[day].length < MAX_TIME_SLOTS) {
      updatedSchedule[day].push({ startTime: null, endTime: null });
      setSchedule(updatedSchedule);
    } else {
      console.log(`Maximum of ${MAX_TIME_SLOTS} time slots reached for ${day}`);
    }
  };
  // const addTimeSlot = (day) => {
  //   const updatedSchedule = { ...schedule };
  //   updatedSchedule[day].push({ startTime: null, endTime: null });
  //   setSchedule(updatedSchedule);
  // };

  const removeTimeSlot = (day, index) => {
    const updatedSchedule = { ...schedule };
    updatedSchedule[day].splice(index, 1);
    setSchedule(updatedSchedule);
  };

  const handleChange = (day, index, field, value) => {
    const updatedSchedule = { ...schedule };
    updatedSchedule[day][index][field] = value;
    setSchedule(updatedSchedule);
  };

  const saveSchedule = () => {
    setSlotsBooked(true);
    setShowScheduleForm(false);
    handleScheduleSlot(); 
  };

  const fetchSchedules = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/wireless/configuration/getAllSsidSchedule?networkId=6001`);
  
      if (response.status === "OK" && response.response) {
        // Sort schedules by createdAt in descending order
        const sortedSchedules = response.response.sort((a, b) => b.createdAt - a.createdAt);
        setSchedulesList(sortedSchedules);
      } else {
        console.error("Failed to fetch schedules:", response.message);
      }
    } catch (error) {
      console.error("Error fetching schedules:", error);
    }
  };

  const handleScheduleSlot = async () => {
    try {
        const api = new allApi();

        const scheduleSettings = {
            schDays: "1000000", 
            schOnTime0: schedule.Monday[0]?.startTime?.format("HHmm") || "0000",
            schOffTime0: schedule.Monday[0]?.endTime?.format("HHmm") || "0000",
            schOnTime1: schedule.Tuesday[0]?.startTime?.format("HHmm") || "0000",
            schOffTime1: schedule.Tuesday[0]?.endTime?.format("HHmm") || "0000",
            schOnTime2: schedule.Wednesday[0]?.startTime?.format("HHmm") || "0000",
            schOffTime2: schedule.Wednesday[0]?.endTime?.format("HHmm") || "0000",
            schOnTime3: schedule.Thursday[0]?.startTime?.format("HHmm") || "0000",
            schOffTime3: schedule.Thursday[0]?.endTime?.format("HHmm") || "0000",
            schOnTime4: schedule.Friday[0]?.startTime?.format("HHmm") || "0000",
            schOffTime4: schedule.Friday[0]?.endTime?.format("HHmm") || "0000",
            schOnTime5: schedule.Saturday[0]?.startTime?.format("HHmm") || "0000",
            schOffTime5: schedule.Saturday[0]?.endTime?.format("HHmm") || "0000",
            schOnTime6: schedule.Sunday[0]?.startTime?.format("HHmm") || "0000",
            schOffTime6: schedule.Sunday[0]?.endTime?.format("HHmm") || "0000",
        };

        const payload = {
            networkId: 6001,
            scheduleName: scheduleName || "sample schedule",
            scheduleSettings: scheduleSettings,
            scheduleStatus: 0,
            createdAt: Math.floor(Date.now() / 1000), 
        };
        const response = await api.postRequest(
            `api/wireless/configuration/ssidSchedule?networkId=6001`,"",
            payload
        );
        if (response && response.scheduleId) {
          fetchSchedules();
          if (onScheduleSave) {
            onScheduleSave(response.scheduleId);
          }
        }
    } catch (error) {
        console.error("Error scheduling the slots:", error);
    }
};

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        {showScheduleForm && !slotsBooked ? (
          <div className="schedule-height">
            <div className="schedule-week">
              <h4>Schedule Name</h4>
              <div className="row d-flex">
                <div className="col-lg-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Schedule Name"
                    value={scheduleName}
                    onChange={(e) => setScheduleName(e.target.value)}
                  />
                </div>
              </div>
              <div className="border-line"></div>
              <p>Location Timezone - America/New_York (ET)</p>
              <h4>Days</h4>
              <p>You can add a maximum of 3 time slots per day.</p>
              <div className="schedule-slot-scroller">
                {Object.keys(schedule).map((day) => (
                  <div className="schedule-days" key={day}>
                    <div className="sc-day-title">
                      <h4>{day}</h4>
                      <div>
                        <button
                          className="icon-btn"
                          onClick={() => addTimeSlot(day)}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                    <div className="schedule-time">
                      {schedule[day].map((slot, index) => (
                        <div className="schedule-time-box" key={index}>
                          <div className="start-time">
                            <h4>Start Time</h4>
                            <TimePicker
                              value={slot.startTime}
                              onChange={(value) =>
                                handleChange(day, index, "startTime", value)
                              }
                              renderInput={(params) => (
                                <TextField {...params} className="time-picker-input" />
                              )}
                              components={{
                                OpenPickerIcon: () => (
                                  <AccessTimeIcon fontSize="small" />
                                ),
                              }}
                            />
                          </div>
                          <div className="end-time">
                            <h4>End Time</h4>
                            <TimePicker
                              value={slot.endTime}
                              onChange={(value) =>
                                handleChange(day, index, "endTime", value)
                              }
                              renderInput={(params) => (
                                <TextField {...params} className="time-picker-input" />
                              )}
                              components={{
                                OpenPickerIcon: () => (
                                  <AccessTimeIcon fontSize="small" />
                                ),
                              }}
                            />
                          </div>
                          <button onClick={() => removeTimeSlot(day, index)} className="delete-slot">
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="wifi-footer">
              <button
                type="button"
                className="btn btn-outline"
                onClick={closeSchedule}
              >
                Cancel
              </button>
              <button
                type="button"
                className="text-btn primary-btn"
                onClick={saveSchedule}
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          <div className="schedule-wrapper">
            {schedulesList.length > 0 ? (
              schedulesList.map((scheduleItem) => (
                <div className="schedule-item" key={scheduleItem.scheduleId}>
                  <div className="schedule-detail">
                    <h5>{scheduleItem.scheduleName}</h5>
                    <p>Status: {scheduleItem.scheduleStatus === 1 ? "Active" : "Inactive"}</p>
                    <p>Created At: {new Date(scheduleItem.createdAt * 1000).toLocaleString()}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No schedules found.</p>
            )}
          </div>
        )}
      </div>
    </LocalizationProvider>
  );
};

export default WifiSchedule;
