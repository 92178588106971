import React, { useState, useEffect, useRef } from "react";
import locationMap from "../../../assets/images/map.png";
import ssidGraph from "../../../assets/images/ssid-graph.png";
import deviceImage from "../../../assets/images/device.png";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import switchIcon from "../../../assets/images/switch.svg";
import apIcon from "../../../assets/images/access-point.svg";
import "leaflet/dist/leaflet.css";
import Chart from "react-apexcharts";
import { useMap } from "react-leaflet";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  Legend,
  ResponsiveContainer,
  Cell,
  AreaChart,
  Area,
} from "recharts";
import allApi from "../../../api/allApi";
import Loader from "../common/Loader";
import StackedHorizontalChart from "../dashboard/StackedHorizontalChart";
import { useLocation } from "react-router-dom";
import moment from "moment";

function MapView({ center }) {
  const map = useMap();
  useEffect(() => {
    if (center) {
      map.setView(center, 12); // Adjust the zoom level as needed
    }
  }, [center, map]);

  return null;
}

function DeviceDashboard(networkId) {
  const location = useLocation();
  const { deviceId } = location.state || {};
  const [loading, setLoading] = useState(true);
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [usageDetails, setUsageDetails] = useState({});
  const [dataUsage, setDataUsage] = useState([]);
  const [apDataUsage, setApDataUsage] = useState([]);
  const [apInterval, setApInterval] = useState("7d");
  const [selectedInterval, setSelectedInterval] = useState("7d");
  const [upDownData, setUpDownData] = useState([]);
  const [colorRanges, setColorRanges] = useState([]);
  const initialDeviceData = location.state?.deviceInfo || {};
  const [deviceType, setDeviceType] = useState(
    initialDeviceData.deviceType || "N/A"
  );
  const [connectedClients, setConnectedClients] = useState([]);
  const [ConnectedClientCategories, setConnectedClientCategories] = useState(
    []
  );
  const [ConnectedClientSeries, setConnectedClientSeries] = useState([]);
  const [connectedClientsDuration, setConnectedClientsDuration] =
    useState("24h");
  const [nodes, setNodes] = useState([]);
  const [deviceLocation, setDeviceLocation] = useState([51.505, -0.09]);

  const getMarkerIcon = (deviceType) => {
    let iconUrl;

    if (deviceType === "AP") {
      iconUrl = apIcon;
    } else if (deviceType === "Switch") {
      iconUrl = switchIcon;
    } else {
      iconUrl = apIcon;
    }

    return L.icon({
      iconUrl: iconUrl,
      iconSize: [45, 45],
      iconAnchor: [12, 41],
    });
  };

  useEffect(() => {
    fetchDeviceDetails(deviceId);
    fetchCpuAndMemoryUsage(deviceId);
  }, [deviceId]);

  useEffect(() => {
    gethttpLocations(deviceId);
  }, [deviceId]);

  useEffect(() => {
    fetchDeviceUpDownData(deviceId);
  }, [deviceId]);

  useEffect(() => {
    if (deviceType === "AP") {
      DataUsageAP(deviceId, networkId);
      fetchConnectedClientsPerSSID(deviceId);
    } else if (deviceType === "SW" || deviceType === "Switch") {
      fetchConnectedClients(deviceId);
      handleDataUsage(deviceId);
    }
  }, [deviceType, deviceId, selectedInterval, apInterval, networkId]);

  const fetchDeviceDetails = async (deviceId) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/http/device/${deviceId}`);
      if (response) {
        setDeviceDetails(response);
        setDeviceType(response.deviceType);
      } else {
        console.error("No data returned from API");
      }
    } catch (error) {
      console.error("Error fetching device details:", error.message || error);
    } finally {
      setLoading(false);
    }
  };

  const gethttpLocations = async (deviceId) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/http/${deviceId}/location`);

      if (response && response.latitude && response.longitude) {
        const location = {
          id: response.deviceId,
          position: [
            parseFloat(response.latitude),
            parseFloat(response.longitude),
          ],
          icon: getMarkerIcon(),
        };
        setNodes([location]);
        setDeviceLocation([
          parseFloat(response.latitude),
          parseFloat(response.longitude),
        ]);
      } else {
        console.error("Unexpected API response structure:", response);
      }
    } catch (error) {
      console.error("Failed to fetch locations:", error);
    }
  };

  const fetchCpuAndMemoryUsage = async (deviceId) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wired/dashboard/memoryCpuUtilization/${deviceId}`
      );
      if (response) {
        setUsageDetails({
          cpuUsage: response.cpuUsage,
          memoryUsage: response.memoryUsage,
        });
      } else {
        console.error("Failed to fetch device details.");
      }
    } catch (error) {
      console.error("Error fetching CPU and memory usage:", error);
    } finally {
      setLoading(false);
    }
  };

  const parseDate = (dateString, interval) => {
    if (interval === "6h" || interval === "12h" || interval === "24h") {
      return moment(dateString, "DD-MM-YYYY HH:mm").format("MM/DD/YYYY HH:mm");
    } else {
      return moment(dateString, "DD-MM-YYYY").format("MM/DD/YYYY");
    }
  };

  const formatXAxisLabel = (date, interval) => {
    if (interval === "6h" || interval === "12h" || interval === "24h") {
      return moment(date).format("HH:mm"); // Show time for shorter intervals
    } else {
      return moment(date).format("DD/MM/YYYY"); // Show date for 7d and 30d
    }
  };

  const handleDataUsage = async (deviceId) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/wired/dashboard/dataUsage/${deviceId}/${selectedInterval}`
      );
      if (response) {
        const formattedData = response.map((entry) => ({
          date: parseDate(entry.created_at, selectedInterval), // Pass interval to parseDate
          totalTraffic: entry.total_traffic / 1000000, // Convert to MB
        }));
        setDataUsage(formattedData);
      }
    } catch (error) {
      console.error("Error fetching data usage:", error);
    } finally {
      setLoading(false);
    }
  };

  const DataUsageAP = async (deviceId, networkId) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/clientDataConsumption?duration=${apInterval}&networkId=6001&deviceId=${deviceId}`
      );

      if (response) {
        const formattedData = response.response.map((entry) => {
          return {
            serialNumber: entry.serialNumber, // Store the serialNumber
            rxData: entry.data.map((consumption) => ({
              time: consumption.interval, // Time data
              value: consumption.rx, // rxBytes for the line
            })),
            txData: entry.data.map((consumption) => ({
              time: consumption.interval, // Time data
              value: consumption.tx, // txBytes for the line
            })),
          };
        });
        setApDataUsage(formattedData); // Set formatted data in state
      }
    } catch (error) {
      console.error("Error fetching AP data usage:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDeviceUpDownData = async (deviceId) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/http/health/${deviceId}`);
      if (response) {
        const formattedData = response.map((entry) => ({
          time: moment(entry.updatedAt).format("HH:mm"),
          status: entry.devHealth === 1 ? "Online" : "Offline",
        }));
        setUpDownData(formattedData);
      }
    } catch (error) {
      console.error("Error fetching device up/down data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchConnectedClients = async () => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/client/switchConnectedClients?serialNumber=3R81945SF0007`
      );
      if (response.response_data && response.response_data.length > 0) {
        // Map the relevant data from the response
        const formattedClients = response.response_data.map((client) => ({
          portId: client.portId,
          macAddress: client.portMacAddress,
          trafficRx: client.trafficRx,
          trafficTx: client.trafficTx,
          speed: client.speed,
        }));
        setConnectedClients(formattedClients);
      } else {
        setConnectedClients([]);
      }
    } catch (error) {
      console.error("Error fetching connected clients:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchConnectedClientsPerSSID = async () => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/connectedClientsPerSsid?deviceId=${deviceId}&networkId=6001`
      );
      if (response.response && response.response.length > 0) {
        const data = response.response;
        const seriesData = data.map((item) => ({
          name: item.ssidName,
          data: item.clients.map((client) => ({
            x: new Date(client.timestamp * 1000).toLocaleString("en-IN", {
              timeZone: "Asia/Kolkata",
              hour12: false,
              year: "numeric",
              month: "short",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }), // Convert timestamp to readable date in IST (24-hour format)
            y: client.totalClient, // Client count per SSID
          })),
        }));

        const timeStamps = [
          ...new Set(
            seriesData.flatMap((series) => series.data.map((point) => point.x))
          ),
        ];
        setConnectedClientCategories(timeStamps); // X-axis categories
        setConnectedClientSeries(seriesData); // Data for each SSID

        console.log("Chart data:", seriesData); // For debugging
      } else {
        console.error("Failed to fetch data:", response.message || "No data");
      }
    } catch (error) {
      console.error("Error fetching connected client data:", error);
    } finally {
      setLoading(false);
    }
  };

  const generateColorRanges = (data) => {
    let colorRanges = [];
    let lastState = data[0].status;
    let from = data[0].updatedAt;

    data.forEach((entry, index) => {
      if (entry.status !== lastState || index === data.length - 1) {
        colorRanges.push({
          from: from,
          to: entry.updatedAt,
          color: lastState === "online" ? "#00D76F" : "#fc3858",
        });
        from = entry.updatedAt;
        lastState = entry.status;
      }
    });

    setColorRanges(colorRanges);
  };

  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
    handleDataUsage(deviceId); // Fetch the data again with the new interval
  };

  const handleAPIntervalChange = (interval) => {
    setApInterval(interval);
  };

  const connectedClientsOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: (value) => {
          const date = new Date(value);
          const shortDateOptions = {
            day: "2-digit",
            month: "short",
            timeZone: "Asia/Kolkata",
          };
          const timeOptions = {
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "Asia/Kolkata",
            hour12: false,
          };

          // Format based on duration
          return connectedClientsDuration === "7d" ||
            connectedClientsDuration === "30d"
            ? date.toLocaleString("en-IN", shortDateOptions)
            : date.toLocaleString("en-IN", timeOptions);
        },
        style: {
          colors: "#9aa0ac",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: true,
        color: "#d6d6d6",
      },
      axisTicks: {
        show: true,
        color: "#d6d6d6",
      },
      tooltip: {
        enabled: true,
        formatter: (value) => {
          const date = new Date(value);
          const hours = String(date.getHours()).padStart(2, "0");
          const minutes = String(date.getMinutes()).padStart(2, "0");
          return `${hours}:${minutes}`;
        },
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: (val) => (val !== undefined ? val.toFixed(0) : "N/A"),
      },
      title: {
        text: "Client Count",
      },
    },
    stroke: {
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: ConnectedClientSeries.map((_, index) =>
      index === 0 ? "#fc7594" : "#00aaff"
    ),
    zoom: {
      enabled: false,
    },
  };

  return (
    <div>
      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="dashboard-device-info">
                {deviceDetails && (
                  <div className="dashboard-device-icon">
                    <img src={deviceImage} alt="" />
                    <strong>{deviceDetails?.deviceName || "N/A"}</strong>
                  </div>
                )}

                <div className="dashboard-device-detail">
                  <div className="detail-list">
                    <div>
                      <strong>{deviceDetails?.serialNumber || "N/A"}</strong>
                      <span>Serial Number</span>
                    </div>
                    <div>
                      <strong>{deviceDetails?.ipAddress || "N/A"}</strong>
                      <span>IP Address</span>
                    </div>
                    <div>
                      <strong>{deviceDetails?.macAddress || "N/A"}</strong>
                      <span>Mac Address</span>
                    </div>
                    <div>
                      <strong>{deviceDetails?.firmwareVersion || "N/A"}</strong>
                      <span>Firmware version</span>
                    </div>
                  </div>
                  <div className="detail-list">
                    <div>
                      <strong>{usageDetails?.cpuUsage || "N/A"}</strong>{" "}
                      <span>CPU Utilization</span>
                    </div>
                    <div>
                      <strong>{usageDetails?.memoryUsage || "N/A"}</strong>{" "}
                      <span>Memory Utilization</span>
                    </div>
                    <div>
                      <strong>{deviceDetails && deviceDetails.health}</strong>
                      <span>Device Health</span>
                    </div>
                  </div>
                  <div className="mt-3 updown-chart">
                    <strong>Up/Down Time</strong>
                    <ResponsiveContainer width="100%" height={100}>
                      <AreaChart
                        data={upDownData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                      >
                        <defs>
                          <linearGradient
                            id="colorStatus"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="100%"
                              stopColor="#00D76F"
                              stopOpacity={1}
                            />
                            <stop
                              offset="0%"
                              stopColor="#fc3858"
                              stopOpacity={1}
                            />
                          </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="time"
                          label={{
                            value: "Time",
                            position: "insideBottomRight",
                            offset: 0,
                          }}
                        />
                        <YAxis
                          type="number"
                          domain={[0, 1]} // Limits the y-axis between 0 (Offline) and 1 (Online)
                          label={{
                            value: "Health Status",
                            angle: -90,
                            position: "insideLeft",
                          }}
                        />
                        <Tooltip />
                        <Legend
                          payload={[
                            {
                              value: "Online",
                              type: "square",
                              color: "#00D76F",
                            },
                            {
                              value: "Offline",
                              type: "square",
                              color: "#fc3858",
                            },
                          ]}
                        />
                        <Area
                          type="monotone"
                          dataKey="healthStatus"
                          stroke="#8884d8"
                          fill="url(#colorStatus)"
                          fillOpacity={1}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>

                  {/* <div className="graph">
                    {upDownData.length === 0 ? (
                      <div>No data to display</div>
                    ) : (
                      <div>
                        {loading ? (
                          <Loader />
                        ) : (
                          <StackedHorizontalChart
                            height={80}
                            data={upDownData}
                            colorRanges={colorRanges}
                          />
                        )}
                      </div>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Data Usage for Switch*/}
      {(deviceType === "SW" || deviceType === "Switch") && (
        <div className="row mb-4">
          <div className="col-lg-6">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Data Usage</h3>
                <span
                  className="dropdown"
                  id="trendDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedInterval}{" "}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="trendDropdown"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleIntervalChange("6h")}
                      >
                        6 Hours
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleIntervalChange("12h")}
                      >
                        12 Hours
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleIntervalChange("24h")}
                      >
                        24 Hours
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleIntervalChange("7d")}
                      >
                        7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleIntervalChange("30d")}
                      >
                        30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="inc-card-body">
                {loading ? (
                  <Loader />
                ) : dataUsage.length === 0 ? (
                  <div className="no-data-box">No data to display</div>
                ) : (
                  <div>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        data={dataUsage}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date"
                          tickFormatter={(date) =>
                            formatXAxisLabel(date, selectedInterval)
                          } // Format based on interval
                          tick={{ fill: "#9aa0ac", fontSize: "12px" }} // Custom color and font size for X-axis
                        />
                        <YAxis
                          tickFormatter={(value) => `${value} MB`}
                          tick={{ fill: "#9aa0ac", fontSize: "12px" }} // Custom color and font size for Y-axis
                        />
                        <Tooltip formatter={(value) => [`${value} MB`]} />
                        <Legend
                          formatter={() =>
                            `${deviceDetails?.serialNumber || "N/A"}`
                          }
                        />
                        <Line
                          type="monotone"
                          dataKey="totalTraffic"
                          stroke="#4791FF"
                          name="Total Traffic"
                          strokeWidth={2}
                          dot={false}
                        />
                      </LineChart>
                    </ResponsiveContainer>

                    {/* <div className="data-label">
                      <span className="download">
                        <i></i>Download
                      </span>
                      <span className="upload">
                        <i></i>Upload
                      </span>
                    </div> */}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Connected Clients</h3>
              </div>
              <div className="inc-card-body">
                {loading ? (
                  <Loader />
                ) : (
                  <div className="inc-card-table">
                    <table className="full-table">
                      <thead>
                        <tr>
                          <th>Port</th>
                          <th>MAC Address</th>
                          <th>Traffic RX</th>
                          <th>Traffic TX</th>
                          <th>Speed (Mbps)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {connectedClients.length > 0 ? (
                          connectedClients.map((client) => (
                            <tr key={client.portId}>
                              <td>{client.portId}</td>
                              <td>{client.macAddress}</td>
                              <td>{client.trafficRx}</td>
                              <td>{client.trafficTx}</td>
                              <td>{client.speed}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5">No connected clients found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Data Usage for AP*/}
      {deviceType === "AP" && (
        <div className="row mb-4">
          <div className="col-lg-7">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Client Data Usage</h3>
                <span
                  className="dropdown"
                  id="trendDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {apInterval}{" "}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="trendDropdown"
                  >
                    {["6h", "12h", "24h", "7d", "30d"].map((interval) => (
                      <li key={interval}>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => handleAPIntervalChange(interval)}
                        >
                          {interval}
                        </a>
                      </li>
                    ))}
                  </ul>
                </span>
              </div>
              <div className="inc-card-body">
                {loading ? (
                  <Loader />
                ) : apDataUsage.length === 0 ? (
                  <div className="no-data-box">No data to display</div>
                ) : (
                  <div>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="time" />
                        <YAxis tickFormatter={(value) => `${value}`} />
                        <Tooltip
                          formatter={(value, name, props) => [`${value}`, name]}
                        />
                        <Legend />

                        {/* Loop over each device to create a line for rxBytes and txBytes */}
                        {apDataUsage.map((device) => (
                          <React.Fragment key={device.serialNumber}>
                            <Line
                              type="monotone"
                              dataKey="value"
                              data={device.rxData} // Data for rxBytes
                              stroke="#4791FF"
                              name={`Download (${device.serialNumber})`} // Use serialNumber in the legend
                              strokeWidth={1}
                              dot={false}
                            />
                            <Line
                              type="monotone"
                              dataKey="value"
                              data={device.txData} // Data for txBytes
                              stroke="#FF9050"
                              name={`Upload (${device.serialNumber})`} // Use serialNumber in the legend
                              strokeWidth={1}
                              dot={false}
                            />
                          </React.Fragment>
                        ))}
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-5">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Connected Client Per SSID</h3>
              </div>
              <div className="inc-card-body">
                {loading ? (
                  <Loader />
                ) : (
                  <div>
                    {ConnectedClientSeries.length === 0 ? (
                      <div className="no-data-box">
                        <div className="no-data-text">No Data Available</div>
                      </div>
                    ) : (
                      <Chart
                        options={connectedClientsOptions}
                        series={ConnectedClientSeries}
                        type="line"
                        height={170}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Device Location</h3>
            </div>
            <div className="inc-card-body">
              <div>
                <MapContainer
                  center={deviceLocation}
                  zoom={12}
                  style={{ height: "200px", width: "100%" }}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  <MapView center={deviceLocation} />{" "}
                  {/* Automatically updates the center */}
                  {nodes.map((node) => (
                    <Marker
                      key={node.id}
                      position={node.position}
                      icon={node.icon}
                    />
                  ))}
                </MapContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceDashboard;
