import React from 'react';
import { Navigate } from 'react-router-dom';
import Sidebar from "./Sidebar"

const PrivateRoute = ({ children }) => {
  const isAuthenticated = !!sessionStorage.getItem('sessionToken');
  return isAuthenticated ? <><Sidebar /> {children}</> : <Navigate to="/" />;
};

export default PrivateRoute;
