import React, { useState, useEffect } from "react";

function portConfiguration() {
  return (
    <div className="w-scroll">

      <div className="tab-container-title pt-0">
        <h4>Ports</h4>
      </div>
      <div>
        <div className="mb-4 router-port">
          <div className="mxw-1260">
            <div className="polygon"></div>
            <div className="port-box">
              <ul className="port54">
                <li>1</li>
                <li className="assign">2</li>
                <li>3</li>
                <li>4</li>
                <li className="assign">5</li>
                <li>6</li>
                <li>7</li>
                <li>8</li>
                <li>9</li>
                <li className="assign">10</li>

                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li className="assign">4</li>
                <li>5</li>
                <li>6</li>
                <li>7</li>
                <li>8</li>
                <li>9</li>
                <li>10</li>

                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
                <li>6</li>
                <li>7</li>
                <li>8</li>
                <li>9</li>
                <li>10</li>

                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
                <li>6</li>
                <li>7</li>
                <li>8</li>
                <li>9</li>
                <li>10</li>

                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
                <li>6</li>
                <li>7</li>
                <li>8</li>
                <li>9</li>
                <li>10</li>

                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="port-status">
          <div><i className="fa fa-circle colorBlack" aria-hidden="true"></i>Available</div>
          <div><i className="fa fa-circle colorBlue" aria-hidden="true"></i>Connected</div>
          <div><i className="fa fa-circle colorGreen" aria-hidden="true"></i>Connected and Powered</div>
          <div><i className="fa fa-circle colorLightGray" aria-hidden="true"></i>Disabled</div>
          <div><i className="fa fa-circle colorRed" aria-hidden="true"></i>DisConnected</div>
        </div>
      </div>

      <div className="tab-container-title top-border mt-4">
        <h4>Device Details</h4>
      </div>
      <div className="device-port-detail mb-4">
        <div>
          <strong>5LG395E0062A</strong>
          <span>Name</span>
        </div>
        <div>
          <strong>5JR18A5X002B3</strong>
          <span>Serial Number</span>
        </div>
        <div>
          <strong>WAC540</strong>
          <span>Model</span>
        </div>
        <div>
          <strong>3c:37:86:16:9e:4c</strong>
          <span>MAC Address</span>
        </div>
        <div>
          <strong>6 Days 1 Hrs 01 Mins 39 Secs</strong>
          <span>Uptime</span>
        </div>
        <div>
          <strong>Dual WAN</strong>
          <span>WAN Mode</span>
        </div>
        <div>
          <strong>192.168.11.1</strong>
          <span>LAN IP Address</span>
        </div>
        <div>
          <strong>45 °C</strong>
          <span>Temperature</span>
        </div>
        <div>
          <strong>14.65%</strong>
          <span>CPU Usage</span>
        </div>
        <div>
          <strong>1.0.02.31</strong>
          <span>Firmware Version</span>
        </div>
        <div>
          <strong>68% (90 GB)</strong>
          <span>Used Memory</span>
        </div>
        <div>
          <strong>32% (38 GB)</strong>
          <span>Free Memory</span>
        </div>
      </div>
      <button type="button" className="btn btn-fill">New Firmware Available</button>
    </div>
  );
}

export default portConfiguration;