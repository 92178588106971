import React, { useState, useEffect } from "react";

function ConfigNet({ data, setData, setIsValid, apiCall1 }) {
  const [networkName, setNetworkName] = useState(data.networkName || "");
  const [description, setDescription] = useState(data.description || "");
  const [networkType, setNetworkType] = useState(data.networkType || "");
  const [vlanName, setVlanName] = useState(data.vlanName || "");
  const [vlanId, setVlanId] = useState(data.vlanId || "");

  const [networkNameError, setNetworkNameError] = useState("");
  const [vlanNameError, setVlanNameError] = useState("");
  const [vlanIdError, setVlanIdError] = useState("");

  const validateNetworkName = (value) => {
    const regex = /^[a-zA-Z0-9]{1,255}$/; // Allows alphanumeric characters, 1-255 characters long
    if (!regex.test(value)) {
      setNetworkNameError("Network Name must be 1-255 alphanumeric characters.");
    } else {
      setNetworkNameError("");
    }
  };

  const validateVlanName = (value) => {
    if (value.length === 0 || value.length > 32) {
      setVlanNameError("VLAN Name must be between 1 and 32 characters.");
    } else {
      setVlanNameError("");
    }
  };

  const validateVlanId = (value) => {
    const vlanIdValue = parseInt(value, 10);
    if (isNaN(vlanIdValue) || vlanIdValue < 1 || vlanIdValue > 4093) {
      setVlanIdError("VLAN ID must be a number between 1 and 4093.");
    } else {
      setVlanIdError("");
    }
  };

  const handleChange = () => {
    setData({
      networkName,
      description,
      networkType,
      vlanName,
      vlanId,
    });
  };

  const isFormValid = () => {
    return (
      networkName && !networkNameError &&
      vlanName && !vlanNameError &&
      vlanId && !vlanIdError &&
      description && networkType
    );
  };

  useEffect(() => {
    setIsValid(isFormValid());
  }, [networkName, vlanName, vlanId, description, networkType]);

  const handleSubmit = async () => {
    if (isFormValid()) {
      try {
        await apiCall1({
          networkName,
          description,
          networkType,
          vlanName,
          vlanId,
        });
        alert("Network configuration submitted successfully.");
      } catch (error) {
        console.error("Submission failed", error);
        alert("An error occurred while submitting the form.");
      }
    }
  };

  return (
    <div>
      <h4>Configure Network</h4>
      <p>Please provide basic network details.</p>
      <div className="row mb-3">
        <div className="col-lg-4">
          <label className="form-label">VLAN Name <span>(1-32 characters)</span></label>
          <input
            type="text"
            className={`form-control ${vlanNameError ? 'is-invalid' : ''}`}
            value={vlanName}
            onChange={(e) => {
              const value = e.target.value;
              setVlanName(value);
              validateVlanName(value);
              handleChange();
            }}
          />
          {vlanNameError && <p style={{ color: "red" }}>{vlanNameError}</p>}
        </div>
        <div className="col-lg-4">
          <label className="form-label">VLAN ID <span>(1-4093, unique number)</span></label>
          <input
            type="text"
            className={`form-control ${vlanIdError ? 'is-invalid' : ''}`}
            value={vlanId}
            onChange={(e) => {
              const value = e.target.value;
              setVlanId(value);
              validateVlanId(value);
              handleChange();
            }}
          />
          {vlanIdError && <p style={{ color: "red" }}>{vlanIdError}</p>}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-lg-4">
          <label className="form-label">Network Name <span>(1-255 characters)</span></label>
          <input
            type="text"
            className={`form-control ${networkNameError ? 'is-invalid' : ''}`}
            value={networkName}
            onChange={(e) => {
              const value = e.target.value;
              setNetworkName(value);
              validateNetworkName(value);
              handleChange();
            }}
          />
          {networkNameError && <p style={{ color: "red" }}>{networkNameError}</p>}
        </div>
        <div className="col-lg-4">
          <label className="form-label">Description</label>
          <input
            type="text"
            className="form-control"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              handleChange();
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
      
        <div className="col-lg-4">
          <label className="form-label">Network Type</label>
          <select
            className="form-select"
            value={networkType}
            onChange={(e) => {
              setNetworkType(e.target.value);
              handleChange();
            }}
          >
            <option value="">Select Network Type</option>
            <option value="dataNetwork">Data Network</option>
            <option value="voiceVlan">Voice VLAN</option>
            <option value="videoVlan">Video VLAN</option>
          </select>
        </div>
      </div>
    
    </div>
  );
}

export default ConfigNet;
