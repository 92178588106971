import React, { useState } from "react";
import allApi from "../../../api/allApi";
import inclogo from "../../../assets/images/inc-name-logo.png";
import { useLocation ,useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import eyeIcon from "../../../assets/images/eye-open.svg";
import eyeCloseIcon from "../../../assets/images/eye-close.svg";


const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null); 
  const [resp, setResp] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match");
      return;
    }else {
      setMessage("");
    }

    try {
      const api = new allApi();
    
      const payload = {
        token,
        newPassword,
        confirmPassword,
      };


      const response = await api.postRequest("api/resetPassword", "",payload);

      if (response) {
        setResp(response);
        console.log('Password changed successfully:', response);
        setShowSuccessModal(true);
      
      } else {
        console.error("Failed to change password");
      }
    } catch (error) {

      console.error('Failed to change password:', error);
    }
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    navigate("/"); 
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
 


  return (
    <div>
      <section className="inc-login">
        <div className="login-form">
          <div className="login-form-container">
            <div className="login-text">
              <img src={inclogo} alt="inc logo" className="inc-logo" />
              <h1>Reset Password</h1>
              <p>
                {" "}
                Strong password includes numbers, letters, and punctuation
                marks.
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="inc-form line-textbox mb-4">
                <label>New Password:</label>
                <div className="input-wrapper">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="New Password"
                  style={{ fontWeight: "400" }}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                 <img
                          src={showPassword ? eyeIcon : eyeCloseIcon}
                          alt={showPassword ? "eye" : "closeeye"}
                          className="toggle-password"
                          onClick={togglePasswordVisibility}
                        />
                        </div>
              </div>
              <div className="inc-form line-textbox">
                <label>Confirm Password:</label>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  style={{ fontWeight: "400" }}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              {message && <div className="required-message" style={{ color: 'red' }}>{message}</div>}
              <button type="submit" className="btn btn-login">
                Submit
              </button>
            </form>
          </div>
        </div>
        <div className="login-banner">
          <div>
            <h3>About INC</h3>
            <p>Highly scalable, elastic, smart cloud platform for networking devices for data aggregation,
              analytics, visualization, and management.</p>

            <p>By building interconnectivity standards, INC bridges the needs of brands, OEMs and developers across
              industries to fast track their cloud development using reusable components like SDKs, streamers,
              protocol connectors and dashboard widget.</p>
          </div>
        </div>
      </section>
      <Modal
        show={showSuccessModal}
        onHide={handleSuccessModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>
          Password has been successfully changed.
        </p>
        <Button
          onClick={() => {
            handleSuccessModalClose();
          }}
        >
          OK
        </Button>
      </Modal>
    </div>
  );
};

export default ResetPassword;
