import React, { useState, useEffect } from "react";
import eyeIcon from "../../../assets/images/eye-open.svg";
import eyeCloseIcon from "../../../assets/images/eye-close.svg";
import facebookIcon from "../../../assets/images/facebook.png";
import googleIcon from "../../../assets/images/google.png";
import inclogo from "../../../assets/images/inc-name-logo.png";
import allApi from "../../../api/allApi";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import errorIcon from "../../../assets/images/error-2.png";
import { Modal, Button } from "react-bootstrap";
import Chat from "../common/chat";// Import the Chat component

const LoginForm = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");

  const api = new allApi();

  const login = async (e) => {
    e.preventDefault();
    setErrMessage("");
    setErrorMessage("");
    setError(null);
    const loginData = { username, password };
    try {
      const response = await axios.post("api/auth/login", loginData);
      const { status, data } = response;
      if (status === 200) {
        const { firstname, lastname, token } = data.data;
        sessionStorage.setItem("sessionToken", token);
        sessionStorage.setItem("username", `${firstname} ${lastname}`);
        setSuccessMessage("Login successful!");
        allApi.token = token;
        navigate("/overview");
      } else {
        setError("Invalid username or password.");
        setSuccessMessage("");
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Login Error:", error);
      setError("An error occurred. Please try again.");
      setSuccessMessage("");
      setShowErrorModal(true);
    }
  };

  const refreshToken = async () => {
    try {
      const response = await api.postRequest("api/auth/refresh-token", "");
      const { token, expiresIn } = response.data.data;
      const expirationTime = Date.now() + expiresIn * 1000;
      sessionStorage.setItem("sessionToken", token);
      sessionStorage.setItem("tokenExpiration", expirationTime);
      allApi.token = token;
    } catch (error) {
      console.error("Token Refresh Error:", error);
    }
  };

  const checkTokenExpiration = () => {
    const tokenExpiration = sessionStorage.getItem("tokenExpiration");
    if (tokenExpiration && Date.now() > tokenExpiration) {
      refreshToken();
    }
  };

  useEffect(() => {
    const interval = setInterval(checkTokenExpiration, 15 * 60 * 1000); // Check every 15 minutes
    return () => clearInterval(interval);
  }, []);

  const handleForgotPassword = async (e) => {
    navigate("/forgot-password");
  };

  const isLoginButtonDisabled = !(
    username &&
    password &&
    !errorMessage &&
    !errMessage
  );

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleUsername = (e) => {
    let value = e.target.value;
    setUsername(value);
    if (value && !validateEmail(value)) {
      setErrMessage("Invalid Email format");
    } else {
      setErrMessage("");
    }
  };

  const validateEmail = (username) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(username);
  };

  return (
    <div className="inc-login">
      <div className="login-form">
        <div className="login-form-container">
          <div className="login-text">
            <img src={inclogo} alt="inc logo" className="inc-logo" />
            <h1>Login</h1>
          </div>
          {successMessage && (
            <div className="success-message">
              <h6>{successMessage}</h6>
            </div>
          )}
          <div className="inc-form line-textbox mb-4">
            <label>Email</label>
            <input
              type="text"
              name="username"
              placeholder="Email"
              value={username}
              autoComplete="username"
              onChange={handleUsername}
              required
            />
            {errMessage && (
              <span className="required-message" style={{ color: "red" }}>
                {errMessage}
              </span>
            )}
          </div>
          <div className="inc-form line-textbox">
            <label>Password</label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              style={{ fontWeight: "400" }}
              value={password}
              onChange={handlePassword}
              required
            />
            <img
              src={showPassword ? eyeIcon : eyeCloseIcon}
              alt={showPassword ? "eye" : "closeeye"}
              className="show-password"
              onClick={togglePasswordVisibility}
            />
          </div>
          {errorMessage && (
            <span className="required-message" style={{ color: "red" }}>
              {errorMessage}
            </span>
          )}
          <div className="forgot-text">
            <a href="#" onClick={handleForgotPassword}>
              Forgot Password?
            </a>
          </div>
          <button
            type="submit"
            className="btn btn-login"
            disabled={isLoginButtonDisabled}
            onClick={login}
          >
            Login
          </button>
          {/* <div className="social-login">
            <div className="s-text">
              <span>Login with</span>
            </div>
            <div className="s-button">
              <button>
                <img src={facebookIcon} alt="Facebook" />
                Facebook
              </button>
              <button>
                <img src={googleIcon} alt="Google" />
                Google
              </button>
            </div>
          </div> */}
        </div>
      </div>
      <div className="login-banner">
        <div>
          <h3>About INC</h3>
          <p>
            Highly scalable, elastic, smart cloud platform for networking
            devices for data aggregation, analytics, visualization, and
            management.
          </p>
          <p>
            By building interconnectivity standards, INC bridges the needs of
            brands, OEMs, and developers across industries to fast track their
            cloud development using reusable components like SDKs, streamers,
            protocol connectors, and dashboard widgets.
          </p>
        </div>
      </div>
      <Modal
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert"
      >
        <div className="modal-body success-msg">
          <img src={errorIcon} alt="Error" />
          <h2>Error</h2>
          <p>Invalid username/password</p>
          <Button
            className="text-btn primary-btn"
            onClick={() => setShowErrorModal(false)}
          >
            OK
          </Button>
        </div>
      </Modal>
      {/* Add the Chat component */}
      {/* // <Chat /> */}
    </div>
  );
};

export default LoginForm;
