import React from "react";
import Chart from "react-apexcharts";
import "../../../assets/css/custom.css";

function StackedHorizontalChart({ height, data }) {
  const getColor = (health) => {
    return health === 1 ? "#00D76F" : "#00D76F"; 
  };

  const uniqueTimestamps = [...new Set(data.map(entry => entry.updatedAt))]; 

  // Prepare chart series data
  const chartSeries = [
    {
      name: "Status",
      data: uniqueTimestamps.map(timestamp => {
        const entries = data.filter(entry => entry.updatedAt === timestamp);
        const health = entries[0].devHealth; 
        return {
          x: timestamp,
          y: entries.length, 
          fillColor: getColor(health), 
        };
      }),
    },
  ];

  const chartOptions = {
    chart: {
      type: "bar",
      height: height,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "100%",
        dataLabels: {
          position: "center",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
   xaxis: {
      type: "datetime", // x-axis type changed to datetime for timestamp
      labels: {
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "dd MMM",
          hour: "HH:mm",
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      padding: {
        bottom: 5,
      },
    },
    fill: {
      opacity: 2,
    }
  };

  return (
    <div className="chart-container">
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={height}
      />
    </div>
  );
}

export default StackedHorizontalChart;
