import React, { useEffect, useState } from 'react';
import allApi from '../../../api/allApi';

const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const today = new Date();
  
  const isToday = date.toDateString() === today.toDateString();
  const isYesterday = date.toDateString() === new Date(today.setDate(today.getDate() - 1)).toDateString();
  
  if (isToday) return 'Today';
  if (isYesterday) return 'Yesterday';
  
  return date.toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  });
};

function Notification() {
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    fetchNotifications();
  }, []);

    const fetchNotifications = async () => {
      try {
        const api = new allApi();
        const response = await api.getRequest("api/notification/allNotifications");
        const allFetchedNotifications = response.map((notification) => ({
          id: notification.id || notification.notificationId,
          description: notification.description,
          createdAt: notification.createdAt,
          deviceName: notification.deviceName || "N/A",
          status: notification.status || 0,  
        }));
        
        const unreadNotifications = allFetchedNotifications.filter(notification => notification.status === 0);
        setNotifications(allFetchedNotifications);
        setNotificationCount(unreadNotifications.length);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

   

  const groupedNotifications = notifications.reduce((acc, notification) => {
    const dateKey = formatDate(notification.createdAt);
    if (!acc[dateKey]) acc[dateKey] = [];
    acc[dateKey].push(notification);
    return acc;
  }, {});

  const markAsRead = async (notificationIds) => {
    try {
      const api = new allApi();
      const payload = notificationIds;
      await api.postRequest("api/notification/markNotifications", "", payload);
  
      const updatedNotifications = notifications.map((n) =>
        notificationIds.includes(n.id) ? { ...n, status: 1 } : n
      );
      setNotifications(updatedNotifications);
  
      const unreadCount = updatedNotifications.filter((n) => n.status === 0).length;
      setNotificationCount(unreadCount);
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };
  
  const handleSingleRead = (notificationId) => {
    const notification = notifications.find((n) => n.id === notificationId);
    if (notification && notification.status === 0) {
      markAsRead([notificationId]);
    }
  };
  
  

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Notification</h3>
          </div>
          <div className="inc-card-body">
            <div className='notification-container w-scroll'>
              {Object.keys(groupedNotifications).length > 0 ? (
                Object.entries(groupedNotifications).map(([date, notifications]) => (
                  <div key={date} className='notification-group'>
                    <h4>{date} <span>{notifications.length}</span></h4>
                    {notifications.map(notification => (
                      <div key={notification.notificationId} 
                      className={`notification-list ${notification.status === 0 ? 'unread' : 'read'}`}
                      onClick={() => handleSingleRead(notification.id)}>
                        <div className='n-info'>
                        <h6>{notification.notificationName || notification.deviceName || "N/A"}<small>{formatTime(notification.createdAt)}</small></h6>
                        <p>{notification.description || "No discription found"}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <p>No notifications available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification;
