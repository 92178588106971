import React from "react";
import { useNavigate } from "react-router-dom";

function IpConfiguration() {
    const navigate = useNavigate();

    return (
        <div>
            <h4>IP Configuration</h4>
            <p>These are IP setting modes applicable to only switches under this location.</p>
            <div className="vlan-text">
                <p><strong>None:</strong> Non-routable IP settings can be configured to network interface of the switch(es), and the switch IP settings mode can be configured from switch dashboard <i className="fa fa-angle-right" aria-hidden="true"></i> IP Settings.<br />
                    <strong>Static:</strong> The routable static mode IP settings can be configured to VLAN interface of the switch(es).<br />
                    <strong>DHCP:</strong> The routable DHCP mode IP settings can be configured to VLAN interface of the switch(es).</p>
            </div>
            <div className="row mt-4">
                <div className="col-lg-2">
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="vlan" id="lan1" />
                        <label className="form-check-label" for="lan1">
                            DHCP
                        </label>
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="vlan" id="lan2" />
                        <label className="form-check-label" for="lan2">
                            Static
                        </label>
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="vlan" id="lan3" />
                        <label className="form-check-label" for="lan3">
                            None
                        </label>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default IpConfiguration;
