import React, { useState, useEffect } from "react";
import allApi from "../../../api/allApi";
import EdgeUserPopUp from "../common/EdgeUserPopup";
import closeIcon from "../../../assets/images/close.svg";
import errorIcon from "../../../assets/images/error-2.png";
import EditEdgePopup from "../common/EditEdgePopup";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Loader from "../common/Loader";
import { useLocation } from "react-router-dom";

function EdgeManagement() {
  const location = useLocation();
  const [gateways, setGateways] = useState([]);
  const [gatewaysPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showEdgeUserPopUp, setShowEdgeUserPopUp] = useState(false);
  const [error, setError] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState([]);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [gateway_id, setGateway_id] = useState("");

  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [filteredGateways, setFilteredGateways] = useState([]);
  const [individualCheckboxChecked, setIndividualCheckboxChecked] = useState(
    {}
  );
  const [showErrorPopup, setShowErrorPopup] = useState(false); // Error popup state

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [gatewayToDelete, setGatewayToDelete] = useState(null);
  const [statusFilter, setStatusFilter] = useState("");
  const [showEmailConfirmationModal, setShowEmailConfirmationModal] =
    useState(false);
  const [searchQueries, setSearchQueries] = useState({
    gateway_name: "",
    organisation_name: "",
    site_name: "",
    publisher_topic: "",
    subscriber_topic: "",
    username: "",
    agent_mail: "",
    status: "",
  });
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    gateway_name: "asc",
    organisation_name: "asc",
    site_name: "asc",
    publisher_topic: "asc",
    subscriber_topic: "asc",
    username: "asc",
    agent_mail: "asc",
    status: "asc",
  });

  const handleSuccess = () => {
    setShowSuccessMessage(true);
    setTimeout(() => setShowSuccessMessage(false), 3000);
  };

  useEffect(() => {
    getEdgeDevices();
  }, [currentPage]);

  useEffect(() => {
    if (location.state && location.state.statusResponse) {
      const statusList = location.state.statusResponse.map(item => item.status);
      setStatusFilter(statusList);
    } else {
      setStatusFilter(null);
    }
  }, [location.state]);

  useEffect(() => {
    if (statusFilter) {
      setFilteredGateways(
        gateways.filter((gateway) => statusFilter.includes(gateway.status))
      );
    } else {
      setFilteredGateways(gateways);
    }
  }, [gateways, statusFilter]);


  async function getEdgeDevices() {
    setLoading(true);
    setRefreshing(true);
    try {
      const api = new allApi();
      let response = await api.getRequest("api/gateway/all");
      if (response && Array.isArray(response)) {
        response.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setGateways(response);
      }
    } catch (error) {
      setError("Error fetching gateways");
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const handleAddButtonClick = () => {
    setShowEdgeUserPopUp(true);
  };

  const handleEdgeClose = () => {
    setShowEdgeUserPopUp(false);
  };

  const handleSaveEdge = async () => {
    try {
      setShowEdgeUserPopUp(true);
    } catch (error) {
      console.error("Error adding discovered device:", error);
      setError("Error adding discovered device");
    }
  };

  const handleDeleteGateway = async (gatewayIds = selectedRows) => {
    try {
      const api = new allApi();
      if (!Array.isArray(gatewayIds)) {
        gatewayIds = [gatewayIds];
      }
      const payload = { gateway_id: gatewayIds };

      // First API call (isConfirmed=false)
      const response = await api.deleteRequest(
        "api/gateway/delete?isConfirmed=false",
        payload
      );

      if (response.status === 409) {
        // If the gateway is linked to a device, show confirmation modal
        setGatewayToDelete(gatewayIds);
        setShowConfirmationModal(true);
      } else if (response.ok) {
        // Successful deletion
        getEdgeDevices();
        setSelectedRows([]);
      } else {
        setError("Failed to delete selected gateways.");
        setShowErrorPopup(true); // Show error modal
      }
    } catch (error) {
      const gateway = gateways.find((gw) => gw.gateway_id === gatewayIds[0]);

      if (gateway) {
        // If the gateway is found, use its name
        setError(
          `Edge Gateway with ID: ${gatewayIds[0]} and Name: ${gateway.gateway_name} is linked to device(s). Do you want to delete it?`
        );
      } else {
        // Fallback in case gateway is not found
        setError(
          `Edge Gateway with ID: ${gatewayIds[0]} is linked to device(s). Do you want to delete it?`
        );
      }

      setShowErrorPopup(true); // Show error modal
    } finally {
      setShowConfirmationModal(false);
      setShowErrorPopup(false);
    }
  };

  const handleConfirmedDelete = async () => {
    try {
      const api = new allApi();
      const payload = { gateway_id: [702] };

      // Second API call (isConfirmed=true)
      const response = await api.deleteRequest(
        "api/gateway/delete?isConfirmed=true",
        payload
      );

      if (response.ok) {
        // Successfully deleted
        getEdgeDevices();
        setShowConfirmationModal(false);
        setGatewayToDelete(null);
        setSelectedRows([]);
      } else {
        setError("Failed to delete selected gateway.");
      }
    } catch (error) {
      console.error("Error confirming deletion:", error);
      setError("Error deleting gateway");
    }
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const sendEmail = async (requestID) => {
    try {
      const api = new allApi();
      const payload = { request_id: requestID };
      const response = await api.postRequest(
        `api/gateway/config_file`,
        "",
        payload
      );

      if (response && response.status === 201) {
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const handleEditGateway = async (gatewayId) => {
    try {
      const gatewayData = await fetchGatewayById(gatewayId);
      setSelectedGateway(gatewayData);
      setShowEditPopup(true);
    } catch (error) {
      setError("Error fetching gateway data for editing");
    }
  };

  const handleSelectAllCheckbox = () => {
    //added for user accounts
    setSelectAllChecked((prev) => !prev);
    const newCheckboxState = {};
    filteredGateways.forEach((gateway) => {
      newCheckboxState[gateway.gateway_name] = !selectAllChecked;
    });
    setIndividualCheckboxChecked(newCheckboxState);
  };

  const handleIndividualCheckboxChange = (index) => {
    setIndividualCheckboxChecked((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const fetchGatewayById = async (gatewayId) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/gateway/${gatewayId}`);
      return response;
    } catch (error) {
      console.error("Error fetching gateway by ID:", error);
      throw new Error("Error fetching gateway by ID");
    }
  };

  const handleSearchChange = (e, field) => {
    const { value } = e.target;
    setSearchQueries((prevQueries) => ({
      ...prevQueries,
      [field]: value,
    }));
  };

  const handleSortChange = (field) => {
    const newOrder = sortOrder[field] === "asc" ? "desc" : "asc";
    const sortedGateways = [...filteredGateways].sort((a, b) => {
      const aValue = a[field] ? a[field].toString().toLowerCase() : "";
      const bValue = b[field] ? b[field].toString().toLowerCase() : "";

      if (newOrder === "asc") {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });

    setFilteredGateways(sortedGateways);
    setSortOrder((prevState) => ({
      ...prevState,
      [field]: newOrder, // Set the new sort order for the field
    }));
  };

  // const filteredGateways = gateways.filter((gateway) => {
  //   return Object.keys(searchQueries).every((key) => {
  //     const queryValue = searchQueries[key]?.toLowerCase();
  //     const gatewayValue = gateway[key]?.toString().toLowerCase();

  //     if (!queryValue) {
  //       return true;
  //     }
  //     if (key === 'status') {
  //       return gatewayValue === queryValue;
  //     }
  //     return gatewayValue.includes(queryValue);
  //   });
  // });

  const indexOfLastGateway = currentPage * gatewaysPerPage;
  const indexOfFirstGateway = indexOfLastGateway - gatewaysPerPage;
  const totalFilteredGateways = filteredGateways.length;
  const currentGateways = filteredGateways.slice(
    indexOfFirstGateway,
    indexOfLastGateway
  );

  const totalPages = Math.ceil(totalFilteredGateways / gatewaysPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const getPageNumbers = () => {
    const range = 1;
    const start = Math.max(1, currentPage - range);
    const end = Math.min(totalPages, currentPage + range);

    return [...Array(end - start + 1).keys()].map((i) => start + i);
  };

  return (
    <div>
      <div className="inc-card ch-100">
        <div className="inc-card-title">
          <h3>Edge</h3>
          <div className="inc-card-button">
            <button
              className="text-btn primary-btn"
              onClick={handleAddButtonClick}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
              Add
            </button>
            <button
              className="icon-btn"
              title="Refresh"
              onClick={() => getEdgeDevices()}
            >
              {refreshing ? (
                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-refresh" aria-hidden="true"></i>
              )}{" "}
            </button>
            <button
              className="icon-btn"
              title={selectedRows.length === 0 ? "" : "Delete Selected"}
              onClick={() => handleDeleteGateway()}
              disabled={selectedRows.length === 0}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div>
          <EdgeUserPopUp
            showSuccessMessage={showSuccessMessage}
            handleSuccess={handleSuccess}
          />
          {showSuccessMessage && (
            <div className="alert alert-success">
              <strong>Success!</strong> Edge User Created successfully.
            </div>
          )}
        </div>
        <div className="inc-card-body">
          <div className="inc-card-table">
            <table className="full-table m-head">
              <thead>
                <tr>
                  <th>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        checked={selectAllChecked}
                        onChange={() => handleSelectAllCheckbox()}
                      />
                      <span className="indicator"></span>
                    </label>
                  </th>
                  <th>Gateway Name</th>
                  <th>Organisation</th>
                  <th>Site Name</th>
                  <th>Publisher Topic</th>
                  <th>Subscriber Topic</th>
                  <th>Username</th>
                  <th>Agent Mail ID</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                <tr className="search-border">
                  <td></td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      value={searchQueries.gateway_name}
                      onChange={(e) => handleSearchChange(e, "gateway_name")}
                    />
                    <i
                      className={`fa ${sortOrder.gateway_name === "asc"
                          ? "fa fa-sort-amount-desc"
                          : "fa fa-sort-amount-asc"
                        }`}
                      onClick={() => handleSortChange("gateway_name")}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      value={searchQueries.organisation_name}
                      onChange={(e) =>
                        handleSearchChange(e, "organisation_name")
                      }
                    />
                    <i
                      className={`fa ${sortOrder.organisation_name === "asc"
                          ? "fa fa-sort-amount-desc"
                          : "fa fa-sort-amount-asc"
                        }`}
                      onClick={() => handleSortChange("organisation_name")}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      value={searchQueries.site_name}
                      onChange={(e) => handleSearchChange(e, "site_name")}
                    />
                    <i
                      className={`fa ${sortOrder.site_name === "asc"
                          ? "fa fa-sort-amount-desc"
                          : "fa fa-sort-amount-asc"
                        }`}
                      onClick={() => handleSortChange("site_name")}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      value={searchQueries.publisher_topic}
                      onChange={(e) => handleSearchChange(e, "publisher_topic")}
                    />
                    <i
                      className={`fa ${sortOrder.publisher_topic === "asc"
                          ? "fa fa-sort-amount-desc"
                          : "fa fa-sort-amount-asc"
                        }`}
                      onClick={() => handleSortChange("publisher_topic")}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      value={searchQueries.subscriber_topic}
                      onChange={(e) =>
                        handleSearchChange(e, "subscriber_topic")
                      }
                    />
                    <i
                      className={`fa ${sortOrder.subscriber_topic === "asc"
                          ? "fa fa-sort-amount-desc"
                          : "fa fa-sort-amount-asc"
                        }`}
                      onClick={() => handleSortChange("subscriber_topic")}
                      aria-hidden="true"
                    ></i>

                  </td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      value={searchQueries.username}
                      onChange={(e) => handleSearchChange(e, "username")}
                    />
                    <i
                      className={`fa ${sortOrder.username === "asc"
                          ? "fa fa-sort-amount-desc"
                          : "fa fa-sort-amount-asc"
                        }`}
                      onClick={() => handleSortChange("username")}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      value={searchQueries.agent_mail}
                      onChange={(e) => handleSearchChange(e, "agent_mail")}
                    />
                    <i
                      className={`fa ${sortOrder.agent_mail === "asc"
                          ? "fa fa-sort-amount-desc"
                          : "fa fa-sort-amount-asc"
                        }`}
                      onClick={() => handleSortChange("agent_mail")}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      value={searchQueries.status}
                      onChange={(e) => handleSearchChange(e, "status")}
                    />
                    <i
                      className={`fa ${sortOrder.status === "asc"
                          ? "fa fa-sort-amount-desc"
                          : "fa fa-sort-amount-asc"
                        }`}
                      onClick={() => handleSortChange("status")}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td colSpan="7"></td>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="12">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  currentGateways.map((gateway, index) => (
                    <tr key={index}>
                      <td>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            checked={
                              individualCheckboxChecked[gateway.gateway_name] ||
                              false
                            }
                            onChange={() =>
                              handleIndividualCheckboxChange(
                                gateway.gateway_name
                              )
                            }
                          />{" "}
                          <span className="indicator"></span>
                        </label>
                      </td>
                      <td>{gateway.gateway_name}</td>
                      <td>{gateway.organisation_name}</td>
                      <td>{gateway.site_name}</td>
                      <td>{gateway.publisher_topic}</td>
                      <td>{gateway.subscriber_topic}</td>
                      <td>{gateway.username}</td>
                      <td>{gateway.agent_mail}</td>
                      <td>
                        <span
                          className={
                            "d-status " +
                            (gateway?.status === "Active"
                              ? "online"
                              : "offline")
                          }
                        >
                          {gateway.status}
                        </span>{" "}
                      </td>
                      <td>
                        <div className="button-group">
                          <button
                            title="Mail"
                            data-bs-toggle="modal"
                            data-bs-target="#mailConfirmation"
                            onClick={() => setRequestId(gateway.request_id)}
                          >
                            <i
                              className="fa fa-envelope-o"
                              aria-hidden="true"
                            ></i>{" "}
                          </button>
                          <button
                            title="Edit"
                            onClick={() =>
                              handleEditGateway(gateway.request_id)
                            }
                          >
                            <i className="fa fa-pencil" aria-hidden="true"></i>
                          </button>
                          <button
                            className="icon-btn"
                            title="Delete"
                            data-bs-toggle="modal"
                            data-bs-target="#noDeviceDelete"
                            onClick={() => setRequestId(gateway.request_id)}
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="pagination-container">
          <div className="pagination-info">
            {totalFilteredGateways > 0 ? (
              <p>
                Showing {indexOfFirstGateway + 1}-
                {Math.min(currentPage * gatewaysPerPage, totalFilteredGateways)} of{" "}
                {totalFilteredGateways} records
              </p>
            ) : (
              <p>No records available</p>
            )}
          </div>
          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Previous
            </button>
            {getPageNumbers().map((page) => (
              <button
                key={page}
                onClick={() => handlePageClick(page)}
                className={currentPage === page ? "active" : ""}
              >
                {page}
              </button>
            ))}
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
            <button
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
            >
              Last Page
            </button>
          </div>
        </div>
      </div>
      <div
        className="modal fade inc-alert"
        id="mailConfirmation"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog email-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h3>Email Confirmation</h3>
              <p>
                Are you sure want to send an email? <br /> In the email you will
                receive the below content: <br />
                <div className="text-start mt-3">
                  1. EDGE application download link. <br />
                  2. Configuration file for setting up the service on HOST.
                  <br />
                  3. EDGE application installation and operating steps.
                </div>
              </p>
              <div className="confirm-btn">
                <button
                  type="button"
                  className="btn btn-outline"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-fill"
                  onClick={() => sendEmail(requestId)}
                  data-bs-dismiss="modal"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade`}
        id="noDeviceDelete"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <h3>Delete Confirmation</h3>
              <img src={errorIcon} alt="error" />
              <p>Are you sure you want to delete?</p>
              <button
                className="text-btn primary-btn"
                data-bs-dismiss="modal"
                onClick={() => handleDeleteGateway(requestId)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showErrorPopup}
        onHide={handleCloseErrorPopup}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="error-alert index-2000"
      >
        <div className="modal-body success-msg">
          <h4>Error Confirmation</h4>
          <img src={errorIcon} alt="error" />
          <p>
            {error
              ? error.split(".").map((line, index) => (
                <span key={index}>
                  {line.trim()}
                  {index < error.split(".").length - 1 && <br />}
                </span>
              ))
              : "An error occurred. Please try again later."}
          </p>
          <div className="confirm-btn">
            <Button
              type="button"
              className="btn btn-fill"
              onClick={() => handleConfirmedDelete(gateway_id)}
              data-bs-dismiss="modal"
            >
              Yes
            </Button>
            <Button
              type="button"
              className="btn btn-outline"
              onClick={handleCloseErrorPopup}
            >
              No
            </Button>
          </div>
        </div>
      </Modal>

      <EditEdgePopup
        showPopUp={showEditPopup}
        handleClose={() => setShowEditPopup(false)}
        headerMessage1="Edit Gateway"
        handleSuccess={handleSuccess}
        gateway={selectedGateway}
      />

      <EdgeUserPopUp
        showPopUp={showEdgeUserPopUp}
        handleClose={handleEdgeClose}
        headerMessage1="Create Edge Location"
        handleSaveEdge={handleSaveEdge}
        handleSuccess={handleSuccess}
        closeIcon={closeIcon}
      />
    </div>
  );
}

export default EdgeManagement;
