import React, { useState } from "react";
import ReportProfile from "./reportProfile";
import ReportFilter from "./reportFilter";
import ReportPickWidgets from "./reportPickWidgets";
import ReportRearrange from "./reportRearrange";
import ReportReview from "./reportReview";

function Reports() {
  const [currentStep, setCurrentStep] = useState(1);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <ReportProfile />;
      case 2:
        return <ReportPickWidgets />;
      case 3:
        return <ReportReview />;
      default:
        return null;
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleCancel = () => {
    setCurrentStep(1);
  };

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Reports</h3>
          </div>
          <div className="inc-card-body">
            <div className="inc-report">
              <div className="report-tab">
                {[1, 2, 3].map((step) => (
                  <div
                    className={`step ${
                      currentStep === step
                        ? "selected"
                        : "" + currentStep > step
                        ? "completed"
                        : ""
                    }`}
                    key={step}
                    onClick={() => handleStepClick(step)}
                  >
                    <div
                      className="s-text "
                      style={{ cursor: "pointer", color: "purple" }}
                      onClick={() => handleStepClick(step)}
                    >
                      {" "}
                      <span>STEP {step}</span>
                      <strong>
                        {step === 1 && "Report Profile"}
                        {step === 2 && "Pick Widgets"}
                        {step === 3 && "Review"}
                      </strong>
                    </div>
                    <div className="s-dot">
                      <i
                        className={`fa ${
                          currentStep >= step ? "fa-check" : "fa-circle"
                        }`}
                        aria-hidden="true"
                      ></i>
                    </div>
                    {step < 3 && (
                      <i className="line">
                        <span></span>
                      </i>
                    )}
                  </div>
                ))}
              </div>
              <div className="report-section">
                <div className="report-container">{renderStep()}</div>
                <div className="report-button">
                  {currentStep > 1 && (
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={handleBack}
                    >
                      Back
                    </button>
                  )}
                  {currentStep === 1 && (
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-fill"
                    onClick={() => setCurrentStep(currentStep + 1)}
                    disabled={currentStep === 5} // Disable next button on last step
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reports;
